import { Templates } from "@rjsf/mui"
import type { ObjectFieldTemplateProps } from "@rjsf/utils"
import { styled } from "@mui/material"

const ObjectFieldStyleHardOverwrites = styled("span")`
  padding-bottom: 8px;

  & > .MuiGrid-container > .MuiGrid-item {
    padding: 0;
    margin: 0 !important;
    padding-left: 12px;

    .field {
      padding-top: 4px;
      margin-bottom: 10px;
    }
  }
`

export function ObjectFieldTemplate(props: ObjectFieldTemplateProps) {
  return (
    <ObjectFieldStyleHardOverwrites>
      <Templates.ObjectFieldTemplate {...props} />
    </ObjectFieldStyleHardOverwrites>
  )
}

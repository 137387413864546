import { useEffect, useLayoutEffect } from "react"
import { useNavigate, useSearchParams, useLocation } from "react-router-dom"
import type { UseFormRegisterReturn } from "react-hook-form"
import { useForm } from "react-hook-form"
import Alert from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import CardHeader from "@mui/material/CardHeader"
import Divider from "@mui/material/Divider"
import FormControl from "@mui/material/FormControl"
import FormGroup from "@mui/material/FormGroup"
import GoogleIcon from "@mui/icons-material/Google"
import LoadingButton from "@mui/lab/LoadingButton"
import Looks3Icon from "@mui/icons-material/Looks3"
import TextField from "@mui/material/TextField"
import Typography from "@mui/material/Typography"
import Stack from "@mui/material/Stack"
import type { PasswordLoginPayload, AccessTokenLoginPayload } from "api"
import { useAuthDispatch, useAuthState } from "context/auth"
import { getEnv, getEnvAppProps } from "shell/config"
import { APP_ENV } from "shell/config"
import { useEffectOnce } from "usehooks-ts"
import { UsageWarningMessage } from "components/UsageWarningMessage/UsageWarningMessage"

const asInputRef = ({ ref, ...rest }: UseFormRegisterReturn) => ({
  ...rest,
  inputRef: ref,
})

function Login() {
  const dispatch = useAuthDispatch()
  const { user, error, loading } = useAuthState()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()

  const isAuthCallback = location.pathname === "/auth_callback"

  useLayoutEffect(() => {
    document.title = `Admin³ - Login`
  }, [])

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<PasswordLoginPayload>()

  const {
    register: registerAccessToken,
    formState: { errors: accessTokenErrors },
    handleSubmit: handleAccessTokenSubmit,
  } = useForm<AccessTokenLoginPayload>()

  useEffect(() => {
    if (user) {
      const redirectTo = sessionStorage.getItem("redirectTo") || "/"
      sessionStorage.removeItem("redirectTo")

      navigate(redirectTo)
    }
  }, [user, navigate])

  useEffectOnce(() => {
    if (isAuthCallback) {
      const code = searchParams.get("code")
      const state = searchParams.get("state")

      const { close } = state && JSON.parse(state)

      if (code) {
        dispatch({ type: "EXCHANGE_TOKEN", payload: { code, close } })
      }
    }
  })

  return user ? null : (
    <Stack
      component="main"
      justifyContent="center"
      alignItems="center"
      sx={{ height: "100vh" }}
    >
      <Card variant="outlined" sx={{ minWidth: 400, p: 4 }}>
        <CardHeader
          sx={{ p: 6 }}
          title={
            <Stack spacing={2}>
              <Typography
                sx={{ fontSize: "4em" }}
                textAlign="center"
                variant="h1"
              >
                Admin
                <sup>
                  <Looks3Icon fontSize="medium" />
                </sup>
              </Typography>
              <Typography textAlign="center" variant="subtitle2">
                ({getEnvAppProps().envName})
              </Typography>
            </Stack>
          }
        />
        <CardContent>
          <UsageWarningMessage />
        </CardContent>
        <CardContent
          hidden={![APP_ENV.LOCAL, APP_ENV.USLOCAL].includes(getEnv())}
        >
          <form
            name="login"
            onSubmit={handleSubmit((payload) => {
              dispatch({
                type: "LOGIN",
                payload,
              })
            })}
          >
            <FormGroup>
              {error && (
                <Alert variant="filled" sx={{ mb: 4 }} severity="error">
                  {error?.message}
                </Alert>
              )}
            </FormGroup>
            <FormGroup>
              <TextField
                {...asInputRef(register("username", { required: true }))}
                label="Username"
                type="text"
                id="username"
                error={Boolean(errors.username)}
                helperText={errors.username && "This field is required"}
              />
            </FormGroup>
            <FormGroup sx={{ mt: 2 }}>
              <TextField
                {...asInputRef(register("password", { required: true }))}
                label="Password"
                type="password"
                id="password"
                error={Boolean(errors.password)}
                helperText={errors.password && "This field is required"}
              />
            </FormGroup>
            <Box sx={{ my: 2 }}></Box>
            <FormGroup>
              <FormControl>
                <LoadingButton
                  size="large"
                  variant="contained"
                  fullWidth
                  type="submit"
                  loading={loading}
                >
                  Log in
                </LoadingButton>
              </FormControl>
            </FormGroup>
          </form>
        </CardContent>
        <CardContent
          hidden={process.env.REACT_APP_IS_LOCAL_DEVELOPMENT !== "true"}
        >
          <form
            name="loginWithAccessToken"
            onSubmit={handleAccessTokenSubmit((payload) => {
              dispatch({
                type: "LOGIN_WITH_ACCESS_TOKEN",
                payload,
              })
            })}
          >
            <FormGroup>
              {error && (
                <Alert variant="filled" sx={{ mb: 4 }} severity="error">
                  {error?.message}
                </Alert>
              )}
            </FormGroup>
            <FormGroup>
              <TextField
                {...asInputRef(
                  registerAccessToken("accessToken", { required: true })
                )}
                label="Access Token"
                type="text"
                id="accessToken"
                error={Boolean(accessTokenErrors.accessToken)}
                helperText={
                  accessTokenErrors.accessToken && "This field is required"
                }
              />
            </FormGroup>
            <Box sx={{ my: 2 }}></Box>
            <FormGroup>
              <FormControl>
                <LoadingButton
                  size="large"
                  variant="contained"
                  fullWidth
                  type="submit"
                  loading={loading}
                >
                  Log in with Access Token
                </LoadingButton>
              </FormControl>
            </FormGroup>
          </form>
        </CardContent>
        <Divider></Divider>
        <CardContent>
          <Button
            variant="outlined"
            startIcon={<GoogleIcon />}
            size="large"
            fullWidth
            onClick={() =>
              dispatch({
                type: "LOGIN_WITH_GOOGLE",
                payload: { inNewWindow: false },
              })
            }
          >
            Log in with Google
          </Button>
        </CardContent>
      </Card>
    </Stack>
  )
}

export default Login

import React, { useEffect } from "react"
import { useLocation } from "react-router-dom"
import { Box, Drawer, useMediaQuery } from "@mui/material"
import { styled, useTheme } from "@mui/material/styles"
import { SIDEBAR } from "../config"
import { Navigation } from "./navigation/Navigation"
import { AccountInformation } from "./AccountInformation"

const SidebarWrapper = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    width: SIDEBAR.WIDTH,
    flexShrink: 0,
  },
}))

const ScrollableArea = styled("div")({
  flexGrow: 1,
  height: "100%",
  overflowX: "hidden",
  overflowY: "auto",
})

type SidebarProps = {
  isOpen: boolean
  onClose: VoidFunction
}

export function Sidebar({ isOpen, onClose }: SidebarProps) {
  const { pathname } = useLocation()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"))

  useEffect(() => {
    if (isOpen) {
      onClose()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <SidebarWrapper>
      <Drawer
        variant={isDesktop ? "permanent" : "temporary"}
        open={isDesktop ? true : isOpen}
        onClose={isDesktop ? undefined : onClose}
        PaperProps={{
          sx: {
            width: SIDEBAR.WIDTH,
            ...(isDesktop ? { bgcolor: "background.default" } : {}),
          },
        }}
      >
        <ScrollableArea>
          <Box
            sx={{
              minHeight: 1,
              display: "flex",
              flexDirection: "column",
              paddingX: 2,
              paddingY: 3,
            }}
          >
            <AccountInformation />
            <Navigation />
            <Box sx={{ flexGrow: 1 }} />
          </Box>
        </ScrollableArea>
      </Drawer>
    </SidebarWrapper>
  )
}

import type { PaydaysSegment } from "api"
import { useNotification } from "context/notification"
import { pickErrorMessage } from "utils/pickErrorMessage"
import { safeHtmlDecode } from "utils/safeHtmlDecode"
import usePatchSegment from "query-hooks/usePatchSegment"
import { SegmentBuilderDialog } from "../SegmentBuilderDialog/SegmentBuilderDialog"

interface EditSegmentDialogProps {
  segment: Pick<
    PaydaysSegment,
    "segment_id" | "description" | "sql" | "properties"
  >
  onClose: () => void
}

export function EditSegmentDialog({
  segment,
  onClose,
}: EditSegmentDialogProps) {
  const notify = useNotification()

  const { mutate, isLoading } = usePatchSegment({
    onSuccess: () => {
      notify.that("segment").successfully("updated")
      onClose()
    },
    onError: (error) => {
      if (safeHtmlDecode(pickErrorMessage(error))) {
        notify.error(`Error: ${safeHtmlDecode(pickErrorMessage(error))}`, {
          autoHideDuration: 10000,
        })
      } else {
        notify.that("segment").erroredWhile("updating")
      }
    },
  })

  return (
    <SegmentBuilderDialog
      title={`Edit Segment ${segment.description}`}
      primaryButtonText="Save"
      segment={segment}
      isSubmitting={isLoading}
      onClose={onClose}
      onSubmit={(values) => {
        mutate({
          segment_id: segment.segment_id,
          ...values,
        })
      }}
    />
  )
}

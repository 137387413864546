import { Checkbox, FormControlLabel, Stack, TextField } from "@mui/material"

interface PropertiesFormHeaderProps {
  searchValue: string
  onSearchChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void
  isShowOnlyActivePropertiesDisabled: boolean
  isShowOnlyActivePropertiesChecked: boolean
  onShowOnlyActivePropertiesChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void
}

export function PropertiesFormHeader({
  searchValue,
  onSearchChange,
  isShowOnlyActivePropertiesDisabled,
  isShowOnlyActivePropertiesChecked,
  onShowOnlyActivePropertiesChange,
}: PropertiesFormHeaderProps) {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
    >
      <TextField
        size="small"
        label="Search fields"
        helperText="Requires at least 3 characters"
        value={searchValue}
        onChange={onSearchChange}
      />
      <FormControlLabel
        disabled={isShowOnlyActivePropertiesDisabled}
        label="Only active properties"
        control={
          <Checkbox
            checked={isShowOnlyActivePropertiesChecked}
            onChange={onShowOnlyActivePropertiesChange}
          />
        }
      />
    </Stack>
  )
}

import { Theme } from "@mui/material/styles"
import { alpha } from "@mui/material"
import { pxToRem } from "../getFontValue"

// ----------------------------------------------------------------------

export default function Autocomplete(theme: Theme) {
  return {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          ".MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
            paddingRight: "39px",
          },
          ".MuiAutocomplete-inputRoot": {
            background: alpha(theme.palette.common.white, 0.85),
          },
        },
        paper: {
          boxShadow: theme.customShadows.dropdown,
        },
        listbox: {
          padding: theme.spacing(0, 1),
          "& .MuiAutocomplete-option": {
            padding: theme.spacing(1),
            margin: theme.spacing(1, 0),
            borderRadius: theme.shape.borderRadius,
          },
        },
        groupLabel: {
          fontSize: pxToRem(16),
          textTransform: "capitalize",
        },
      },
    },
  }
}

import React from "react"
import ChevronRightIcon from "@mui/icons-material/ChevronRight"

type ExpandArrowIconProps = {
  isOpen: boolean
}

export function ExpandArrowIcon({ isOpen }: ExpandArrowIconProps) {
  return (
    <ChevronRightIcon
      height={16}
      width={16}
      sx={(theme) => ({
        transform: isOpen ? `rotate(90deg)` : `rotate(0)`,
        transition: theme.transitions.create("transform", {
          duration: theme.transitions.duration.shorter,
        }),
      })}
    />
  )
}

import { LoadingButton } from "@mui/lab"
import { Stack } from "@mui/material"
import { useForm } from "react-hook-form"
import Papa from "papaparse"
import { pick } from "lodash-es"
import { useParams } from "react-router-dom"
import { CollapsibleCard, DropzoneField } from "components"
import usePostPayScheduleBulkUpload from "pages/PaySchedulesDates/query-hooks/usePostPayScheduleBulkUpload"
import { useNotification } from "context/notification"
import { Instructions } from "./Instructions"
import { ErrorDisplay } from "./components/ErrorDisplay/ErrorDisplay"

const CSV_FIELDS = ["pay_period_start", "paid_on", "blackout_period_start"]

const parseCsv = (
  file: File
): Promise<Papa.ParseResult<Record<string, string>>> =>
  new Promise((resolve, reject) => {
    Papa.parse<Record<string, string>>(file, {
      header: true,
      skipEmptyLines: true,
      error: (error) => reject(error),
      complete: (results) => resolve(results),
    })
  })

type FormValues = {
  file: File | null
}

export function BulkUploadPaySchedules() {
  const { id } = useParams()
  const notify = useNotification()

  const {
    handleSubmit,
    control,
    setError,
    reset,
    formState: { isSubmitting },
  } = useForm<FormValues>({
    reValidateMode: "onSubmit",
    defaultValues: {
      file: null,
    },
  })

  const { mutate, error, isLoading } = usePostPayScheduleBulkUpload(id, {
    onSuccess: () => {
      reset()
      notify.that("bulk data").successfully("inserted")
    },
    onError: () => notify.that("bulk data").erroredWhile("inserting"),
  })

  return (
    <CollapsibleCard title="Bulk Upload">
      <Stack spacing={4}>
        <ErrorDisplay error={error} />
        <Instructions />
        <form
          onSubmit={handleSubmit(async ({ file }) => {
            if (!file) {
              throw new Error("File is missing")
            }

            const { data, meta } = await parseCsv(file)

            if (!CSV_FIELDS.every((field) => meta.fields?.includes(field))) {
              setError("file", {
                type: "custom",
                message: "File is missing required columns",
              })

              return
            }

            const payload = data.map((entry) => pick(entry, CSV_FIELDS))

            mutate(payload)
          })}
        >
          <Stack spacing={4}>
            <DropzoneField
              control={control}
              name="file"
              rules={{ required: true }}
              options={{
                accept: { "text/csv": [] },
              }}
            />
            <LoadingButton
              loading={isLoading || isSubmitting}
              type="submit"
              variant="contained"
            >
              Submit
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </CollapsibleCard>
  )
}

import { Alert, AlertTitle } from "@mui/material"
import { pickErrorMessage } from "utils/pickErrorMessage"
import ReactJson from "react-json-view"
import { isAxiosError } from "utils/isAxiosError"
import type { FC } from "react"

type BulkUploadErrorResponse = {
  message?: string | object
  err_message?: string
}

export const ErrorDisplay: FC<{ error: unknown }> = ({ error }) => {
  if (!error) {
    return null
  }

  if (
    isAxiosError<BulkUploadErrorResponse>(error) &&
    typeof error.response?.data.message === "object"
  ) {
    const jsonSource = error.response?.data.message

    return (
      <>
        <Alert severity="error" variant="filled">
          {error.response?.data?.err_message}
        </Alert>
        <Alert severity="error">
          <AlertTitle sx={{ pb: 2 }}>Validation Error Details</AlertTitle>
          <ReactJson
            iconStyle="square"
            quotesOnKeys={false}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            name="validation"
            src={jsonSource || {}}
          />
        </Alert>
      </>
    )
  }

  return (
    <Alert severity="error">
      There was an error processing the file
      <p>{pickErrorMessage(error)}</p>
    </Alert>
  )
}

import { Autocomplete, TextField, Chip } from "@mui/material"
import type { PaydaysDiscountRetailers } from "api"
import { useMemo } from "react"
import type { ControllerRenderProps, FieldValues } from "react-hook-form"

interface Props<T extends FieldValues> {
  retailers: PaydaysDiscountRetailers[] | undefined
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  field: ControllerRenderProps<T, any>
  label: string
}

export const DiscountAutocomplete = <T extends FieldValues>({
  retailers,
  field,
  label,
}: Props<T>) => {
  const simplifiedOptions = useMemo(
    () =>
      retailers?.map((retailer) => ({
        value: retailer.discount_retailer_id,
        label: retailer.display_name,
      })),
    [retailers]
  )

  const optionsMap = useMemo(
    () =>
      (retailers || []).reduce<Record<string, string>>(
        (acc, o) => ({
          ...acc,
          [o.discount_retailer_id]: o.display_name,
        }),
        {}
      ),
    [retailers]
  )

  const { value } = field

  return (
    <Autocomplete
      multiple
      options={simplifiedOptions || []}
      filterSelectedOptions
      value={((value as string[]) || []).map((id) => ({
        value: id,
        label: optionsMap[id],
      }))}
      onChange={(_, data) => field.onChange(data.map((i) => i.value))}
      renderInput={(params) => <TextField {...params} label={label} />}
      renderOption={(props, option) => <li {...props}>{option.label}</li>}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip {...getTagProps({ index })} label={option.label} />
        ))
      }
      isOptionEqualToValue={(o, v) => o.value === v.value}
    />
  )
}

import { Divider, Box, LinearProgress, Alert } from "@mui/material"
import type Form from "@rjsf/core"
import {
  CollapsibleCard,
  ChangelogDialog,
  ExpandableChangelogTable,
  JsonForm,
  PropertiesFormHeader,
} from "components"
import { useNotification } from "context/notification"
import { useFilteredSchema } from "hooks/useFilteredSchema"
import { useOnlyExistingSchema } from "hooks/useOnlyExistingSchema"
import { isEqual } from "lodash-es"
import useOptionsPayScheduleProperties from "pages/PaySchedulesDates/query-hooks/useOptionsPropertiesSchema"
import usePaySchedule from "pages/PaySchedulesDates/query-hooks/usePaySchedule"
import usePaySchedulePropertiesChangelog from "pages/PaySchedulesDates/query-hooks/usePaySchedulePropertiesChangelog"
import usePutPayScheduleProperties from "pages/PaySchedulesDates/query-hooks/usePutPayScheduleProperties"
import type { ComponentProps } from "react"
import { useEffect, useState, useTransition } from "react"
import { useParams } from "react-router-dom"
import { pickErrorMessage } from "utils/pickErrorMessage"
import { omitEmptyObjectsAndArrays } from "utils/omitEmptyObjectsAndArrays"
import { safeHtmlDecode } from "utils/safeHtmlDecode"

function PaySchedulePropertiesContent() {
  const { id } = useParams()
  const notify = useNotification()

  const [isChangelogOpen, setIsChangelogOpen] = useState(false)
  const [isExistingOnly, setIsExistingOnly] = useState(true)
  const [changelogNote, setChangelogNote] = useState("")
  const [formData, setFormData] = useState<Record<string, unknown>>({})

  const updatedData = omitEmptyObjectsAndArrays(formData)

  const { data: paySchedule, isLoading } = usePaySchedule(id)
  const { data: schema } = useOptionsPayScheduleProperties(id)
  const { data: changelog } = usePaySchedulePropertiesChangelog(id)
  const { mutate, error } = usePutPayScheduleProperties(id, {
    onSuccess: () =>
      notify.that("pay schedule properties").successfully("updated"),
    onError: () =>
      notify.that("pay schedule properties").erroredWhile("updating"),
  })

  useEffect(() => {
    paySchedule?.properties && setFormData(paySchedule?.properties)
  }, [paySchedule?.properties])

  const [isTransitionPending, startTransition] = useTransition()

  const onlyExistingSchema = useOnlyExistingSchema({
    schema,
    source: paySchedule?.properties,
  })

  const [
    search,
    setSearch,
    isSearchDebounced,
    debouncedSearchValue,
    debouncedFilteredSchema,
  ] = useFilteredSchema({ schema })

  const handleCancel = () => {
    setChangelogNote("")
    setIsChangelogOpen(false)
  }

  const handleSubmit = () => {
    setChangelogNote("")
    setIsChangelogOpen(true)
  }

  const handleUpdate = () => {
    setIsChangelogOpen(false)
    setChangelogNote("")

    mutate({
      ...updatedData,
      changelog: changelogNote,
    })
  }

  const isSearching = debouncedSearchValue.length > 2

  const formSchema = (
    isSearching
      ? debouncedFilteredSchema
      : isExistingOnly
      ? onlyExistingSchema
      : schema
  ) as ComponentProps<typeof Form>["schema"]

  if (formSchema) {
    formSchema.title = ""
  }

  return (
    <>
      {error && (
        <Alert sx={{ mb: 2, whiteSpace: "break-spaces" }} severity="error">
          {safeHtmlDecode(pickErrorMessage(error))}
        </Alert>
      )}
      <Box>
        <PropertiesFormHeader
          searchValue={search}
          onSearchChange={(e) => setSearch(e.target.value)}
          isShowOnlyActivePropertiesDisabled={
            isSearching || isTransitionPending
          }
          isShowOnlyActivePropertiesChecked={!isSearching && isExistingOnly}
          onShowOnlyActivePropertiesChange={() =>
            startTransition(() => {
              setIsExistingOnly(!isExistingOnly)
            })
          }
        />
        {isTransitionPending || isLoading || isSearchDebounced ? (
          <Box width="120%" height={5} mt={3} mx="-10%">
            <LinearProgress color="primary" />
          </Box>
        ) : (
          <Divider sx={{ height: 5, mt: 3 }} />
        )}
        <JsonForm
          shouldRender={!!formSchema}
          formData={formData}
          onChange={(e) => setFormData(e.formData)}
          onSubmit={handleSubmit}
          schema={formSchema}
          uiSchema={{
            "ui:submitButtonOptions": {
              submitText: "Submit",
              norender: false,
              props: {
                disabled:
                  !updatedData || isEqual(updatedData, paySchedule?.properties),
              },
            },
          }}
        />
      </Box>
      <Divider sx={{ mt: 4 }} />
      <ExpandableChangelogTable changelog={changelog} />
      <ChangelogDialog
        onCancel={handleCancel}
        onClose={handleCancel}
        onSubmit={handleUpdate}
        open={isChangelogOpen}
        note={changelogNote}
        onChangeNote={(e) => setChangelogNote(e.target.value)}
      />
    </>
  )
}

export function PayScheduleProperties() {
  return (
    <CollapsibleCard title="Pay Schedule Properties">
      <PaySchedulePropertiesContent />
    </CollapsibleCard>
  )
}

import { Outlet } from "react-router"
import { useLocation } from "react-router-dom"
import { routes } from "routes"
import { Page, TabNav } from "components"
import useDevice from "query-hooks/useDevice"
import { useParams } from "react-router-dom"

function Device() {
  const { id } = useParams()
  const { data } = useDevice(id)
  const { pathname } = useLocation()

  const title = data?.device_id ? `Device ${data.device_id}` : "Device"

  const navigationTabs = [
    { label: "Details", href: routes.deviceDetails({ id }) },
    { label: "Experiments", href: routes.deviceExperiments({ id }) },
  ]

  return (
    <Page
      title={title}
      tabs={<TabNav tabs={navigationTabs} currentTab={pathname} />}
    >
      <Outlet />
    </Page>
  )
}

export default Device

import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = () => ["configuration", "app-releases"] as const

function useAppReleaseVersions() {
  const endpoint = endpoints.configuration.versions()

  return useQuery(getQueryKey(), endpoint.get, {
    select: (data) => data.data.data,
  })
}

useAppReleaseVersions.getQueryKey = getQueryKey

export default useAppReleaseVersions

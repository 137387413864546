import assert from "assert"
import type { PropsWithChildren } from "react"
import type { Control, FieldPath } from "react-hook-form"
import { Controller } from "react-hook-form"
import { get } from "lodash-es"
import type { SegmentBuilderFormValues } from "components"
import { FormControl, FormHelperText, Select } from "@mui/material"
import type { PaydaysSegmentPropertiesFilter } from "api"
import { isValidDataSource } from "../../utils/isValidDataSource"
import { useDataSource } from "../../hooks/useDataSource"

type ControlledSelectProps = PropsWithChildren<{
  name: FieldPath<SegmentBuilderFormValues>
  control: Control<SegmentBuilderFormValues>
  isOptionsLoading: boolean
  isErrorLoadingOptions: boolean
}>

function ControlledSelect({
  name,
  control,
  children,
  isOptionsLoading,
  isErrorLoadingOptions,
}: ControlledSelectProps) {
  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState }) => (
        <FormControl variant="outlined" size="small" fullWidth>
          <Select
            native
            {...field}
            disabled={isOptionsLoading || isErrorLoadingOptions}
            error={isErrorLoadingOptions || Boolean(fieldState.error)}
          >
            {isOptionsLoading && <option value="">Loading</option>}
            {isErrorLoadingOptions && <option value="">Error</option>}
            {!isOptionsLoading && !isErrorLoadingOptions && (
              <option value="">Please select…</option>
            )}
            {children}
          </Select>
          {(isErrorLoadingOptions || fieldState.error) && (
            <FormHelperText>
              {isErrorLoadingOptions
                ? // NOTE: Show an error for options not being loaded without waiting for the form
                  // to be validated on submission.
                  "Options not loaded"
                : fieldState.error?.message || `Field cannot be blank`}
            </FormHelperText>
          )}
        </FormControl>
      )}
      rules={{
        required: true,
        validate: () =>
          (!isOptionsLoading && !isErrorLoadingOptions) || "Options not loaded",
      }}
    />
  )
}

interface ListFilterFieldProps {
  name: FieldPath<SegmentBuilderFormValues>
  control: Control<SegmentBuilderFormValues>
  filter: Omit<PaydaysSegmentPropertiesFilter, "values"> & {
    values: (string | boolean | Date | string[])[]
  }
}

export function ListFilterField({
  name,
  control,
  filter,
}: ListFilterFieldProps) {
  const { data: filterData } = filter
  const source = !Array.isArray(filterData) ? filterData.source : undefined
  const dataSourceQuery = useDataSource(
    source && isValidDataSource(source) ? source : undefined
  )

  if (
    !Array.isArray(filterData) &&
    filterData.source &&
    filterData.key &&
    filterData.display
  ) {
    const { source, key, display } = filterData
    assert(isValidDataSource(source), `Unsupported data source: ${source}`)
    assert(dataSourceQuery)
    const { data, isLoading, isSuccess, isError } = dataSourceQuery

    return (
      <ControlledSelect
        name={name}
        control={control}
        isOptionsLoading={isLoading}
        isErrorLoadingOptions={isError}
      >
        {isSuccess &&
          data.map((item) => {
            assert(
              typeof item === "object",
              "Item in data source data should be an object"
            )
            const value = get(item, key)
            assert(
              value,
              `Could not find value key "${key}" in an item belonging to data source: "${source}"`
            )
            const label = get(item, display)
            assert(
              label,
              `Could not find display key "${display}" in an item belonging to data source: "${source}"`
            )

            return (
              <option key={value} value={value}>
                {label}
              </option>
            )
          })}
      </ControlledSelect>
    )
  } else if (!Array.isArray(filterData) && filterData.source) {
    const { source } = filterData
    assert(isValidDataSource(source), `Unsupported data source: ${source}`)
    assert(dataSourceQuery)
    const { data, isLoading, isSuccess, isError } = dataSourceQuery

    return (
      <ControlledSelect
        name={name}
        control={control}
        isOptionsLoading={isLoading}
        isErrorLoadingOptions={isError}
      >
        {isLoading && <option value="">Loading</option>}
        {isError && <option value="">Error</option>}
        {isSuccess &&
          data.map((value) => {
            assert(
              typeof value === "string",
              "Item in data source data should be a string"
            )
            return (
              <option key={value} value={value}>
                {value}
              </option>
            )
          })}
      </ControlledSelect>
    )
  } else if (Array.isArray(filter.data)) {
    return (
      <ControlledSelect
        name={name}
        control={control}
        isOptionsLoading={false}
        isErrorLoadingOptions={false}
      >
        {filter.data.map((value) => (
          <option key={value} value={value}>
            {value}
          </option>
        ))}
      </ControlledSelect>
    )
  } else {
    throw new Error("Filter data not of the correct shape")
  }
}

import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = (id: string | undefined) => ["device", id] as const

function useDevice(id: string | undefined) {
  const endpoint = endpoints.devices.byId(id)

  return useQuery(getQueryKey(id), endpoint.get, {
    enabled: Boolean(id),
    select: (data) => data.data.data,
  })
}

useDevice.getQueryKey = getQueryKey

export default useDevice

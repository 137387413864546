import {
  Alert,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import RemoveIcon from "@mui/icons-material/Remove"
import { Link as RouterLink } from "react-router-dom"
import { routes } from "routes"

interface SegmentPreviewDialogProps {
  data: { employee_id: string; full_name: string }[]
  onClose: () => void
}

export function SegmentPreviewDialog({
  onClose,
  data,
}: SegmentPreviewDialogProps) {
  return (
    <Dialog open onClose={onClose} maxWidth="lg" fullWidth>
      <DialogTitle>Segment Preview</DialogTitle>
      <DialogContent>
        <Alert severity="info">
          Segment contains {data.length > 0 ? `at least ${data.length}` : "0"}{" "}
          employees
        </Alert>
        {data.length > 0 && (
          <TableContainer sx={{ mt: 4 }} component={Card}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Employee ID</TableCell>
                  <TableCell>Employee</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(({ employee_id, full_name }) => (
                  <TableRow key={employee_id}>
                    <TableCell width="50%">
                      <Link
                        component={RouterLink}
                        to={routes.employee({ id: employee_id })}
                        target="_blank"
                      >
                        {employee_id}
                      </Link>
                    </TableCell>
                    <TableCell>
                      {full_name ? (
                        <Link
                          component={RouterLink}
                          to={routes.employee({ id: employee_id })}
                          target="_blank"
                        >
                          {full_name}
                        </Link>
                      ) : (
                        <RemoveIcon fontSize="small" color="disabled" />
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
}

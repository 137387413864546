import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getPayScheduleDates = (payScheduleId?: string) =>
  ["payschedules", payScheduleId, "dates"] as const

function usePaySchedulesDates(payScheduleId?: string) {
  const endpoint = endpoints.paySchedules.dates.byPayScheduleId(
    payScheduleId,
    true
  )

  return useQuery(getPayScheduleDates(payScheduleId), endpoint.get, {
    enabled: Boolean(payScheduleId),
    select: (data) => data.data.data,
  })
}

export default usePaySchedulesDates

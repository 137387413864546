import { useMemo } from "react"
import type {
  PaydaysCompanyProperties,
  PaydaysCompanyIntegrationProperties,
  Schema,
} from "api"
import type { PropertiesData } from "utils/deepRemoveUnassignedPropertiesFromSchema"
import { deepRemoveUnassignedPropertiesFromSchema } from "utils/deepRemoveUnassignedPropertiesFromSchema"

export const useOnlyExistingSchema = ({
  source = {},
  schema = {} as Schema,
}: {
  schema?: Schema
  source?: PaydaysCompanyProperties | PaydaysCompanyIntegrationProperties
}) => {
  return useMemo(() => {
    const { additionalProperties, ...filteredSchema } =
      deepRemoveUnassignedPropertiesFromSchema(
        schema,
        source as PropertiesData
      ) || {}

    return filteredSchema
  }, [schema, source])
}

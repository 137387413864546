import { useState, useEffect } from "react"
import { useInterval } from "usehooks-ts"
import { getTtl, getRefreshInterval } from "./utils"

export function useEstimatedTtl(initialExp: number) {
  const [estimatedTtl, setEstimatedTtl] = useState(getTtl(initialExp))
  const refreshInterval = getRefreshInterval(estimatedTtl)

  useEffect(() => {
    setEstimatedTtl(getTtl(initialExp))
  }, [initialExp])

  useInterval(() => {
    setEstimatedTtl(estimatedTtl - refreshInterval)
  }, refreshInterval)

  return estimatedTtl
}

import { Widgets } from "@rjsf/mui"
import type { WidgetProps } from "@rjsf/utils"
import { Box, Card, FormGroup, FormHelperText } from "@mui/material"

export function CheckboxWidget(props: WidgetProps) {
  const { readonly, schema, value, onChange } = props

  return (
    <FormGroup>
      <Card
        onClick={(e) => {
          e.preventDefault()
          if (!readonly) onChange(!value)
        }}
        variant="outlined"
        sx={{
          borderColor: "grey.50056",
          "&:hover": { borderColor: "grey.700" },
        }}
      >
        <Box p={1} pl={2}>
          <Widgets.CheckboxWidget {...props} />
          <FormHelperText>{schema.description}</FormHelperText>
        </Box>
      </Card>
    </FormGroup>
  )
}

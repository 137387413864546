import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"
export const getQueryKey = () => ["debitcard_us", "application"] as const

function useDebitcardApplicationsUS(user_id: string) {
  const endpoint = endpoints.debitCardUS.applications(user_id)

  return useQuery([...getQueryKey(), user_id], endpoint.get, {
    select: (data) => data.data,
  })
}

useDebitcardApplicationsUS.getQueryKey = getQueryKey

export default useDebitcardApplicationsUS

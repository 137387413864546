import type { FC, PropsWithChildren } from "react"
import { Box } from "@mui/material"
import { useSearch } from "context/search/useSearch"

type SearchableProps = PropsWithChildren<{
  disabled?: boolean
  title?: string
}>

export const Searchable: FC<SearchableProps> = ({
  children,
  title,
  disabled,
}) => {
  const [search] = useSearch()

  let matched: boolean | undefined = false
  if (!disabled) {
    matched = title?.toLocaleLowerCase().includes(search.toLocaleLowerCase())
  }

  const highlight = search && matched
  const applyDefaultStyle = highlight || disabled || !search

  return (
    <Box
      sx={{
        display: !children ? "none" : undefined,
        border: "2px solid",
        borderRadius: 2,
        borderColor: (theme) =>
          !disabled && highlight
            ? theme.palette.primary.lighter
            : "transparent",
        transition:
          "opacity 200ms ease-in-out, all 300ms ease-in-out, border 0s",
        pointerEvents: applyDefaultStyle ? undefined : "none",
        opacity: applyDefaultStyle ? 1 : 0.1,
        height: applyDefaultStyle ? undefined : 10,
        transform: applyDefaultStyle ? undefined : "scale(1, 0.9)",
        transformOrigin: "center center",
        filter: applyDefaultStyle
          ? "brightness(100%) blur(0px)"
          : "brightness(95%) blur(1px)",
      }}
    >
      {children}
    </Box>
  )
}

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { endpoints } from "api"
import { getQueryKey } from "./usePaySchedule"

function usePostPayScheduleDemoDateGenerate(
  payScheduleId: string,
  options?: Partial<{
    onSuccess: () => void
    onError: () => void
  }>
) {
  const queryClient = useQueryClient()
  const endpoint = endpoints.paySchedules.dates.generateDemo(payScheduleId)

  return useMutation(
    (payload: { date_in_first_week: string }) => {
      return endpoint.post(new URLSearchParams(payload))
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getQueryKey(payScheduleId))
        options?.onSuccess?.()
      },
      onError: () => {
        options?.onError?.()
      },
    }
  )
}

export default usePostPayScheduleDemoDateGenerate

import { useContext } from "react"
import { ConfirmationContext } from "./ConfirmationContext"

export const useConfirmation = () => {
  const context = useContext(ConfirmationContext)

  if (!context) {
    throw new Error(
      "useConfirmation may only be called in a child of ConfirmationProvider"
    )
  }

  return context
}

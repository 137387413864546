import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Tooltip,
} from "@mui/material"
import { Fragment } from "react"
import { formatDistance } from "date-fns"
import type { PaydaysPropertyChangelog } from "api"
import { DottedUnderline } from "components"
import { DATE_FORMATS, formatDate, parseDate } from "utils/date"

const humanizePath = (value: unknown) =>
  JSON.stringify(value, null, 2)
    .replace(/'\]\['/g, " > ")
    .replace(/"/g, "")
    .replace("root['", "")
    .replace("']", "")

const getChangesText = (changes: PaydaysPropertyChangelog["changes"]) => {
  const changesText: string[] = []

  Object.entries(changes.dictionary_item_added || {}).forEach(
    ([key, value]) => {
      changesText.push(`Added ${humanizePath(key)} = ${humanizePath(value)}`)
    }
  )

  Object.entries(changes.dictionary_item_removed || {}).forEach(
    ([key, value]) => {
      changesText.push(`Removed ${humanizePath(key)} = ${humanizePath(value)}`)
    }
  )

  Object.entries(changes.values_changed || {}).forEach(([key, value]) => {
    changesText.push(`Updated ${humanizePath(key)} = ${humanizePath(value)}`)
  })

  return changesText
}

export function ChangelogTable({
  changelog,
}: {
  changelog?: PaydaysPropertyChangelog[]
}) {
  return (
    <Table aria-label="changelog">
      <TableHead>
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>User</TableCell>
          <TableCell>Note</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {!!changelog?.length &&
          changelog.map((row, i) => {
            const createdAtDate = parseDate(row.created_at)
            const formattedCreatedAtDate = formatDate(
              createdAtDate,
              DATE_FORMATS.longDate
            )

            return (
              <Fragment key={row.sequence}>
                <TableRow>
                  <TableCell>
                    <Tooltip title={formattedCreatedAtDate ?? false}>
                      <span>
                        <DottedUnderline>
                          {createdAtDate &&
                            formatDistance(createdAtDate, new Date(), {
                              addSuffix: true,
                            })}
                        </DottedUnderline>
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{row.username}</TableCell>
                  <TableCell>{row.notes}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell colSpan={3}>
                    <Box component="ul" sx={{ paddingLeft: 2 }}>
                      {getChangesText(row.changes).map((text, index) => (
                        <li key={index}>
                          <pre>{text}</pre>
                        </li>
                      ))}
                    </Box>
                  </TableCell>
                </TableRow>
              </Fragment>
            )
          })}
        {changelog?.length === 0 && (
          <TableRow>
            <TableCell colSpan={3} sx={{ textAlign: "center" }}>
              No changes
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  )
}

import type { FC, PropsWithChildren } from "react"
import type { Theme } from "@mui/material"
import { createTheme, ThemeProvider } from "@mui/material"

const rjsfTheme = (theme: Theme): Theme =>
  createTheme({
    ...theme,
    components: {
      MuiFormHelperText: {
        styleOverrides: {
          root: {
            marginLeft: 34,
            marginRight: 28,
            marginBottom: 4,
          },
        },
      },
      MuiTypography: {
        variants: [
          {
            props: {
              variant: "subtitle2",
            },
            style: {
              color: theme.palette.grey[500],
              marginTop: "0 !important",
            },
          },
          {
            props: {
              variant: "caption",
            },
            style: {
              marginLeft: 8,
              marginTop: 2,
            },
          },
        ],
      },
      MuiTextField: {
        defaultProps: {
          size: "small",
        },
      },
      MuiFormControlLabel: {
        defaultProps: {
          componentsProps: {
            typography: {
              variant: "body2",
            },
          },
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            paddingLeft: 16,
            paddingTop: 4,
            paddingBottom: 0,
          },
        },
        defaultProps: {
          size: "small",
        },
      },
    },
  })

export const JsonFormThemeProvider: FC<PropsWithChildren> = ({ children }) => (
  <ThemeProvider theme={rjsfTheme}>{children}</ThemeProvider>
)

export function omitEmptyObjectsAndArrays<T extends object>(
  object: T
): Partial<T> | undefined {
  const prunedObject = Object.entries(object).reduce((acc, [key, value]) => {
    if (value === undefined) {
      return acc
    }

    if (Array.isArray(value)) {
      return value.length ? { ...acc, [key]: value } : acc
    }

    if (typeof value === "object" && value !== null) {
      const pruned = omitEmptyObjectsAndArrays(value)

      return pruned ? { ...acc, [key]: pruned } : acc
    }

    return { ...acc, [key]: value }
  }, {})

  return Object.keys(prunedObject).length ? prunedObject : undefined
}

export const HEADER = {
  MOBILE_HEIGHT: 64,
  DESKTOP_HEIGHT: 66,
}

export const SIDEBAR = {
  WIDTH: 240,
  ITEM_ROOT_HEIGHT: 30,
  ITEM_SUB_HEIGHT: 30,
  ITEM_ICON_SIZE: 20,
}

export enum APP_ENV {
  LOCAL = "local",
  USLOCAL = "uslocal",
  STAGING = "prod-sandbox",
  USSTAGING = "usstaging",
  PROD = "prod-live",
  USPROD = "usprod",
}

export enum BUILD_REGION {
  UK = "uk",
  US = "us",
}

export const appEnvProps = {
  [APP_ENV.LOCAL]: { envName: "Development", color: "#ff9800" },
  [APP_ENV.USLOCAL]: { envName: "Development", color: "#7f3b08" },
  [APP_ENV.STAGING]: { envName: "Staging", color: "#29b6f6" },
  [APP_ENV.USSTAGING]: { envName: "Staging", color: "#c51b7d" },
  [APP_ENV.PROD]: { envName: "Production", color: "#66bb6a" },
  [APP_ENV.USPROD]: { envName: "Production", color: "#542788" },
}

export const getEnv = () => {
  return process.env.REACT_APP_ENVIRONMENT as APP_ENV
}

export const getEnvAppProps = () => {
  const env = process.env.REACT_APP_ENVIRONMENT as APP_ENV
  return appEnvProps?.[env] ?? appEnvProps["prod-live"]
}

export const getBuildRegion = () => {
  return (process.env.REACT_APP_BUILD_REGION || "uk") as BUILD_REGION
}

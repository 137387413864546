import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = () => ["discounts", "categories"] as const

const useDiscountCategories = () => {
  const endpoint = endpoints.discounts.categories()

  return useQuery(getQueryKey(), endpoint.get, {
    select: (data) => data.data.data,
  })
}

useDiscountCategories.getQueryKey = getQueryKey

export default useDiscountCategories

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material"
import type { ChangeEvent } from "react"

type ChangelogDialogProps = {
  open: boolean
  onClose: () => void
  onCancel: () => void
  onSubmit: () => void
  onChangeNote: (e: ChangeEvent<HTMLInputElement>) => void
  note: string
}

export function ChangelogDialog({
  open,
  onClose,
  onCancel,
  onSubmit,
  onChangeNote,
  note,
}: ChangelogDialogProps) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Changelog Note</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Explain the changes. This note will be shown below.
        </DialogContentText>
        <TextField
          multiline
          margin="normal"
          rows={2}
          autoFocus
          required
          id="name"
          label="Note"
          type="text"
          fullWidth
          value={note}
          onChange={onChangeNote}
          variant="standard"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel}>Cancel</Button>
        <Button disabled={!note} variant="contained" onClick={onSubmit}>
          Update
        </Button>
      </DialogActions>
    </Dialog>
  )
}

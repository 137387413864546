import useCompanies from "query-hooks/useCompanies"
import useEmailTemplates from "query-hooks/useEmailTemplates"
import useInAppDialogs from "query-hooks/useInAppDialogs"
import useCompanyIntegrationPartners from "query-hooks/useCompanyIntegrationPartners"
import useOptionsCompanyProperties from "pages/Company/query-hooks/useOptionsCompanyProperties"
import type { SUPPORTED_DATA_SOURCES } from "../constants/supportedDataSources"

export function useDataSource(
  value: typeof SUPPORTED_DATA_SOURCES[number] | undefined
) {
  const companiesQueryResult = useCompanies()
  const inAppDialogsQueryResult = useInAppDialogs()
  const integrationPartnersQueryResult = useCompanyIntegrationPartners()
  const emailTemplatesQueryResult = useEmailTemplates()

  const firstCompanyId = companiesQueryResult.data?.[0].company_id
  const companyPropertiesSchema = useOptionsCompanyProperties(firstCompanyId)

  const appFeatureProperties =
    companyPropertiesSchema.data?.data?.properties?.app_features?.properties
  const appFeaturesQueryResult = {
    ...companyPropertiesSchema,
    data: appFeatureProperties
      ? Object.entries(appFeatureProperties).map(([key, value]) => ({
          app_feature_id: key,
          name: value.title,
        }))
      : [],
  }

  const DATA_SOURCE_MAP = {
    companies: companiesQueryResult,
    in_app_dialogs: inAppDialogsQueryResult,
    integrations: integrationPartnersQueryResult,
    email_templates: emailTemplatesQueryResult,
    app_features: appFeaturesQueryResult,
  }

  return !value ? undefined : DATA_SOURCE_MAP[value]
}

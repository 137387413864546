import { Avatar, Box, Typography, alpha } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useAuthState } from "context/auth"
import { useState } from "react"
import { getEnvAppProps } from "../config"
import WagestreamLogo from "./wagestream-logo.png"

const StyledContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(getEnvAppProps().color, 0.15),
}))

export function AccountInformation() {
  const { user } = useAuthState()
  const [identity, setIdentity] = useState(user?.identity)

  if (user && user.identity !== identity) {
    setIdentity(user.identity)
  }

  return (
    <StyledContainer>
      <Avatar src={WagestreamLogo} alt="Wagestream" sx={{ mr: 2 }} />
      <Box sx={{ overflow: "hidden" }}>
        <Typography
          variant="subtitle2"
          sx={{ overflow: "hidden", textOverflow: "ellipsis" }}
        >
          {identity}
        </Typography>
        <Typography variant="subtitle2">
          ({getEnvAppProps().envName})
        </Typography>
      </Box>
    </StyledContainer>
  )
}

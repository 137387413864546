import type { Dispatch } from "react"
import React from "react"

export const SearchContext = React.createContext<[string, Dispatch<string>]>([
  "",
  () => {
    throw new Error("Context is not initialized yet")
  },
])

SearchContext.displayName = "SearchContext"

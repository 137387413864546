import type { Schema } from "api"
import { isEmpty } from "lodash-es"

export type PropertiesData =
  | { [key: string]: PropertiesData }
  | number
  | boolean
  | string
  | null

export const deepRemoveUnassignedPropertiesFromSchema = (
  sourceSchema: Schema,
  sourceProperties: PropertiesData
): Schema | null => {
  if (sourceProperties === null) {
    return null
  }

  if (sourceSchema.type !== "object") {
    return !!sourceProperties ? sourceSchema : null
  }

  const entries = Object.entries(sourceSchema.properties || {})

  const onlyExisting = entries.reduce((prevEntries, [key, subSchema]) => {
    if (typeof sourceProperties === "object") {
      const cleanSchema = deepRemoveUnassignedPropertiesFromSchema(
        subSchema,
        sourceProperties[key]
      )

      if (
        !cleanSchema ||
        (cleanSchema.type === "object" && isEmpty(sourceProperties?.[key])) ||
        (cleanSchema.type === "object" && isEmpty(cleanSchema.properties))
      ) {
        return prevEntries
      }

      const entry: [string, Schema] = [key, cleanSchema]

      return [...prevEntries, entry]
    }

    const entry: [string, Schema] = [key, subSchema]

    return [...prevEntries, entry]
  }, [] as typeof entries)

  return { ...sourceSchema, properties: Object.fromEntries(onlyExisting) }
}

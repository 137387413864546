export function pathWithSearchParams(
  path: string,
  searchParams: Record<string, string | number | boolean | null | undefined>
) {
  const prunedSearchParams = Object.entries(searchParams)
    .filter(([_, value]) => value !== null && value !== undefined)
    .map(([key, value]) => [key, String(value)])

  return `${path}${
    prunedSearchParams.length > 0 ? "?" : ""
  }${new URLSearchParams(prunedSearchParams)}`
}

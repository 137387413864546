import type { CheckboxProps, FormControlLabelProps } from "@mui/material"
import { FormControlLabel, Checkbox } from "@mui/material"
import type { ControllerProps, FieldValues } from "react-hook-form"
import { Controller } from "react-hook-form"

type CheckboxLiftedProps = "sx"
type FormControlLabelLiftedProps = "label"

type ControlledCheckboxProps<T> = Omit<ControllerProps<T>, "render"> &
  Pick<CheckboxProps, CheckboxLiftedProps> &
  Pick<FormControlLabelProps, FormControlLabelLiftedProps> & {
    fieldProps?: Omit<
      CheckboxProps,
      CheckboxLiftedProps | keyof ControllerProps<T>
    >
  }

export function ControlledCheckboxField<T extends FieldValues>({
  name,
  label,
  sx,
  fieldProps,
  ...controllerProps
}: ControlledCheckboxProps<T>) {
  return (
    <Controller
      name={name}
      {...controllerProps}
      render={({ field }) => (
        <FormControlLabel
          label={label}
          sx={sx}
          control={
            <Checkbox
              size="small"
              checked={field.value}
              {...fieldProps}
              {...field}
            />
          }
        />
      )}
    />
  )
}

import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = (id?: string) =>
  ["payschedules", id, "properties", "schema"] as const

function useOptionsPayScheduleProperties(id: string | undefined) {
  const endpoint = endpoints.paySchedules.properties.byId(id)
  const enabled = Boolean(id)

  return useQuery(getQueryKey(id), endpoint.options, {
    enabled,
    select: (data) => data.data,
  })
}

useOptionsPayScheduleProperties.getQueryKey = getQueryKey

export default useOptionsPayScheduleProperties

import { Grid } from "@mui/material"
import { ErrorBoundary } from "@sentry/react"
import { ErrorFallback, PageHeader, ScrollToTopFab } from "components"
import { ConfirmationProvider } from "context/confirmation"
import type { FC, PropsWithChildren, ReactNode } from "react"
import { useLayoutEffect } from "react"

export const Page: FC<
  PropsWithChildren<{
    tabs?: ReactNode
    header?: ReactNode
    headerTitle?: string
    headerSubtitle?: string
    title?: string
    scrollToTopButton?: boolean
  }>
> = ({
  header,
  headerTitle,
  headerSubtitle,
  tabs,
  children,
  title = "...",
  scrollToTopButton,
}) => {
  useLayoutEffect(() => {
    document.title = "Admin³" + (title ? " - " + title : "")
  }, [title])

  const headerSlot = header || (
    <PageHeader title={headerTitle || title} subtitle={headerSubtitle} />
  )

  return (
    <ConfirmationProvider>
      <Grid
        flex={1}
        container
        spacing={2}
        sx={{ mb: 16, padding: { xs: 2, lg: 0 } }}
      >
        {headerSlot && (
          <Grid item xs={12}>
            {headerSlot}
          </Grid>
        )}
        {tabs && (
          <Grid item xs={12}>
            {tabs}
          </Grid>
        )}
        <Grid item xs={12}>
          <ErrorBoundary fallback={(error) => <ErrorFallback {...error} />}>
            <Grid container spacing={1}>
              {children}
            </Grid>
          </ErrorBoundary>
        </Grid>
        {scrollToTopButton && <ScrollToTopFab />}
      </Grid>
    </ConfirmationProvider>
  )
}

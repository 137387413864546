import { type GridColumns } from "@mui/x-data-grid"
import { Button } from "@mui/material"
import type { DebitcardUS } from "api"
import { dateTimeColumnDefaultOptions } from "utils/columns"
import PreviewIcon from "@mui/icons-material/Preview"
import { useMemo } from "react"
import UserDebitcardUpdateStatusAction from "./UserDebitcardUpdateStatusAction"
import UserDebitcardCancelAction from "./UserDebitcardCancelAction"
import UserDebitcardReissueAction from "./UserDebitcardReissueAction"

type UseColumns = {
  onViewStatusHistory: (id: string) => void
  onViewFulfillmentHistory: (id: string) => void
}

const getColumns = ({
  onViewStatusHistory,
  onViewFulfillmentHistory,
}: UseColumns): GridColumns<DebitcardUS> => {
  return [
    {
      field: "ws_card_id",
      headerName: "WS Card ID",
      minWidth: 160,
    },
    {
      field: "card_status",
      headerName: "Status",
      minWidth: 160,
      renderCell: ({ row }) => (
        <Button
          startIcon={<PreviewIcon />}
          onClick={() => {
            onViewStatusHistory(row.ws_card_id)
          }}
        >
          {row.card_status}
        </Button>
      ),
    },
    {
      field: "card_api_provider",
      headerName: "Provider",
      minWidth: 160,
    },
    {
      field: "network",
      headerName: "Network",
      minWidth: 160,
    },
    {
      field: "external_card_id",
      headerName: "External Card ID",
      flex: 1,
      minWidth: 100,
    },
    {
      field: "fulfillment_status",
      headerName: "Fulfillment Status",
      minWidth: 160,
      renderCell: ({ row }) => (
        <Button
          startIcon={<PreviewIcon />}
          onClick={() => {
            onViewFulfillmentHistory(row.ws_card_id)
          }}
        >
          {row.fulfillment_status}
        </Button>
      ),
    },
    {
      field: "currency",
      headerName: "Currency",
      minWidth: 80,
    },
    {
      field: "created_at",
      headerName: "Created At",
      minWidth: 140,
      ...dateTimeColumnDefaultOptions(),
    },
    {
      field: "updated_at",
      headerName: "Updated At",
      minWidth: 140,
      ...dateTimeColumnDefaultOptions(),
    },
    {
      field: "actions",
      type: "actions",
      width: 100,
      getActions: ({ row }) => {
        return [
          <UserDebitcardUpdateStatusAction
            cardId={row.ws_card_id}
            userId={row.user_id}
            status={row.card_status}
          />,
          <UserDebitcardCancelAction
            cardId={row.ws_card_id}
            userId={row.user_id}
            status={row.card_status}
          />,
          <UserDebitcardReissueAction
            accountId={row.account_id}
            status={row.card_status}
          />,
        ]
      },
    },
  ]
}

export const useDebitcardColumns = ({
  onViewStatusHistory,
  onViewFulfillmentHistory,
}: UseColumns) =>
  useMemo(
    () => getColumns({ onViewStatusHistory, onViewFulfillmentHistory }),
    [onViewStatusHistory, onViewFulfillmentHistory]
  )

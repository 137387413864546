import { useMutation, useQueryClient } from "@tanstack/react-query"
import type { ManualTransaction } from "api"
import { endpoints } from "api"
import type { AxiosError } from "axios"
import useRootActions from "query-hooks/useRootActions"

export default function useManualTransfer(
  options?: Partial<{
    onSuccess: () => void
    onError: (e: AxiosError) => void
  }>
) {
  const endpoint = endpoints.banking.manualTransaction
  const queryClient = useQueryClient()

  return useMutation(
    ({ data }: { data: ManualTransaction }) => endpoint().post(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(useRootActions.getQueryKey())
        options?.onSuccess?.()
      },
      onError: (e: AxiosError) => {
        options?.onError?.(e)
      },
    }
  )
}

import AutorenewIcon from "@mui/icons-material/Autorenew"
import useDebitcardReprocessApplicationUS from "query-hooks/useDebitcardApplicationsUSReprocess"
import { ConfirmationDialog } from "components"
import { useState } from "react"
import { Button } from "@mui/material"

function UserDebitcardApplicationRetryAction({ userId }: { userId: string }) {
  const { mutate, isLoading } = useDebitcardReprocessApplicationUS(userId, {
    onSuccess: () => {
      setShowConfirm(false)
    },
  })
  const [showConfirm, setShowConfirm] = useState(false)

  return (
    <>
      <Button
        style={{ marginBottom: "1rem" }}
        variant="outlined"
        onClick={() => {
          setShowConfirm(true)
        }}
      >
        <AutorenewIcon /> Reprocess application
      </Button>
      <ConfirmationDialog
        open={showConfirm}
        title="Confirm application reprocess"
        isLoading={isLoading}
        onConfirm={() => {
          mutate()
        }}
        onCancel={() => setShowConfirm(false)}
      >
        Are you sure you want to reprocess paycard application?
      </ConfirmationDialog>
    </>
  )
}

export default UserDebitcardApplicationRetryAction

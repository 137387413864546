import { styled } from "@mui/system"

export const FormStyleHardOverwrites = styled("span")`
  & > .rjsf > .MuiFormControl-root > span > .MuiGrid-container > .MuiGrid-item {
    position: relative;
    padding-bottom: 25px;

    &:has(.MuiTypography-caption) {
      padding-bottom: 15px;
    }

    :after {
      position: absolute;
      border-radius: 50%;
      content: "";
      bottom: 0;
      left: 50%;
      width: 4px;
      height: 4px;
      background: #ddd;
    }
  }
`

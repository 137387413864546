import { useQuery } from "@tanstack/react-query"
import type { PaydaysCompany } from "api"
import { endpoints } from "api"

export const getQueryKey = () => ["companies"] as const

function useCompanies(
  options?: Partial<{
    onSuccess: (data: PaydaysCompany[]) => void
    onError: () => void
  }>
) {
  const endpoint = endpoints.companies.all()

  return useQuery(getQueryKey(), endpoint.get, {
    select: (data) => data.data.data,
    onSuccess: (data) => options?.onSuccess?.(data),
    onError: () => options?.onError?.(),
  })
}

useCompanies.getQueryKey = getQueryKey

export default useCompanies

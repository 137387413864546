import { isAxiosError } from "./isAxiosError"

type PaydaysMutationErrorPayload = {
  message?: string
}

export function pickErrorMessage(error: unknown): string | null {
  if (
    isAxiosError<PaydaysMutationErrorPayload>(error) &&
    error.response?.data?.message
  ) {
    return error.response.data.message
  }

  return error instanceof Error ? error.message : null
}

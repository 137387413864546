import { useState } from "react"
import { IconButton, Tooltip, Typography } from "@mui/material"
import DeleteIcon from "@mui/icons-material/Delete"
import { pickErrorMessage } from "utils/pickErrorMessage"
import { useNotification } from "context/notification"
import { ConfirmationDialog } from "components"
import useDeletePayScheduleDate from "../../query-hooks/useDeletePayScheduleDate"

interface DeleteActionProps {
  payScheduleId: string
  payScheduleDateId: string
}

export function DeleteAction({
  payScheduleId,
  payScheduleDateId,
}: DeleteActionProps) {
  const notify = useNotification()
  const [showConfirm, setShowConfirm] = useState(false)

  const { mutate, isLoading, error } = useDeletePayScheduleDate(
    payScheduleId,
    payScheduleDateId,
    {
      onSuccess: () => {
        closeConfirm()
        notify.that("pay schedule date").successfully("deleted")
      },
    }
  )

  const openConfirm = () => setShowConfirm(true)
  const closeConfirm = () => setShowConfirm(false)
  const handleConfirm = () => mutate()

  return (
    <>
      {showConfirm && (
        <ConfirmationDialog
          open
          isLoading={isLoading}
          errorMessage={pickErrorMessage(error)}
          title="Delete pay schedule date"
          primaryButtonText="Delete"
          onCancel={closeConfirm}
          onConfirm={handleConfirm}
        >
          <Typography>
            Are you sure you want to delete this pay schedule date?
          </Typography>
        </ConfirmationDialog>
      )}
      <Tooltip title="Delete">
        <IconButton onClick={openConfirm} disabled={isLoading}>
          <DeleteIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

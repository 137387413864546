import { ErrorBoundary, withProfiler } from "@sentry/react"
import { memo } from "react"
import { BrowserRouter } from "react-router-dom"
import { SentryRoutes } from "sentry/sentry"
import { rootRoute } from "routes"
import { ErrorFallback } from "components"

function App() {
  return (
    <ErrorBoundary
      showDialog
      fallback={(error) => (
        <div style={{ padding: 4 }}>
          <ErrorFallback {...error} />
        </div>
      )}
    >
      <BrowserRouter>
        <SentryRoutes>{rootRoute}</SentryRoutes>
      </BrowserRouter>
    </ErrorBoundary>
  )
}

export default memo(withProfiler(App))

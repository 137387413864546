import { useMutation, useQueryClient } from "@tanstack/react-query"
import { endpoints } from "api"
import useDebitcardApplicationsUS from "./useDebitcardApplicationsUS"
export const getQueryKey = () => ["debitcard_us", "application"] as const

function useDebitcardReprocessApplicationUS(
  user_id: string,
  options?: Partial<{
    onSuccess: () => void
    onError: () => void
  }>
) {
  const endpoint = endpoints.debitCardUS.reprocessApplication(user_id)
  const queryClient = useQueryClient()

  return useMutation([...getQueryKey(), user_id], endpoint.post, {
    onSuccess: () => {
      queryClient.invalidateQueries(useDebitcardApplicationsUS.getQueryKey())
      options?.onSuccess?.()
    },
    onError: () => {
      options?.onError?.()
    },
  })
}

useDebitcardReprocessApplicationUS.getQueryKey = getQueryKey

export default useDebitcardReprocessApplicationUS

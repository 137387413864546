import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = (id?: string) =>
  ["discounts", "get_category", id] as const

const useCategory = (id?: string) => {
  const endpoint = endpoints.discounts.category(id)

  return useQuery(getQueryKey(id), endpoint.get, {
    select: (data) => data.data.data,
  })
}

useCategory.getQueryKey = getQueryKey

export default useCategory

import { type GridColumns } from "@mui/x-data-grid"
import type { PaymentAccountUS } from "api"
import { dateTimeColumnDefaultOptions } from "utils/columns"

export const USER_PAYMENT_ACCOUNT_COLUMNS: GridColumns<PaymentAccountUS> = [
  {
    field: "account_id",
    headerName: "Payment Account ID",
    minWidth: 160,
  },
  {
    field: "account_type",
    headerName: "Account Type",
    minWidth: 100,
  },
  {
    field: "banking_provider",
    headerName: "Banking Provider",
    flex: 1,
    minWidth: 100,
  },
  {
    field: "external_account_id",
    headerName: "External Account ID",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "enduser_id",
    headerName: "External User ID",
    flex: 1,
    minWidth: 150,
  },
  {
    field: "date_created",
    headerName: "Created At",
    minWidth: 140,
    ...dateTimeColumnDefaultOptions(),
  },
  {
    field: "close_pending_at",
    headerName: "Close Pending At",
    minWidth: 140,
    ...dateTimeColumnDefaultOptions(),
  },
  {
    field: "closed_at",
    headerName: "Closed At",
    minWidth: 140,
    ...dateTimeColumnDefaultOptions(),
  },
]

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { endpoints } from "api"
import { getQueryKey } from "./usePaySchedule"

function useDeletePayScheduleDate(
  payScheduleId: string,
  payScheduleDateId: string,
  options?: Partial<{
    onSuccess: () => void
    onError: () => void
  }>
) {
  const queryClient = useQueryClient()
  const endpoint = endpoints.paySchedules.dates.byDateId(
    payScheduleId,
    payScheduleDateId
  )

  return useMutation(endpoint.delete, {
    onSuccess: () => {
      queryClient.invalidateQueries(getQueryKey(payScheduleId))
      options?.onSuccess?.()
    },
    onError: () => {
      options?.onError?.()
    },
  })
}

export default useDeletePayScheduleDate

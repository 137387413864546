import type { GridColumns } from "@mui/x-data-grid"

const COLUMNS: GridColumns<{ name: string }> = [
  {
    field: "name",
    headerName: "Name",
    width: 200,
    renderCell: ({ value }) => <a href={`queue?category=${value}`}>{value}</a>,
  },
  {
    field: "description",
    headerName: "Description",
    width: 500,
  },
  {
    field: "request_element",
    headerName: "Request",
    width: 200,
    renderCell: ({ value }) => value,
  },
]

export default COLUMNS

import type { ReactNode } from "react"
import { useLayoutEffect } from "react"
import { useLocation } from "react-router-dom"

export function ScrollToTopRoute({ children }: { children: ReactNode }) {
  const location = useLocation()

  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0)
  }, [location.pathname])

  return <>{children}</>
}

import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = (segmentId?: string) =>
  ["segments", segmentId, "dependents"] as const

function useSegmentsDependents(segmentId?: string) {
  const endpoint = endpoints.campaigns.segments.dependents(segmentId)

  return useQuery(getQueryKey(segmentId), endpoint.get, {
    enabled: Boolean(segmentId),
    select: (data) => data.data.data,
  })
}

useSegmentsDependents.getQueryKey = getQueryKey

export default useSegmentsDependents

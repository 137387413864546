import { Box, Button } from "@mui/material"
import PreviewIcon from "@mui/icons-material/Preview"
import type { GridColumns } from "@mui/x-data-grid"
import type { PaydaysDeviceExperiment } from "api"
import { useMemo } from "react"
import { dateTimeColumnDefaultOptions } from "utils/columns"

type UseColumns = {
  onViewProperties?: (request: [string, object]) => void
}

const getColumns = ({
  onViewProperties,
}: UseColumns): GridColumns<PaydaysDeviceExperiment> => [
  {
    field: "created_at",
    headerName: "Created At",
    flex: 2,
    ...dateTimeColumnDefaultOptions(),
  },
  {
    field: "experiment_code",
    headerName: "Experiment Code",
    flex: 2,
  },
  {
    field: "experiment_name",
    headerName: "Experiment Name",
    flex: 2,
  },
  {
    field: "active",
    headerName: "Active",
    type: "boolean",
    flex: 1,
  },
  {
    field: "treatment_code",
    headerName: "Treatment Code",
    flex: 2,
  },
  {
    field: "treatment_name",
    headerName: "Treatment Name",
    flex: 2,
  },
  {
    field: "assigned_at",
    headerName: "Assigned At",
    flex: 2,
    ...dateTimeColumnDefaultOptions(),
  },
  {
    field: "treatment_properties",
    headerName: "Treatment Properties",
    flex: 2,
    renderCell: ({ value, row }) => (
      <Box color="grey.600" mx={2}>
        <Button
          variant="outlined"
          color="inherit"
          startIcon={<PreviewIcon />}
          onClick={() => onViewProperties?.([row.treatment_name, value])}
        >
          Open
        </Button>
      </Box>
    ),
  },
]

export const useColumns = ({ onViewProperties }: UseColumns) =>
  useMemo(() => getColumns({ onViewProperties }), [onViewProperties])

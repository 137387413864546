import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"
export const getQueryKey = () => ["debitcard_us"] as const

function useDebitcardUS(user_id: string) {
  const endpoint = endpoints.debitCardUS.list(user_id)

  return useQuery([...getQueryKey(), user_id], endpoint.get, {
    select: (data) => data.data,
  })
}

useDebitcardUS.getQueryKey = getQueryKey

export default useDebitcardUS

import { Grid } from "@mui/material"
import { CollapsibleCard, SimpleGrid } from "components"
import ManalTransactionRequest from "./ManualActionRequest"
import COLUMNS from "./ActionRequest.columns"

const ROWS = [
  {
    name: "banking.transactions",
    description: "Make a manual transfer from one ledger to another",
    request_element: <ManalTransactionRequest />,
  },
]

export default function ActionRequests() {
  return (
    <Grid item md={16}>
      <CollapsibleCard type="main">
        <SimpleGrid
          idField="name"
          rows={ROWS}
          columns={COLUMNS}
          quickFilter
          pagination
          disableColumnFilter={false}
          rowsPerPageOptions={[100, 500, 99999]}
        />
      </CollapsibleCard>
    </Grid>
  )
}

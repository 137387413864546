import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = (deviceId?: string) =>
  ["trials", "deviceId", deviceId] as const

function useDeviceExperiments(deviceId?: string) {
  const endpoint = endpoints.devices.experiments(deviceId)
  const enabled = Boolean(deviceId)

  return useQuery(getQueryKey(deviceId), endpoint.get, {
    enabled,
    select: (data) => data.data.data,
  })
}

useDeviceExperiments.getQueryKey = getQueryKey

export default useDeviceExperiments

import { Box } from "@mui/material"
import { Widgets } from "@rjsf/mui"
import type { WidgetProps } from "@rjsf/utils"

const EmptyLabel = () => <Box height="1em"></Box>

export function SelectWidget(props: WidgetProps) {
  const { options, multiple } = props

  // The Element can be handled by the library because it's just the children prop of MenuItem
  // https://github.com/rjsf-team/react-jsonschema-form/blob/v5.0.0-beta.13/packages/mui/src/SelectWidget/SelectWidget.tsx#L62-L64
  const enumOptions =
    options.enumOptions?.length && !multiple
      ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [{ value: "", label: (<EmptyLabel />) as any }, ...options.enumOptions]
      : options.enumOptions

  const patchedOptions = { ...options, enumOptions }

  return <Widgets.SelectWidget {...props} options={patchedOptions} />
}

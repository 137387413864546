import { differenceInHours, add, parseISO } from "date-fns"
import { useGridQueryParamFilter } from "hooks/useGridQueryParamFilter"
import useAppReleaseVersions from "pages/Employee/query-hooks/useAppReleaseVersions"
import {
  Card,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material"
import { CollapsibleCard, SimpleGrid } from "components"
import { formatDate, DATE_FORMATS } from "utils/date"
import { StyledTableHeadCell } from "utils/tables"
import COLUMNS, { parseReleaseDuration } from "./AppReleases.columns"
import "./AppReleasesList.css"

export default function AppReleasesList() {
  const [filterModel, onFilterModelChange] = useGridQueryParamFilter()
  const { data, isLoading, error } = useAppReleaseVersions()

  const latestFullyRolledOutRelease = data?.find(
    (item) => !item.invalid_at && item.is_fully_rolled_out
  )
  const latestNonFullyRolledOutRelease = data?.find(
    (item) =>
      !item.invalid_at &&
      !item.is_fully_rolled_out &&
      latestFullyRolledOutRelease?.deployment_start &&
      item.deployment_start &&
      new Date(item.deployment_start) >
        new Date(latestFullyRolledOutRelease.deployment_start)
  )

  let latestReleaseRolloutPercentage = "0"

  if (latestNonFullyRolledOutRelease) {
    const deploymentStart = parseISO(
      latestNonFullyRolledOutRelease.deployment_start
    )
    const deploymentDuration =
      latestNonFullyRolledOutRelease.deployment_duration

    const parseDuration = (duration: string) => {
      const durationParts = duration.split(", ")
      const days = parseInt(durationParts[0].split(" ")[0], 10)
      const hours = parseInt(durationParts[1].split(":")[0], 10)
      return { days, hours }
    }

    const { days, hours } = parseDuration(deploymentDuration)
    const deploymentEnd = add(deploymentStart, { days, hours: hours })
    const now = new Date()
    const hoursPassed = differenceInHours(now, deploymentStart)
    const totalDurationHours = differenceInHours(deploymentEnd, deploymentStart)

    latestReleaseRolloutPercentage = `${Math.min(
      (hoursPassed / totalDurationHours) * 100,
      100
    ).toFixed(2)}`
  }

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {!isLoading && (
          <>
            {latestNonFullyRolledOutRelease && (
              <CollapsibleCard
                title="Active Release Rollout"
                type="detail"
                subtitle="The latest release not yet available to all users"
                startExpanded
              >
                <TableContainer component={Card}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <StyledTableHeadCell>Version</StyledTableHeadCell>
                        <TableCell>
                          {latestNonFullyRolledOutRelease.version}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableHeadCell>
                          Percentage Rollout
                        </StyledTableHeadCell>
                        <TableCell>{latestReleaseRolloutPercentage}%</TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableHeadCell>
                          Released to Store
                        </StyledTableHeadCell>
                        <TableCell>
                          {latestNonFullyRolledOutRelease.deployed_to_store
                            ? "Yes"
                            : "No"}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </CollapsibleCard>
            )}
            <CollapsibleCard
              title="Latest Fully Rolled Out Release"
              startExpanded={!latestNonFullyRolledOutRelease}
            >
              {latestFullyRolledOutRelease ? (
                <TableContainer component={Card}>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <StyledTableHeadCell>Version</StyledTableHeadCell>
                        <TableCell>
                          {latestFullyRolledOutRelease.version}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableHeadCell>
                          Deployment Start
                        </StyledTableHeadCell>
                        <TableCell>
                          {formatDate(
                            latestFullyRolledOutRelease.deployment_start,
                            DATE_FORMATS.standardShortDateTime
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableHeadCell>
                          Deployment Duration
                        </StyledTableHeadCell>
                        <TableCell>
                          {parseReleaseDuration(
                            latestFullyRolledOutRelease.deployment_duration
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableHeadCell>
                          Minimum Native Wrapper Version
                        </StyledTableHeadCell>
                        <TableCell>
                          {
                            latestFullyRolledOutRelease.minimum_native_wrapper_version
                          }
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <StyledTableHeadCell>Created At</StyledTableHeadCell>
                        <TableCell>
                          {formatDate(
                            latestFullyRolledOutRelease.created_at,
                            DATE_FORMATS.standardShortDateTime
                          )}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ) : (
                <Typography>No fully rolled out release found.</Typography>
              )}
            </CollapsibleCard>
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <SimpleGrid
          getRowClassName={(params) =>
            params.row.invalid_at ? "invalid-row" : ""
          }
          idField="mobile_application_version_id"
          initialState={{
            sorting: {
              sortModel: [{ field: "deployment_start", sort: "desc" }],
            },
          }}
          rows={data || []}
          columns={COLUMNS}
          error={error}
          quickFilter
          pagination
          filterModel={filterModel}
          onFilterModelChange={onFilterModelChange}
          disableColumnFilter={false}
          rowsPerPageOptions={[100, 500, 99999]}
          isLoading={isLoading}
        />
      </Grid>
    </Grid>
  )
}

import { Checkbox, Stack, FormControlLabel, Box } from "@mui/material"
import { GridToolbarContainer, GridToolbarQuickFilter } from "@mui/x-data-grid"

interface DeviceExperimentsGridToolbarProps {
  includeInactiveExperiments: boolean
  toggleInactiveExperiments(): void
}

export function DeviceExperimentsGridToolbar({
  includeInactiveExperiments,
  toggleInactiveExperiments,
}: DeviceExperimentsGridToolbarProps) {
  return (
    <GridToolbarContainer>
      <Stack flex={1} spacing={2} direction={{ xs: "column", lg: "row" }}>
        <GridToolbarQuickFilter
          variant="outlined"
          size="small"
          sx={{
            // NOTE: Pushes the checkbox out to the right
            marginRight: "auto",
            "& .MuiOutlinedInput-root": {
              backgroundColor: "background.paper",
            },
          }}
        />
        {/* NOTE: Wrapper is needed because Stack reset the margin of its direct children in narrower viewports, while Checkbox's relies on a -marginLeft for it's positioning. */}
        <Box display="flex" alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={includeInactiveExperiments}
                onChange={toggleInactiveExperiments}
              />
            }
            label="Show inactive experiments"
          />
        </Box>
      </Stack>
    </GridToolbarContainer>
  )
}

type ActionPayload<Payload> = [Payload] extends [never]
  ? { payload?: never }
  : { payload: Payload }

export type Action<
  Type extends string,
  Payload = never,
  IsInternal extends boolean = false
> = { type: Type; _internal?: IsInternal } & ActionPayload<Payload>

export type UIAction<Action> = Action extends { _internal?: false }
  ? Omit<Action, "_internal">
  : never

export type Effectful<
  BaseAction,
  SuccessPayload = never,
  ErrorPayload = Error
> =
  | BaseAction
  | (BaseAction extends { type: infer Type extends string }
      ?
          | Action<`${Type}_SUCCESS`, SuccessPayload, true>
          | Action<`${Type}_ERROR`, ErrorPayload, true>
      : never)

interface WithIdentityAndExpProperties {
  identity?: unknown
  exp?: unknown
}

export function hasIdentityAndExpProperties(
  value: object
): value is WithIdentityAndExpProperties {
  return value.hasOwnProperty("identity") && value.hasOwnProperty("exp")
}

export interface AuthUser {
  identity: string
  exp: number
}

export function isAuthUser(value: unknown): value is AuthUser {
  return (
    typeof value === "object" &&
    value !== null &&
    hasIdentityAndExpProperties(value) &&
    typeof value.identity === "string" &&
    typeof value.exp === "number"
  )
}

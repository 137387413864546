import { Box } from "@mui/material"

export function NoRowsOverlay({ message }: { message: string }) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      p={4}
      minHeight={100}
    >
      {message}
    </Box>
  )
}

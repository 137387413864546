import {
  Box,
  Card as MuiCard,
  CardContent,
  CardHeader,
  Collapse,
  IconButton,
  Stack,
  Typography,
} from "@mui/material"
import type { MouseEventHandler } from "react"
import React, { useState } from "react"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import { ErrorBoundary } from "@sentry/react"
import { HourglassBottom } from "@mui/icons-material"
import { Searchable, ExpandMore, ErrorFallback } from "components"

type CardType = "detail" | "section" | "main" | "inner" | "action"

export type CollapsibleCardProps = {
  /** Title shown when card is both expanded and collapsed */
  title?: string
  /** SubTitle is shown when card is expanded only */
  subtitle?: string
  action?: React.ReactNode
  children?: React.ReactNode
  disabled?: boolean
  loading?: boolean
  /** On load, the card will be initially expanded */
  startExpanded?: boolean
  /** Disable the search bar */
  searchDisabled?: boolean
  /** Hide the option to collapse, use for forms where extry is controlled by the completion of an action */
  hideCollapse?: boolean
  /** Control stying and behaviour in specfic scenarios.
   * - detail: default, used for most cards. The whole card header is clikcable and expands/collapses the card.
   * - section: when you have more than one card on a page, then one card might be main and additional cards might be section. Decreases prominence of the title compared to cards with type main. Only the collapse icon expanded/collapses the card
   * - main: when you have more than one card on a page, then the main card should be main. Increases prominence of the title compared to cards with type section.  Only the collapse icon expanded/collapses the card
   * - inner: used for cards inside other cards. Increases padding top and disables search.  Only the collapse icon expanded/collapses the card
   * - action: used for cards that and are inner cards that contain a form (i.e. have an action for the user to complete). Displays the header with a purple background so it stands out. The whole card header is clikcable and expands/collapses the card.
   */
  type?: CardType
  hidden?: boolean
}

function CollapsibleCard({
  title: titleText,
  subtitle,
  action: additionalAction,
  children,
  hideCollapse,
  loading,
  disabled = loading,
  searchDisabled,
  type = "detail",
  startExpanded: expandedByDefault,
  hidden = false,
}: CollapsibleCardProps) {
  const isDetail = type === "detail"
  const isSection = type === "section"
  const isMain = type === "main"
  const isInner = type === "inner"
  const isAction = type === "action"

  const [expanded, setExpanded] = useState(
    expandedByDefault ?? (isSection || isMain)
  )

  const clickExpand: MouseEventHandler<Element> = (e) => {
    e.stopPropagation()
    !disabled && setExpanded(!expanded)
  }

  const action = (
    <Stack direction="row" sx={{ ml: 1 }}>
      {loading && (
        <IconButton disabled>
          <HourglassBottom />
        </IconButton>
      )}
      {expanded ? additionalAction : null}
      {!hideCollapse && (
        <ExpandMore
          color={isAction ? "inherit" : undefined}
          aria-label={expanded ? "collapse card" : "expand card"}
          disabled={disabled}
          expand={expanded}
          onClick={clickExpand}
        >
          <ExpandMoreIcon />
        </ExpandMore>
      )}
    </Stack>
  )

  const title = (
    <Typography
      color={disabled ? "grey.500" : ""}
      variant={isMain || isSection ? "h3" : "h4"}
      gutterBottom={isMain}
      component="div"
    >
      {titleText}
    </Typography>
  )

  return (
    <Searchable
      disabled={isInner || isAction || searchDisabled}
      title={titleText}
    >
      <MuiCard
        component="section"
        hidden={hidden}
        sx={{
          cursor: disabled ? "not-allowed" : "",
          pb: isSection ? 3 : 2,
          py: isMain ? 2 : undefined,
          px: isMain ? 2 : 0,
        }}
      >
        <CardHeader
          component="header"
          role="heading"
          title={title}
          action={isMain ? null : action}
          sx={{
            display: !titleText ? "none" : undefined,
            borderRadius: 0,
            "& .MuiCardHeader-action": {
              alignSelf: "center",
            },
            minHeight: 40,
            color: ({ palette }) =>
              isAction ? palette.common.white : undefined,
            background: ({ palette }) =>
              isAction ? palette.primary.main : undefined,
            whiteSpace: "nowrap",
            cursor: isAction || isDetail ? "pointer" : "",
            pt: isDetail || isInner || isAction ? 2 : 3,
            pb: 2,
            px: 5,
            mb: -2,
          }}
          onClick={isAction || isDetail ? clickExpand : void 0}
        />
        <Collapse in={expanded} mountOnEnter timeout={200}>
          <CardContent sx={{ px: 5 }}>
            <Box sx={{ pb: 1 }} />
            {Boolean(subtitle) && (
              <Typography
                color="grey.500"
                sx={{ mt: -5, mb: 5 }}
                variant="subtitle2"
                gutterBottom
              >
                {subtitle}
              </Typography>
            )}
            <ErrorBoundary
              fallback={(error) => <ErrorFallback {...error} />}
              beforeCapture={(scope) => {
                scope.setTag("card", titleText)
              }}
            >
              {children}
            </ErrorBoundary>
          </CardContent>
        </Collapse>
      </MuiCard>
    </Searchable>
  )
}

export { CollapsibleCard }

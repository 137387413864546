import assert from "assert"
import { useMemo, useState } from "react"
import {
  Box,
  Button,
  Card,
  Checkbox,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material"
import type { Control } from "react-hook-form"
import { Controller } from "react-hook-form"
import { CollapsibleCard, ControlledTextField } from "components"
import { safeHtmlDecode } from "utils/safeHtmlDecode"
import { DatePicker } from "@mui/x-date-pickers"
import { parseDate } from "utils/date"
import type { PaydaysSegmentPropertiesFilter } from "api"
import type { SegmentBuilderFormValues } from "../../SegmentBuilderDialog"
import { FILTER_OPTIONS } from "./constants/filterOptions"
import { AddSegmentFilter } from "./components/AddSegmentFilter/AddSegmentFilter"
import { ListFilterField } from "./components/ListFilterField/ListFilterField"
import { MultiSelectFilterField } from "./components/MultiSelectFilterField/MultiSelectFilterField"

interface SegmentFiltersProps {
  filterFields: (Omit<PaydaysSegmentPropertiesFilter, "values"> & {
    values: (string | boolean | Date | string[])[]
  })[]
  control: Control<SegmentBuilderFormValues>
  removeFilterField: (index: number) => void
  appendFilterField: (
    filter: typeof FILTER_OPTIONS[number] & {
      values: (string | boolean | Date | string[])[]
    }
  ) => void
}

export function SegmentFilters({
  control,
  filterFields,
  removeFilterField,
  appendFilterField,
}: SegmentFiltersProps) {
  const unusedFilters = useMemo(
    () =>
      FILTER_OPTIONS.filter(
        ({ id }) => !filterFields.find((filterField) => filterField.id === id)
      ),
    [filterFields]
  )

  const [filterIdToAdd, setFilterIdToAdd] = useState<string>(
    unusedFilters?.[0].id || ""
  )

  return (
    <CollapsibleCard title="Filters" type="inner" startExpanded hideCollapse>
      <Stack gap={8}>
        <TableContainer component={Card}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Values</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filterFields.map((filter, fieldIndex) => (
                <TableRow key={filter.id}>
                  <TableCell>
                    <strong>{filter.name}</strong>
                    <br />
                    {filter.description}
                  </TableCell>
                  <TableCell>
                    <Stack direction="row" alignItems="center" gap={4}>
                      {filter?.prepend ? (
                        <Box sx={{ whiteSpace: "nowrap", ml: 4 }}>
                          {safeHtmlDecode(filter?.prepend)}
                        </Box>
                      ) : null}
                      {filter.types.map((type, typeIndex) => {
                        const name =
                          `properties.filters.${fieldIndex}.values.${typeIndex}` as const

                        switch (type) {
                          case "multi":
                            return (
                              <MultiSelectFilterField
                                key={typeIndex}
                                name={name}
                                control={control}
                                filter={filter}
                              />
                            )
                            break
                          case "list":
                            return (
                              <ListFilterField
                                key={typeIndex}
                                name={name}
                                control={control}
                                filter={filter}
                              />
                            )
                            break
                          case "comparison":
                          case "equals":
                            return (
                              <ControlledTextField
                                key={typeIndex}
                                name={name}
                                control={control}
                                fieldProps={{
                                  type: "number",
                                  size: "small",
                                  autoComplete: "off",
                                }}
                                rules={{
                                  required: true,
                                  validate: (value) =>
                                    Number(value) > 0 ||
                                    "Value must by a number greater than 0",
                                }}
                              />
                            )
                            break
                          case "boolean":
                            return (
                              <Checkbox
                                key={typeIndex}
                                disabled
                                size="small"
                                checked={true}
                              />
                            )
                            break
                          case "date":
                            return (
                              <Controller
                                key={typeIndex}
                                name={name}
                                control={control}
                                render={({ field, fieldState: { error } }) => (
                                  <DatePicker
                                    {...field}
                                    renderInput={(params) => (
                                      <TextField
                                        size="small"
                                        fullWidth
                                        error={Boolean(error)}
                                        helperText={error?.message}
                                        {...params}
                                      />
                                    )}
                                  />
                                )}
                                rules={{
                                  required: true,
                                  validate: (value) =>
                                    (value instanceof Date &&
                                      !!parseDate(value)) ||
                                    "Date is not correctly formatted",
                                }}
                              />
                            )
                            break
                          default:
                            throw new Error(`Unsupported filter type: ${type}`)
                        }
                      })}
                      {filter?.append ? (
                        <Box sx={{ whiteSpace: "nowrap" }}>
                          {safeHtmlDecode(filter?.append)}
                        </Box>
                      ) : null}
                    </Stack>
                  </TableCell>
                  {/* NOTE: Want this to be as narrow as possible */}
                  <TableCell width="1px">
                    <Button
                      variant="contained"
                      onClick={() => removeFilterField(fieldIndex)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <AddSegmentFilter
          filters={unusedFilters}
          value={filterIdToAdd}
          onValueChange={(filterId) => setFilterIdToAdd(filterId)}
          onValueSubmit={(filterId) => {
            const filterToAdd = FILTER_OPTIONS.find(({ id }) => id === filterId)
            assert(filterToAdd)
            appendFilterField({
              ...filterToAdd,
              values: filterToAdd.types.map((type) =>
                type === "boolean" ? true : ""
              ),
            })
            setFilterIdToAdd(
              unusedFilters.filter(({ id }) => id !== filterId)?.[0].id || ""
            )
          }}
        />
      </Stack>
    </CollapsibleCard>
  )
}

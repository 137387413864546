import assert from "assert"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { LoadingButton } from "@mui/lab"
import { Alert, MenuItem, Stack } from "@mui/material"
import { CollapsibleCard, ControlledTextField } from "components"
import usePatchPaySchedule from "pages/PaySchedulesDates/query-hooks/usePatchPaySchedule"
import usePaySchedule from "pages/PaySchedulesDates/query-hooks/usePaySchedule"
import useCompanies from "query-hooks/useCompanies"
import { ACCRUAL, PERIODICITY, TIMEZONES } from "utils/constants"
import { useNotification } from "context/notification"
import { pickErrorMessage } from "utils/pickErrorMessage"

function PayScheduleDetailsContent() {
  const { id } = useParams()
  assert(id)
  const notify = useNotification()

  const { data: companies, isLoading: isLoadingCompanies } = useCompanies()
  const { data: paySchedule } = usePaySchedule(id)
  const {
    mutate,
    isLoading: isLoadingPatch,
    error,
  } = usePatchPaySchedule(id, {
    onSuccess: () => notify.that("pay schedule").successfully("updated"),
    onError: () => notify.that("pay schedule").erroredWhile("updating"),
  })

  const { handleSubmit, control } = useForm({
    defaultValues: {
      name: paySchedule?.name,
      accrual_type: paySchedule?.accrual_type,
      company_id: paySchedule?.company_id,
      periodicity: paySchedule?.periodicity,
      timezone: paySchedule?.timezone || "",
      paid_on_timezone: paySchedule?.paid_on_timezone || "",
    },
  })

  return (
    <form
      onSubmit={handleSubmit((values) => {
        const filteredObject = Object.fromEntries(
          Object.entries(values).filter(
            (entry): entry is [string, string] => !!entry[1]
          )
        )

        mutate({ pay_schedule_id: id, ...filteredObject })
      })}
    >
      <Stack spacing={4}>
        {error ? (
          <Alert severity="error">{pickErrorMessage(error)}</Alert>
        ) : null}
        <ControlledTextField
          name="name"
          control={control}
          rules={{ required: true }}
          label="Name"
        />
        <ControlledTextField
          name="accrual_type"
          control={control}
          rules={{ required: true }}
          label="Accrual Type"
          fieldProps={{ select: true }}
        >
          {Object.entries(ACCRUAL).map(([value, label]) => (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          ))}
        </ControlledTextField>
        <ControlledTextField
          name="company_id"
          control={control}
          rules={{ required: true }}
          label="Company"
          disabled={isLoadingCompanies}
          fieldProps={{ select: true }}
        >
          {companies
            ?.sort((a, b) => a.name.localeCompare(b.name))
            .map(({ company_id, name }) => (
              <MenuItem key={company_id} value={company_id}>
                {name}
              </MenuItem>
            )) || (
            <MenuItem value={paySchedule?.company_id}>Loading...</MenuItem>
          )}
        </ControlledTextField>
        <ControlledTextField
          name="periodicity"
          control={control}
          rules={{ required: true }}
          label="Periodicity"
          fieldProps={{ select: true }}
        >
          {Object.entries(PERIODICITY).map(([key, label]) => (
            <MenuItem key={key} value={key}>
              {label}
            </MenuItem>
          ))}
        </ControlledTextField>
        <ControlledTextField
          name="timezone"
          control={control}
          rules={{ required: false }}
          label="Accrual Timezone"
          fieldProps={{ select: true }}
        >
          {Object.entries(TIMEZONES).map(([value, { text, ...props }]) => (
            <MenuItem key={value} value={value} {...props}>
              {text}
            </MenuItem>
          ))}
        </ControlledTextField>
        <ControlledTextField
          name="paid_on_timezone"
          control={control}
          rules={{ required: false }}
          label="Paid on Timezone"
          fieldProps={{ select: true }}
        >
          {Object.entries(TIMEZONES).map(([value, { text, ...props }]) => (
            <MenuItem key={value} value={value} {...props}>
              {text}
            </MenuItem>
          ))}
        </ControlledTextField>
        <LoadingButton
          loading={isLoadingPatch}
          variant="contained"
          type="submit"
        >
          Update
        </LoadingButton>
      </Stack>
    </form>
  )
}

export function PayScheduleDetails() {
  const { id } = useParams()
  const { isLoading } = usePaySchedule(id)

  return (
    <CollapsibleCard loading={isLoading} title="Pay Schedule Details">
      <PayScheduleDetailsContent />
    </CollapsibleCard>
  )
}

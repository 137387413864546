import { useMutation } from "@tanstack/react-query"
import type { PaydaysSegment } from "api"
import { endpoints } from "api"
import { queryClient } from "utils/queryClient"
import { getQueryKey } from "./useSegments"

function usePostSegment(
  options?: Partial<{
    onSuccess: () => void
    onError: (error: unknown) => void
  }>
) {
  const endpoint = endpoints.campaigns.segments.all()

  return useMutation(
    (payload: Pick<PaydaysSegment, "description" | "sql" | "properties">) =>
      endpoint.post({
        ...payload,
        properties: JSON.stringify(payload.properties),
      }),
    {
      onSuccess: () => {
        options?.onSuccess?.()
        queryClient.invalidateQueries(getQueryKey())
      },
      onError: (error: unknown) => {
        options?.onError?.(error)
      },
    }
  )
}

export default usePostSegment

import assert from "assert"
import type { PaydaysSegmentPropertiesFilter } from "api"
import { parseDateAsLocal } from "utils/date"

export function parseDateValuesInFilter({
  id,
  types,
  values,
  ...rest
}: PaydaysSegmentPropertiesFilter) {
  return {
    id,
    types,
    ...rest,
    values: values.map(
      (value, valueIndex): string | boolean | Date | string[] => {
        if (typeof value === "string" && types[valueIndex] === "date") {
          const dateAsLocal = parseDateAsLocal(value)
          assert(
            dateAsLocal,
            `Not able to parse date value: ${value} belonging to filter ID: ${id}`
          )
          return dateAsLocal
        }
        return value
      }
    ),
  }
}

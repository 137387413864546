import type { FC, PropsWithChildren } from "react"
import { useEffect } from "react"
import { useCallback, useReducer } from "react"
import jwt_decode from "jwt-decode"
import { SESSION_EXPIRED_EVENT_NAME } from "api/client/client"
import { clearToken } from "api"
import type { AuthState, AuthAction } from "./AuthContext"
import { AuthStateContext, AuthDispatchContext } from "./AuthContext"
import { asyncMiddleware } from "./asyncMiddleware"
import { reducer } from "./reducer"
import { isAuthUser } from "./types"
import { getAPIToken } from "./getAPIToken"

export const SESSION_EXPIRED_ERROR_MESSAGE = "Session expired"

const initialState = {
  user: null,
  loading: false,
  redirectToLogin: true,
}

function stateInitializer(initialState: AuthState): AuthState {
  const apiToken = getAPIToken()

  if (apiToken) {
    try {
      const user = jwt_decode(apiToken)
      if (isAuthUser(user)) {
        return {
          user,
          loading: false,
          redirectToLogin: null,
        }
      } else {
        throw new Error()
      }
    } catch {
      clearToken(null)

      return {
        user: null,
        loading: false,
        redirectToLogin: true,
        error: new Error("Error restoring the previous session"),
      }
    }
  }

  return initialState
}

export const AuthProvider: FC<PropsWithChildren> = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState, stateInitializer)

  const dispatchWithMiddleware = useCallback(
    (action: AuthAction) => asyncMiddleware(dispatch, action),
    [dispatch]
  )

  useEffect(() => {
    // NOTE: Will only run in secondary / background window
    function onStorageEvent({ key, newValue }: StorageEvent) {
      if (key === "sessionData") {
        const parsedNewValue = JSON.parse(newValue || "{}")
        const { token, errorMessage } = parsedNewValue
        const hasSessionExpired = errorMessage === SESSION_EXPIRED_ERROR_MESSAGE

        if (token) {
          dispatchWithMiddleware({
            type: "TOKEN_UPDATED",
            payload: token,
          })
        } else if (hasSessionExpired) {
          dispatchWithMiddleware({
            type: "REQUEST_REFRESH",
            payload: {
              isPrimaryWindow: false,
            },
          })
        } else {
          dispatchWithMiddleware({
            type: "LOGOUT",
            payload: {
              isPrimaryWindow: false,
            },
          })
        }
      }
    }

    function onSessionExpiredResponseEvent() {
      if (sessionStorage.getItem("redirectTo")) {
        sessionStorage.setItem("redirectTo", window.location.pathname)
      }

      dispatchWithMiddleware({
        type: "REQUEST_REFRESH",
        payload: {
          isPrimaryWindow: true,
        },
      })
    }

    window.addEventListener("storage", onStorageEvent)
    window.addEventListener(
      SESSION_EXPIRED_EVENT_NAME,
      onSessionExpiredResponseEvent
    )

    return () => {
      window.removeEventListener("storage", onStorageEvent)
      window.removeEventListener(
        SESSION_EXPIRED_EVENT_NAME,
        onSessionExpiredResponseEvent
      )
    }
  }, [dispatchWithMiddleware])

  return (
    <AuthDispatchContext.Provider value={dispatchWithMiddleware}>
      <AuthStateContext.Provider value={state}>
        {props.children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  )
}

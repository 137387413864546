import assert from "assert"
import type { GridColumns } from "@mui/x-data-grid"
import RemoveIcon from "@mui/icons-material/Remove"
import { dateTimeColumnDefaultOptions } from "utils/columns"
import type { PayScheduleDateWithPayScheduleId } from "./PaySchedulesDates"
import { ApproveAction } from "./components/ApproveAction/ApproveAction"
import { EditAction } from "./components/EditAction/EditAction"
import { DeleteAction } from "./components/DeleteAction/DeleteAction"

export const COLUMNS: GridColumns<PayScheduleDateWithPayScheduleId> = [
  {
    field: "pay_period_start",
    headerName: "Pay Period Start",
    minWidth: 140,
    flex: 1,
    ...dateTimeColumnDefaultOptions({ onlyDate: true }),
  },
  {
    field: "pay_period_end",
    headerName: "Pay Period End",
    minWidth: 140,
    flex: 1,
    ...dateTimeColumnDefaultOptions({ onlyDate: true }),
  },
  {
    field: "blackout_period_start",
    headerName: "Blackout Period Start",
    minWidth: 140,
    flex: 1,
    type: "string",
    ...dateTimeColumnDefaultOptions({ onlyDate: true }),
  },
  {
    field: "paid_on",
    headerName: "Paid On",
    minWidth: 140,
    flex: 1,
    ...dateTimeColumnDefaultOptions({ onlyDate: true }),
  },
  {
    field: "suggested_next_paid_on",
    headerName: "Suggested Next Paid On",
    minWidth: 140,
    flex: 1,
    ...dateTimeColumnDefaultOptions({ onlyDate: true }),
  },
  {
    field: "properties",
    headerName: "Properties",
    flex: 2,
    minWidth: 200,
    sortable: false,
    valueGetter: ({ value }) =>
      value ? JSON.stringify(value, null, 2) : undefined,
    renderCell: ({ value }) =>
      value ? value : <RemoveIcon fontSize="small" color="disabled" />,
  },
  {
    field: "actions",
    type: "actions",
    minWidth: 140,
    flex: 0,
    getActions: ({
      id,
      row: {
        pending,
        pay_schedule_id,
        pay_period_start,
        paid_on,
        blackout_period_start,
        properties,
      },
    }) => {
      assert(typeof id === "string")

      const actions = [
        <EditAction
          payScheduleId={pay_schedule_id}
          payScheduleDateId={id}
          payPeriodStart={pay_period_start}
          paidOn={paid_on}
          blackoutPeriodStart={blackout_period_start}
          lockDate={properties?.lock_date}
        />,
        <DeleteAction payScheduleId={pay_schedule_id} payScheduleDateId={id} />,
      ]

      if (pending) {
        actions.unshift(
          <ApproveAction
            payScheduleId={pay_schedule_id}
            payScheduleDateId={id}
          />
        )
      }

      return actions
    },
  },
]

import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = () =>
  ["companies", "integrations", "partners"] as const

function useCompanyIntegrationPartners() {
  const endpoint = endpoints.companies.integrations.partners()
  return useQuery(getQueryKey(), endpoint.get, {
    select: (data) => data.data.data,
  })
}

useCompanyIntegrationPartners.getQueryKey = getQueryKey

export default useCompanyIntegrationPartners

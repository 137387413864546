import { Alert, Box, Stack } from "@mui/material"

export function Instructions() {
  return (
    <>
      <Alert severity="info" variant="filled">
        To upload a file for bulk processing it must conform to the following
        format.
      </Alert>
      <Alert severity="info" icon={false}>
        <Stack component="ul" spacing={2} pl={4}>
          <Box component="li">
            Must be of format .csv (Comma-separated values)
          </Box>
          <Box component="li">
            <Box mb={1}>Have three columns named:</Box>
            <Stack component="ul" spacing={2} pl={4}>
              <Box component="li">pay_period_start</Box>
              <Box component="li">paid_on</Box>
              <Box component="li">blackout_period_start</Box>
            </Stack>
          </Box>
          <Box component="li">
            <Box mb={1}>
              Conform to the following rules. Warnings will appear if violated.
            </Box>
            <Stack component="ul" spacing={2} pl={4}>
              <Box component="li">
                You cannot add a pay period start which is smaller than the
                maximum found for current pay schedule, e.g. if maximum is
                2020-03-01, you can only add entries that are 2020-03-02 and
                older.
              </Box>
              <Box component="li">
                Paid on date must be strictly larger than pay period start. This
                is to prevent a mistake of paying on the same day or earliest
                than pay period start.
              </Box>
              <Box component="li">
                Paid on date must be unique throughout pay schedule.
              </Box>
              <Box component="li">
                Blackout period start must be strictly between pay period start
                and date paid on.
              </Box>
            </Stack>
          </Box>
        </Stack>
      </Alert>
    </>
  )
}

// Based on minimals.cc react-scripts
// src/components/NotistackProvider.tsx
import type { ReactNode } from "react"
import { useRef } from "react"
import type { SnackbarKey, SnackbarProviderProps } from "notistack"
import { SnackbarProvider } from "notistack"
import { IconButton } from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import DoneIcon from "@mui/icons-material/Done"
import InfoIcon from "@mui/icons-material/Info"
import WarningIcon from "@mui/icons-material/Warning"
import ErrorIcon from "@mui/icons-material/Error"
import { SnackbarIcon } from "./SnackbarIcon"
import { SnackbarStyles } from "./SnackbarStyles"

const icons = {
  info: (
    <SnackbarIcon color="info">
      <InfoIcon />
    </SnackbarIcon>
  ),
  success: (
    <SnackbarIcon color="success">
      <DoneIcon />
    </SnackbarIcon>
  ),
  warning: (
    <SnackbarIcon color="warning">
      <WarningIcon />
    </SnackbarIcon>
  ),
  error: (
    <SnackbarIcon color="error">
      <ErrorIcon />
    </SnackbarIcon>
  ),
}

type NotistackProviderProps = {
  children: ReactNode
  options?: Partial<SnackbarProviderProps>
}

export const NotistackProvider = ({
  children,
  options = {},
}: NotistackProviderProps) => {
  const notistackRef = useRef<SnackbarProvider>(null)

  const onClose = (key: SnackbarKey) => () => {
    notistackRef.current?.closeSnackbar(key)
  }

  return (
    <>
      <SnackbarStyles />
      <SnackbarProvider
        ref={notistackRef}
        maxSnack={5}
        preventDuplicate
        autoHideDuration={5000}
        variant="success"
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        iconVariant={icons}
        action={(key) => (
          <IconButton size="large" onClick={onClose(key)}>
            <CloseIcon />
          </IconButton>
        )}
        {...options}
      >
        {children}
      </SnackbarProvider>
    </>
  )
}

import { Alert } from "@mui/material"
import { Page } from "components"

export default function NotFound() {
  return (
    <Page title="Page not found">
      <Alert severity="error" sx={{ width: 1 }}>
        Page not found
      </Alert>
    </Page>
  )
}

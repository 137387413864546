import type { Dispatch } from "react"
import {
  clearToken,
  requestToken,
  requestLoginWithPassword,
  requestLoginWithGoogle,
} from "api"
import jwt_decode from "jwt-decode"
import { requestValidateAccessToken } from "api/api/api"
import type { AuthAction } from "./AuthContext"
import { isAuthUser } from "./types"
import { SESSION_EXPIRED_ERROR_MESSAGE } from "./AuthProvider"
import { getAPIToken } from "./getAPIToken"

function decodeAPIToken(token: string) {
  try {
    const user = jwt_decode(token)

    if (isAuthUser(user)) {
      return user
    } else {
      throw new Error()
    }
  } catch {
    throw new Error("Error updating the session")
  }
}

export const asyncMiddleware = async (
  dispatch: Dispatch<AuthAction>,
  action: AuthAction
) => {
  dispatch(action)

  switch (action.type) {
    case "LOGIN": {
      try {
        await requestLoginWithPassword(action.payload)

        const token = getAPIToken()

        if (!token) {
          throw new Error("Login could not be processed by the browser")
        }

        const user = decodeAPIToken(token)

        dispatch({ type: "LOGIN_SUCCESS", payload: user })
      } catch (error) {
        dispatch({ type: "LOGIN_ERROR", payload: error as Error })
      }

      return
    }
    case "LOGIN_WITH_GOOGLE": {
      try {
        await requestLoginWithGoogle(action.payload)

        dispatch({ type: "LOGIN_WITH_GOOGLE_SUCCESS" })
      } catch (error) {
        dispatch({ type: "LOGIN_WITH_GOOGLE_ERROR", payload: error as Error })
      }

      return
    }
    case "LOGIN_WITH_ACCESS_TOKEN": {
      try {
        await requestValidateAccessToken(action.payload)

        const token = getAPIToken()

        if (!token) {
          throw new Error(
            "Access Token Login could not be processed by the browser"
          )
        }

        const user = decodeAPIToken(token)

        dispatch({ type: "LOGIN_WITH_ACCESS_TOKEN_SUCCESS", payload: user })
      } catch (error) {
        dispatch({
          type: "LOGIN_WITH_ACCESS_TOKEN_ERROR",
          payload: error as Error,
        })
      }

      return
    }
    case "EXCHANGE_TOKEN": {
      try {
        await requestToken(action.payload.code)

        const token = getAPIToken()

        if (!token) {
          throw new Error("Login could not be processed by the browser")
        }

        if (action.payload.close) {
          window.close()
        }

        const user = decodeAPIToken(token)

        dispatch({ type: "EXCHANGE_TOKEN_SUCCESS", payload: user })
      } catch (error) {
        dispatch({ type: "EXCHANGE_TOKEN_ERROR", payload: error as Error })
      }

      return
    }
    case "TOKEN_UPDATED": {
      try {
        const token = action.payload

        const user = decodeAPIToken(token)

        dispatch({ type: "TOKEN_UPDATED_SUCCESS", payload: user })
      } catch (error) {
        clearToken(null)

        dispatch({ type: "TOKEN_UPDATED_ERROR", payload: error as Error })
      }

      return
    }
    case "LOGOUT": {
      const { isPrimaryWindow } = action.payload

      if (isPrimaryWindow) {
        clearToken(null)
      }

      return
    }
    case "REQUEST_REFRESH": {
      const { isPrimaryWindow } = action.payload

      if (isPrimaryWindow) {
        clearToken(SESSION_EXPIRED_ERROR_MESSAGE)
      }

      if (sessionStorage.getItem("redirectTo")) {
        sessionStorage.setItem("redirectTo", window.location.pathname)
      }

      return
    }
    default: {
      return
    }
  }
}

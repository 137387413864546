export function formatToCurrency(
  value?: number,
  currency?: string,
  convertFromPence?: boolean
) {
  if (typeof value !== "number") return null
  const returnValue = convertFromPence ? value / 100 : value

  if (currency == null) {
    return returnValue.toString()
  }

  return returnValue?.toLocaleString("en-GB", {
    style: "currency",
    currencyDisplay: "narrowSymbol",
    currency,
  })
}

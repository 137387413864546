import { NavLink as RouterNavLink } from "react-router-dom"
import { useHover, useLocalStorage } from "usehooks-ts"
import type { LinkProps, ListItemButtonProps } from "@mui/material"
import { Box } from "@mui/material"
import { IconButton } from "@mui/material"
import {
  Link,
  Typography,
  Tooltip,
  ListItemText,
  ListItemButton,
  ListItemIcon,
} from "@mui/material"
import { alpha, styled } from "@mui/material/styles"
import { PushPin } from "@mui/icons-material"
import { useRef } from "react"
import { SIDEBAR } from "../../config"
import type { NavListProps } from "./NavList"
import { ExpandArrowIcon } from "./ExpandArrowIcon"
import { DotIcon } from "./DotIcon"

export function usePinnedItems() {
  return useLocalStorage<Record<string, boolean>>("menuPins", {})
}

// NOTE: Copied from the Minimals UI kit and altered to remove the concept of items being inside a "collapsed" navigation.

type IProps = LinkProps & ListItemButtonProps

interface ListItemStyleProps extends IProps {
  component?: typeof RouterNavLink | typeof Link
  to?: string
  activeRoot?: boolean
  activeSub?: boolean
  subItem?: boolean
  roles?: string[]
}

const ListItemStyle = styled(ListItemButton, {
  shouldForwardProp: (prop) =>
    prop !== "activeRoot" && prop !== "activeSub" && prop !== "subItem",
})<ListItemStyleProps>(({ activeRoot, activeSub, subItem, theme }) => ({
  ...theme.typography.body2,
  position: "relative",
  height: SIDEBAR.ITEM_ROOT_HEIGHT,
  textTransform: "capitalize",
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(1.5),
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
  // activeRoot
  ...(activeRoot && {
    ...theme.typography.subtitle2,
    color: theme.palette.primary.main,
    backgroundColor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity
    ),
  }),
  // activeSub
  ...(activeSub && {
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary,
  }),
  // subItem
  ...(subItem && {
    height: SIDEBAR.ITEM_SUB_HEIGHT,
  }),
}))

const ListItemTextStyle = styled(ListItemText)({
  whiteSpace: "nowrap",
})

const ListItemIconStyle = styled(ListItemIcon)({
  width: SIDEBAR.ITEM_ICON_SIZE,
  height: SIDEBAR.ITEM_ICON_SIZE,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  "& svg": { width: "100%", height: "100%" },
})

export function isExternalLink(path: string) {
  return path.includes("http")
}

export type NavItemProps = {
  item: NavListProps
  active?: boolean | undefined
  open?: boolean
  onOpen?: VoidFunction
  onMouseEnter?: VoidFunction
  onMouseLeave?: VoidFunction
}

// ----------------------------------------------------------------------

export function NavItemRoot({
  item,
  open = false,
  active,
  onOpen,
}: NavItemProps) {
  const { title, path, icon, info, children, disabled, caption, roles } = item
  const ref = useRef<HTMLElement>(null)
  const isHover = useHover(ref)

  const [menuPins, setMenuPins] = usePinnedItems()

  const renderContent = (
    <Box display="contents" ref={ref}>
      {icon && <ListItemIconStyle>{icon}</ListItemIconStyle>}
      <ListItemTextStyle
        disableTypography
        primary={title}
        sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
        secondary={
          <Tooltip title={caption || ""} arrow>
            <Typography
              noWrap
              variant="caption"
              component="div"
              sx={{ textTransform: "initial", color: "text.secondary" }}
            >
              {caption}
            </Typography>
          </Tooltip>
        }
      />
      {info && info}
      {isHover && (
        <IconButton
          data-testid="pin-button"
          size="small"
          onClick={(e) => {
            setMenuPins({ ...menuPins, [title]: !menuPins[title] })
            e.preventDefault()
          }}
        >
          <PushPin fontSize="inherit" />
        </IconButton>
      )}
      {children && <ExpandArrowIcon isOpen={open} />}
    </Box>
  )

  if (children) {
    return (
      <ListItemStyle
        onClick={onOpen}
        activeRoot={active}
        disabled={disabled}
        roles={roles}
      >
        {renderContent}
      </ListItemStyle>
    )
  }

  return isExternalLink(path) ? (
    <ListItemStyle
      component={Link}
      href={path}
      target="_blank"
      rel="noopener"
      disabled={disabled}
      roles={roles}
    >
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle
      component={RouterNavLink}
      to={path}
      activeRoot={active}
      disabled={disabled}
      roles={roles}
    >
      {renderContent}
    </ListItemStyle>
  )
}

// ----------------------------------------------------------------------

export function NavItemSub({
  item,
  open = false,
  active = false,
  onOpen,
}: NavItemProps) {
  const { title, path, info, children, disabled, caption, roles } = item

  const renderContent = (
    <>
      <ListItemIconStyle>
        <DotIcon active={active} />
      </ListItemIconStyle>
      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Tooltip title={caption || ""} arrow>
            <Typography
              noWrap
              variant="caption"
              component="div"
              sx={{ textTransform: "initial", color: "text.secondary" }}
            >
              {caption}
            </Typography>
          </Tooltip>
        }
      />
      {info && info}
      {children && <ExpandArrowIcon isOpen={open} />}
    </>
  )

  if (children) {
    return (
      <ListItemStyle
        onClick={onOpen}
        activeSub={active}
        subItem
        disabled={disabled}
        roles={roles}
      >
        {renderContent}
      </ListItemStyle>
    )
  }

  return isExternalLink(path) ? (
    <ListItemStyle
      component={Link}
      href={path}
      target="_blank"
      rel="noopener"
      subItem
      disabled={disabled}
      roles={roles}
    >
      {renderContent}
    </ListItemStyle>
  ) : (
    <ListItemStyle
      component={RouterNavLink}
      to={path}
      activeSub={active}
      subItem
      disabled={disabled}
      roles={roles}
    >
      {renderContent}
    </ListItemStyle>
  )
}

import type { FormControlProps } from "@mui/material"
import {
  Box,
  FormControl,
  FormHelperText,
  IconButton,
  Paper,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material"
import type { DropzoneOptions } from "react-dropzone"
import { useDropzone } from "react-dropzone"
import type { ControllerProps, FieldValues } from "react-hook-form"
import { Controller } from "react-hook-form"
import { useState, useEffect } from "react"
import CancelIcon from "@mui/icons-material/Cancel"

type DropzoneProps = {
  disabled?: boolean
  helperText?: string
  error?: boolean
  onChange: (files: File[]) => void
  options?: DropzoneOptions
  sx?: FormControlProps["sx"]
}

export function Dropzone({
  disabled,
  onChange,
  helperText,
  error,
  options = {},
  sx = {},
}: DropzoneProps) {
  const theme = useTheme()
  const [preview, setPreview] = useState<string | undefined>()
  const [file, setFile] = useState<File | undefined>()

  useEffect(() => {
    onChange(file ? [file] : [])
  }, [file, onChange])

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    isFileDialogActive,
  } = useDropzone({
    maxFiles: 1,
    onDrop: (files) => {
      const lastFile = files[0]
      const previewUrl = URL.createObjectURL(lastFile)

      setFile(lastFile)
      setPreview(previewUrl)
    },
    ...options,
  })

  const borderColor = error
    ? theme.palette.error.main
    : !!file
    ? theme.palette.success.light
    : isDragAccept || isFileDialogActive || isDragActive
    ? theme.palette.info.main
    : isDragReject
    ? theme.palette.error.main
    : theme.palette.primary.main

  const message = !!file ? file.name : "Drop or click to attach a file"

  return (
    <FormControl sx={sx}>
      <Paper
        variant="elevation"
        elevation={isDragActive || isFileDialogActive ? 20 : 4}
        sx={{
          border: "1px solid",
          borderColor,
          pointerEvents: disabled ? "none" : undefined,
          filter: disabled ? "grayscale(75%) brightness(110%)" : undefined,
          "&:hover": {
            borderColor: error
              ? theme.palette.error.light
              : theme.palette.text.primary,
          },
        }}
        {...getRootProps()}
      >
        <Box
          display="grid"
          gridTemplateColumns="1fr max-content 1fr"
          alignItems="center"
          justifyItems="center"
          height={80}
          p={4}
          gap={2}
        >
          <Box>
            <input {...getInputProps()} />
          </Box>
          <Box display="flex" gap={2} alignItems="center">
            <Typography
              color={
                !!file
                  ? theme.palette.primary.light
                  : theme.palette.primary.main
              }
              fontWeight="600"
            >
              {message}
            </Typography>
            {file && file.type.match(/image.*/) && (
              <img
                src={preview}
                alt="logo-preview"
                style={{ width: 40, height: "auto" }}
                onLoad={() => {
                  preview && URL.revokeObjectURL(preview)
                }}
              />
            )}
          </Box>
          <Box justifySelf="end">
            {file && (
              <Tooltip title="Remove file">
                <IconButton
                  disabled={!file}
                  aria-label="cancel"
                  onClick={(e) => {
                    e.stopPropagation()
                    setFile(undefined)
                  }}
                  sx={{ alignSelf: "flex-end" }}
                >
                  <CancelIcon />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        </Box>
      </Paper>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  )
}

type LiftedProps = "helperText" | "disabled" | "options" | "sx"

export type DropzoneFieldProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  "render"
> &
  Pick<DropzoneProps, LiftedProps> & {
    fieldProps?: Omit<DropzoneProps, LiftedProps | keyof ControllerProps<T>>
  }

export function DropzoneField<T extends FieldValues>({
  helperText,
  disabled,
  fieldProps,
  options,
  sx,
  ...controllerProps
}: DropzoneFieldProps<T>) {
  return (
    <Controller
      {...controllerProps}
      render={({ field, fieldState }) => (
        <Dropzone
          {...fieldProps}
          sx={sx}
          options={options}
          disabled={disabled}
          helperText={
            fieldState.error
              ? fieldState.error?.message || `File input cannot be blank`
              : helperText
          }
          error={Boolean(fieldState.error)}
          onChange={(files: File[]) => {
            field.onChange(files?.[0])
          }}
        />
      )}
    />
  )
}

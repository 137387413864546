import { styled, TableCell } from "@mui/material"
import type { ReactNode } from "react"

export const StyledTableHeadCell = styled((props: { children: ReactNode }) => {
  return <TableCell variant="head" component="th" scope="row" {...props} />
})({
  width: "40%",
  whiteSpace: "nowrap",
  verticalAlign: "top",
})

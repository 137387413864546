export function compareBy<T>(property: keyof T) {
  return (a: T, b: T) => {
    if (a[property] < b[property]) {
      return -1
    }
    if (a[property] > b[property]) {
      return 1
    }
    return 0
  }
}

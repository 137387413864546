import type { Reducer } from "react"
import type { AuthState, AuthAction } from "./AuthContext"

type AuthReducer = Reducer<AuthState, AuthAction>

export const reducer: AuthReducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
    case "LOGIN_WITH_GOOGLE":
    case "EXCHANGE_TOKEN":
    case "TOKEN_UPDATED": {
      return {
        ...state,
        error: undefined,
        loading: true,
      }
    }
    case "LOGIN_SUCCESS":
    case "LOGIN_WITH_ACCESS_TOKEN_SUCCESS":
    case "EXCHANGE_TOKEN_SUCCESS":
    case "TOKEN_UPDATED_SUCCESS": {
      return {
        ...state,
        user: action.payload,
        loading: false,
        redirectToLogin: null,
      }
    }
    case "LOGIN_WITH_GOOGLE_SUCCESS": {
      return {
        ...state,
        loading: false,
      }
    }
    case "LOGIN_ERROR":
    case "LOGIN_WITH_ACCESS_TOKEN_ERROR":
    case "LOGIN_WITH_GOOGLE_ERROR":
    case "EXCHANGE_TOKEN_ERROR":
    case "TOKEN_UPDATED_ERROR": {
      return {
        ...state,
        error: action.payload,
        user: null,
        loading: false,
      }
    }
    case "LOGOUT": {
      return {
        ...state,
        user: null,
        loading: false,
        redirectToLogin: true,
        error: undefined,
      }
    }
    case "REQUEST_REFRESH": {
      return {
        ...state,
        user: null,
        loading: false,
        redirectToLogin: false,
        error: undefined,
      }
    }
    default: {
      return state
    }
  }
}

import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = (id: string | undefined) =>
  ["payschedules", id] as const

function usePaySchedule(id: string | undefined) {
  const endpoint = endpoints.paySchedules.single(id)

  return useQuery(getQueryKey(id), endpoint.get, {
    enabled: Boolean(id),
    select: (data) => data.data.data,
  })
}

usePaySchedule.getQueryKey = getQueryKey

export default usePaySchedule

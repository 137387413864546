import type { ReactElement } from "react"
import { useState } from "react"
import { useLocation, matchPath } from "react-router-dom"
import { List, Collapse } from "@mui/material"
import { NavItemRoot, NavItemSub } from "./NavItem"

// NOTE: Copied from the Minimals UI kit and altered to remove the concept of items being inside a "collapsed" navigation.

export type NavListProps = {
  title: string
  path: string
  icon?: ReactElement
  info?: ReactElement
  caption?: string
  disabled?: boolean
  roles?: string[]
  children?: {
    title: string
    path: string
    children?: { title: string; path: string }[]
  }[]
}

type NavListRootProps = {
  list: NavListProps
}

export function getActive(path: string, pathname: string) {
  return path ? !!matchPath({ path: path, end: false }, pathname) : false
}

export function NavListRoot({ list }: NavListRootProps) {
  const { pathname } = useLocation()

  const active = getActive(list.path, pathname)

  const [open, setOpen] = useState(active)

  const hasChildren = list.children

  if (hasChildren) {
    return (
      <>
        <NavItemRoot
          item={list}
          active={active}
          open={open}
          onOpen={() => setOpen(!open)}
        />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {(list.children || []).map((item) => (
              <NavListSub key={item.title + item.path} list={item} />
            ))}
          </List>
        </Collapse>
      </>
    )
  }

  return <NavItemRoot item={list} active={active} />
}

type NavListSubProps = {
  list: NavListProps
}

function NavListSub({ list }: NavListSubProps) {
  const { pathname } = useLocation()

  const active = getActive(list.path, pathname)

  const [open, setOpen] = useState(active)

  const hasChildren = list.children

  if (hasChildren) {
    return (
      <>
        <NavItemSub
          item={list}
          onOpen={() => setOpen(!open)}
          open={open}
          active={active}
        />

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding sx={{ pl: 3 }}>
            {(list.children || []).map((item) => (
              <NavItemSub
                key={item.title + item.path}
                item={item}
                active={getActive(item.path, pathname)}
              />
            ))}
          </List>
        </Collapse>
      </>
    )
  }

  return <NavItemSub item={list} active={active} />
}

import type {
  DataGridProps,
  GridColumns,
  GridCsvExportOptions,
  GridRowId,
  GridToolbarQuickFilterProps,
  GridValidRowModel,
} from "@mui/x-data-grid"
import { GridToolbar } from "@mui/x-data-grid"
import { DataGrid } from "@mui/x-data-grid"
import { NoRowsOverlay } from "./NoRowsOverlay"

const simpleGridStyle = {
  "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
    py: 2,
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    textOverflow: "clip",
    whiteSpace: "break-spaces",
    lineHeight: 1,
  },
}

const quickFilterProps: {
  showQuickFilter: boolean
  quickFilterProps: GridToolbarQuickFilterProps
  csvOptions: GridCsvExportOptions
} = {
  showQuickFilter: true,
  quickFilterProps: { debounceMs: 200 },
  csvOptions: { utf8WithBom: true },
}

export type SimpleGridProps<T extends GridValidRowModel> = {
  quickFilter?: boolean
  isLoading?: boolean
  columns: GridColumns<T>
  rows: Array<T>
  idField: keyof T
  noRowsText?: string
  initialPaginationPageSize?: number
} & Omit<DataGridProps<T>, "loading" | "getRowId" | "columns" | "rows">

export function SimpleGrid<T extends GridValidRowModel>({
  quickFilter,
  isLoading,
  columns,
  rows,
  idField,
  sx = null,
  noRowsText,
  initialPaginationPageSize,
  ...rest
}: SimpleGridProps<T>) {
  return (
    <DataGrid
      loading={isLoading}
      columns={columns}
      rows={rows}
      components={{
        Toolbar: GridToolbar,
        NoRowsOverlay: noRowsText ? NoRowsOverlay : undefined,
      }}
      componentsProps={{
        toolbar: quickFilter ? quickFilterProps : {},
        noRowsOverlay: noRowsText ? { message: noRowsText } : {},
      }}
      getRowId={(rows) => rows[idField] as GridRowId}
      autoHeight
      getRowHeight={() => "auto"}
      sx={{ ...simpleGridStyle, ...sx }}
      disableColumnMenu
      disableColumnFilter
      disableColumnSelector
      disableDensitySelector
      disableSelectionOnClick
      initialState={{
        ...rest.initialState,
        pagination: initialPaginationPageSize
          ? {
              ...rest.initialState?.pagination,
              pageSize: initialPaginationPageSize,
            }
          : rest.initialState?.pagination,
      }}
      hideFooter={!rest.pagination}
      {...rest}
    />
  )
}

import { Button } from "@mui/material"
import { useNotification } from "context/notification"

const CopyToClipboard = ({
  link,
  children,
}: {
  link: string
  children?: React.ReactNode
}) => {
  const notify = useNotification()
  const handleClick = () => {
    notify.info("link copied to clipboard")
    navigator.clipboard.writeText(link)
  }

  return <Button onClick={handleClick}>{children}</Button>
}

export default CopyToClipboard

export function remToPx(value: string) {
  return Math.round(parseFloat(value) * 16)
}

export function pxToRem(value: number) {
  return `${value / 16}rem`
}

type FontScale = { fontSize: number; lineHeight: number }

export function responsiveFontScales({
  md,
  lg,
}: {
  md: FontScale
  lg: FontScale
}) {
  return {
    "@media (min-width:900px)": {
      fontSize: typeScaleRem(md.fontSize),
      lineHeight: typeScaleRem(md.lineHeight),
    },
    "@media (min-width:1200px)": {
      fontSize: typeScaleRem(lg.fontSize),
      lineHeight: typeScaleRem(md.lineHeight),
    },
  }
}

// Based on https://carbondesignsystem.com/guidelines/typography/overview/#scale
export function typeScale(step: number): number {
  if (step <= 1) return 8
  return typeScale(step - 1) + Math.floor((step - 2) / 4 + 1) * 2
}

export function typeScaleRem(step: number) {
  return pxToRem(typeScale(step))
}

import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import type { Control, UseFormGetValues, UseFormWatch } from "react-hook-form"
import type { PaydaysSegmentPropertiesTestGroup } from "api"
import { CollapsibleCard, ControlledTextField } from "components"
import type { SegmentBuilderFormValues } from "../../SegmentBuilderDialog"

interface SegmentTestGroupsProps {
  testGroupFields: PaydaysSegmentPropertiesTestGroup[]
  watch: UseFormWatch<SegmentBuilderFormValues>
  getValues: UseFormGetValues<SegmentBuilderFormValues>
  control: Control<SegmentBuilderFormValues>
  removeTestGroupField: (index: number) => void
  appendTestGroupField: (group: PaydaysSegmentPropertiesTestGroup) => void
}

export function SegmentTestGroups({
  testGroupFields,
  watch,
  getValues,
  control,
  removeTestGroupField,
  appendTestGroupField,
}: SegmentTestGroupsProps) {
  return (
    <CollapsibleCard
      title="Test Groups"
      type="inner"
      startExpanded
      hideCollapse
    >
      <TableContainer component={Card}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Weighting</TableCell>
              <TableCell>Audience %</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {testGroupFields.map(({ group_id }, fieldIndex) => {
              const groups = watch("properties.groups")
              const totalWeighting = groups.reduce((acc, cur) => {
                const floatValue = parseFloat(cur.weighting)
                return acc + (Number.isNaN(floatValue) ? 0 : floatValue)
              }, 0)

              const fieldsFloatValue = parseFloat(
                watch(`properties.groups.${fieldIndex}.weighting`)
              )
              const fieldsWeighting = Number.isNaN(fieldsFloatValue)
                ? 0
                : fieldsFloatValue

              const audiencePercentage =
                totalWeighting === 0
                  ? "-"
                  : ((fieldsWeighting / totalWeighting) * 100).toFixed(2) + "%"

              return (
                <TableRow key={group_id}>
                  <TableCell>
                    <ControlledTextField
                      name={`properties.groups.${fieldIndex}.name`}
                      control={control}
                      fieldProps={{
                        size: "small",
                        autoComplete: "off",
                      }}
                      rules={{
                        required: true,
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <ControlledTextField
                      name={`properties.groups.${fieldIndex}.weighting`}
                      control={control}
                      fieldProps={{
                        type: "number",
                        size: "small",
                        autoComplete: "off",
                      }}
                      rules={{
                        required: true,
                        validate: (value) =>
                          Number(value) > 0 ||
                          "Value must by a number greater than 0",
                      }}
                    />
                  </TableCell>
                  <TableCell>{audiencePercentage}</TableCell>
                  {/* NOTE: Want this to be as narrow as possible */}
                  <TableCell width="1px">
                    <Button
                      variant="contained"
                      onClick={() => removeTestGroupField(fieldIndex)}
                    >
                      Delete
                    </Button>
                  </TableCell>
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Box display="flex" mt={8} justifyContent="flex-end">
        <Button
          // NOTE: To be same height as the add button for filters above
          sx={{ height: 37 }}
          variant="contained"
          onClick={() => {
            appendTestGroupField({
              // NOTE: 1 indexed vs 0 indexed since A2 for compatibility
              group_id: getValues().properties.groups.length + 1,
              name: "",
              weighting: "",
            })
          }}
        >
          Add
        </Button>
      </Box>
    </CollapsibleCard>
  )
}

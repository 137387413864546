import AutorenewIcon from "@mui/icons-material/Autorenew"
import Icon from "@mui/material/Icon"
import useDebitcardUSReissue from "query-hooks/useDebitcardUSReissue"

import { ConfirmationDialog } from "components"
import { GridActionsCellItem } from "@mui/x-data-grid"
import { useState } from "react"

function UserDebitcardReissueAction({
  accountId,
  status,
}: {
  accountId: string
  status: string
}) {
  const { mutate, isLoading } = useDebitcardUSReissue(accountId, {
    onSuccess: () => {
      setShowConfirm(false)
    },
  })
  const [showConfirm, setShowConfirm] = useState(false)

  return (
    <>
      <GridActionsCellItem
        label="Re-issue"
        disabled={status !== "TERMINATED"}
        icon={status === "TERMINATED" ? <AutorenewIcon /> : <Icon />}
        onClick={() => {
          setShowConfirm(true)
        }}
      />
      <ConfirmationDialog
        open={showConfirm}
        title="Confirm card re-issuance"
        isLoading={isLoading}
        disabled={status !== "TERMINATED"}
        onConfirm={() => {
          mutate(accountId)
        }}
        onCancel={() => setShowConfirm(false)}
      >
        Are you sure you want to reissue this card? All other cards must be
        TERMINATED first.
      </ConfirmationDialog>
    </>
  )
}

export default UserDebitcardReissueAction

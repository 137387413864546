import type { ReactNode } from "react"
import { Box, List, ListSubheader } from "@mui/material"
import { styled } from "@mui/material/styles"
import { NavListRoot } from "./NavList"
import { useNavigationConfig } from "./config"

export const StyledListSubheader = styled((props: { children: ReactNode }) => (
  <ListSubheader disableSticky disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.overline,
  paddingTop: theme.spacing(3),
  paddingLeft: theme.spacing(2),
  paddingBottom: theme.spacing(1),
  color: theme.palette.text.primary,
  background: "none",
}))

export function Navigation() {
  const config = useNavigationConfig()

  return (
    <nav>
      {config.map((group) => (
        <List key={group.subheader} disablePadding>
          <StyledListSubheader>{group.subheader}</StyledListSubheader>
          {group.items.map((item) => (
            <NavListRoot key={item.title + item.path} list={item} />
          ))}
        </List>
      ))}
      <Box pb={4}></Box>
    </nav>
  )
}

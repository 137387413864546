import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"
export const getQueryKey = () => ["debitcard_us", "application"] as const

function useDebitcardApplicationStatusHistoryUS(application_id: string | null) {
  const endpoint = endpoints.debitCardUS.applicationHistory(application_id)

  return useQuery([...getQueryKey(), application_id], endpoint.get, {
    enabled: !!application_id,
    select: (data) => data.data,
  })
}

useDebitcardApplicationStatusHistoryUS.getQueryKey = getQueryKey

export default useDebitcardApplicationStatusHistoryUS

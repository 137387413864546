import { createContext } from "react"
import type { Dispatch } from "react"
import type {
  ExchangeTokenPayload,
  GoogleLoginPayload,
  PasswordLoginPayload,
  AccessTokenLoginPayload,
} from "api"
import type { Effectful, Action, UIAction, AuthUser } from "./types"

export type AuthState = {
  redirectToLogin: boolean | null
  user: AuthUser | null
  loading: boolean
  error?: Error
}

export type AuthAction =
  | Effectful<Action<"LOGIN", PasswordLoginPayload>, AuthUser>
  | Effectful<Action<"LOGIN_WITH_GOOGLE", GoogleLoginPayload>>
  | Effectful<
      Action<"LOGIN_WITH_ACCESS_TOKEN", AccessTokenLoginPayload>,
      AuthUser
    >
  | Effectful<Action<"EXCHANGE_TOKEN", ExchangeTokenPayload>, AuthUser>
  | Effectful<Action<"TOKEN_UPDATED", string>, AuthUser>
  | Action<"LOGOUT", { isPrimaryWindow: boolean }>
  | Action<"REQUEST_REFRESH", { isPrimaryWindow: boolean }>

export const AuthStateContext = createContext<AuthState | undefined>(undefined)
AuthStateContext.displayName = "AuthStateContext"

export const AuthDispatchContext = createContext<
  Dispatch<UIAction<AuthAction>>
>(() => {
  throw new Error("Context is not initialized yet")
})
AuthDispatchContext.displayName = "AuthDispatchContext"

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { endpoints } from "api"
import { getQueryKey } from "./usePaySchedule"

function usePutPayScheduleDate(
  payScheduleId: string,
  options?: Partial<{
    onSuccess: () => void
    onError: () => void
  }>
) {
  const queryClient = useQueryClient()
  const endpoint = endpoints.paySchedules.dates.byPayScheduleId(payScheduleId)

  return useMutation(
    (payload: {
      pay_period_start: string
      paid_on: string
      blackout_period_start?: string
    }) => endpoint.post(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getQueryKey(payScheduleId))
        options?.onSuccess?.()
      },
      onError: () => {
        options?.onError?.()
      },
    }
  )
}

export default usePutPayScheduleDate

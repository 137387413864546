import type { ReactElement } from "react"
import { Link, Navigate } from "react-router-dom"
import { useAuthDispatch, useAuthState } from "context/auth"
import { routes } from "routes"
import { Backdrop, Button, Stack, Typography } from "@mui/material"
import GoogleIcon from "@mui/icons-material/Google"
import { UsageWarningMessage } from "components/UsageWarningMessage/UsageWarningMessage"

export function PrivateRoute({ children }: { children: ReactElement }) {
  const { redirectToLogin, user } = useAuthState()
  const dispatch = useAuthDispatch()

  if (!user && redirectToLogin) {
    sessionStorage.setItem("redirectTo", window.location.pathname)

    return <Navigate to={routes.login()} />
  }

  return (
    <>
      {children}
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={!user && !redirectToLogin}
      >
        <Stack
          spacing={4}
          alignItems="center"
          sx={(theme) => ({
            background: theme.palette.grey[0],
            border: "2px solid",
            borderColor: theme.palette.primary.main,
            borderRadius: 2,
            padding: 12,
          })}
        >
          <Typography variant="h1">Session Expired</Typography>
          <UsageWarningMessage />
          <Button
            component={Link}
            size="large"
            variant="outlined"
            fullWidth
            to={routes.login()}
          >
            Go to login
          </Button>
          <Button
            size="large"
            fullWidth
            variant="contained"
            startIcon={<GoogleIcon fontSize="inherit" />}
            onClick={() =>
              dispatch({
                type: "LOGIN_WITH_GOOGLE",
                payload: { inNewWindow: true },
              })
            }
          >
            Refresh login
          </Button>
        </Stack>
      </Backdrop>
    </>
  )
}

import { useMutation } from "@tanstack/react-query"
import type { PaydaysSegmentTestData } from "api"
import { endpoints } from "api"

function usePostTestSegmentSQL(
  options?: Partial<{
    onSuccess: (data: PaydaysSegmentTestData) => void
    onError: (data: unknown) => void
  }>
) {
  return useMutation(
    ({ sql, params }: { sql: string; params: Record<string, string> }) => {
      const endpoint = endpoints.campaigns.segments.test()
      return endpoint.post({ sql, params })
    },
    {
      onSuccess: (data) => {
        options?.onSuccess?.(data.data)
      },
      onError: (data) => {
        options?.onError?.(data)
      },
    }
  )
}

export default usePostTestSegmentSQL

import type { ConfirmationDialogProps } from "components"
import type { Dispatch } from "react"
import { createContext } from "react"

export type ConfirmationState = Omit<
  ConfirmationDialogProps,
  "onCancel" | "onConfirm" | "open"
> & {
  onCancel?: (close: () => void) => void
  onConfirm?: (close: () => void) => void
}

export const ConfirmationContext = createContext<Dispatch<ConfirmationState>>(
  () => {
    throw new Error("Context is not initialized yet")
  }
)

ConfirmationContext.displayName = "ConfirmationContext"

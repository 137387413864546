import { useContext } from "react"
import { SearchContext } from "./SearchContext"

export const useSearch = () => {
  const context = useContext(SearchContext)

  if (!context) {
    throw new Error("useSearch may only be called in a child of SearchProvider")
  }

  return context
}

import CodeMirror from "@uiw/react-codemirror"
import { styled } from "@mui/material"

export const StyledCodeMirror = styled(CodeMirror)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: "1px solid",
  borderColor: theme.palette.grey[50048],
  "& > div": {
    borderRadius: "inherit",
  },
}))

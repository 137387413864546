import {
  Alert,
  Card,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material"
import type { PaydaysSegmentDependents } from "api"
import { CollapsibleCard } from "components"
import { pickErrorMessage } from "utils/pickErrorMessage"

interface SegmentDependentsTableProps {
  dialogs?: PaydaysSegmentDependents["dialogs"]
  emailCampaigns?: PaydaysSegmentDependents["email_campaigns"]
  isLoading: boolean
  isError: boolean
  isSuccess: boolean
  error: unknown
}

export function SegmentDependentsTable({
  dialogs,
  emailCampaigns,
  isLoading,
  isError,
  isSuccess,
  error,
}: SegmentDependentsTableProps) {
  const isNotUsedAnywhere = !(emailCampaigns?.length || dialogs?.length)

  return (
    <CollapsibleCard title="Used By" type="inner" startExpanded hideCollapse>
      {isLoading && (
        <Skeleton
          role="progressbar"
          height="100px"
          sx={{ transform: "none" }}
        />
      )}
      {isError && (
        <Alert severity="error" sx={{ mb: 4 }}>
          Error: {pickErrorMessage(error)}
        </Alert>
      )}
      {isSuccess &&
        (isNotUsedAnywhere ? (
          <Alert severity="info" sx={{ mb: 4 }}>
            No dialog or email campaign usage. Note that segments may still be
            used elsewhere.
          </Alert>
        ) : (
          <TableContainer component={Card}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Type</TableCell>
                  <TableCell>Title</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {dialogs?.map(({ in_app_dialog_id, title }) => (
                  <TableRow key={in_app_dialog_id}>
                    <TableCell>In app dialog</TableCell>
                    <TableCell>{title}</TableCell>
                  </TableRow>
                ))}
                {emailCampaigns?.map(({ email_campaign_id, subject }) => (
                  <TableRow key={email_campaign_id}>
                    <TableCell>Email campaign</TableCell>
                    <TableCell>{subject}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ))}
    </CollapsibleCard>
  )
}

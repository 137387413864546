import { useState, type FC, type PropsWithChildren } from "react"
import type { Breakpoint, DialogProps } from "@mui/material"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Alert,
} from "@mui/material"
import LoadingButton from "@mui/lab/LoadingButton"
import type { ColorSchema } from "theme/palette"
import styled from "@emotion/styled"

export type ConfirmationDialogProps = PropsWithChildren<
  Pick<DialogProps, "transitionDuration"> & {
    open: boolean
    onConfirm: () => void
    onCancel: () => void
    title?: string
    isLoading?: boolean
    disabled?: boolean
    primaryButtonColor?: ColorSchema
    primaryButtonText?: string
    cancelButtonText?: string
    errorMessage?: string | null
    fullWidth?: boolean
    message?: string
    maxWidth?: Breakpoint
    typeConfirm?: boolean
  }
>

const ConfirmInput = styled.input`
  align-items: center;
  justify-content: center;
  width: 100%;
`

export const ConfirmationDialog: FC<ConfirmationDialogProps> = ({
  open,
  title,
  isLoading,
  disabled,
  primaryButtonColor = "primary",
  primaryButtonText = "Confirm",
  cancelButtonText = "Cancel",
  onConfirm,
  onCancel,
  errorMessage,
  children,
  fullWidth,
  message,
  transitionDuration,
  maxWidth,
  typeConfirm,
}) => {
  const [text, setText] = useState("")
  const hasContent = message || children || errorMessage

  const handleCancel = () => {
    setText("")
    onCancel()
  }

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="confirmation-dialog"
      fullWidth={fullWidth}
      transitionDuration={transitionDuration}
      maxWidth={maxWidth}
    >
      {title && <DialogTitle id="confirmation-dialog">{title}</DialogTitle>}
      {hasContent && (
        <DialogContent>
          {!!errorMessage && (
            <Alert sx={{ my: 2 }} severity="error">
              Error: {errorMessage}
            </Alert>
          )}
          {message || children}
        </DialogContent>
      )}
      {typeConfirm && (
        <DialogContent>
          <Alert sx={{ my: 2 }} severity="warning">
            Type 'CONFIRM' to proceed:
            <ConfirmInput
              type="text"
              placeholder="CONFIRM"
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </Alert>
        </DialogContent>
      )}
      <DialogActions>
        <Button variant="outlined" onClick={handleCancel}>
          {cancelButtonText}
        </Button>
        <LoadingButton
          loading={isLoading}
          disabled={disabled || (typeConfirm && text !== "CONFIRM")}
          color={primaryButtonColor}
          variant="contained"
          onClick={onConfirm}
        >
          {primaryButtonText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

import { ReactNode } from "react"
import { CssBaseline } from "@mui/material"
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MUIThemeProvider,
} from "@mui/material/styles"
import palette from "./palette"
import typography from "./typography"
import breakpoints from "./breakpoints"
import componentsOverride from "./overrides"
import shadows, { customShadows } from "./shadows"

// ----------------------------------------------------------------------

type Props = {
  children: ReactNode
}

export default function ThemeProvider({ children }: Props) {
  const paletteVersion = "light"

  const themeOptions: ThemeOptions = {
    palette: palette[paletteVersion],
    typography,
    breakpoints,
    shape: { borderRadius: 8 },
    shadows: shadows[paletteVersion],
    customShadows: customShadows[paletteVersion],
    spacing: 3,
  }

  const theme = createTheme(themeOptions)

  theme.components = componentsOverride(theme)

  return (
    <MUIThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </MUIThemeProvider>
  )
}

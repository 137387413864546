import { useMutation, useQueryClient } from "@tanstack/react-query"
import { endpoints } from "api"
import { getQueryKey } from "./usePaySchedule"

export type PayScheduleDateGeneratePayload = {
  pay_period_start?: string
  pay_day_of_month?: string
  initial_period_start?: string
  days_from_period_start_to_paid?: string
  lock_date_days_delta?: string
  lock_date_hours_delta?: string
}

function usePostPayScheduleDateGenerate(
  payScheduleId: string,
  options?: Partial<{
    onSuccess: () => void
    onError: () => void
  }>
) {
  const queryClient = useQueryClient()
  const endpoint = endpoints.paySchedules.dates.generate(payScheduleId)

  return useMutation(
    (payload: PayScheduleDateGeneratePayload) => {
      return endpoint.post(new URLSearchParams(payload))
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getQueryKey(payScheduleId))
        options?.onSuccess?.()
      },
      onError: () => {
        options?.onError?.()
      },
    }
  )
}

export default usePostPayScheduleDateGenerate

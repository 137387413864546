import { useParams } from "react-router-dom"
import { Card, Dialog, DialogContent, DialogTitle, Grid } from "@mui/material"
import useDebitcardApplicationsUS from "query-hooks/useDebitcardApplicationsUS"
import usePaymentcardStatusHistory from "query-hooks/usePaymentcardStatusHistory"
import useDebitcardUS from "query-hooks/useDebitcardUS"
import { CollapsibleCard, SimpleGrid } from "components"
import { useState } from "react"
import useDebitcardApplicationHistoryUS from "query-hooks/useDebitcardApplicationHistoryUS"
import usePaymentcardFulfillmentHistory from "query-hooks/usePaymentcardFulfillmentHistory"
import usePaymentAccountsUS from "query-hooks/usePaymentAccountsUS"
import { useDebitcardColumns } from "./UserDebitcardColumns"
import { USER_PAYMENT_ACCOUNT_COLUMNS } from "./UserPaymentAccountColumns"
import { useColumns as useDebitcardApplicationColumns } from "./UserDebitcardApplicationColumns"
import { USER_DEBITCARD_APPLICATION_HISTORY_COLUMNS } from "./UserDebitCardApplicationHistoryColumns"
import { USER_DEBITCARD_STATUS_HISTORY_COLUMNS } from "./UserDebitcardStatusHistoryColumns"
import { USER_DEBITCARD_FULFILLMENT_HISTORY_COLUMNS } from "./UserDebitcardFulfillmentHistoryColumns"
import UserDebitcardApplicationRetryAction from "./UserDebitcardApplicationRetryAction"

function UserPaymentCards() {
  const { id } = useParams()

  const [showingStatusHistoryForId, setShowStatusHistoryForId] = useState<
    string | null
  >(null)

  const [showingCardStatusHistoryForId, setShowCardStatusHistoryForId] =
    useState<string | null>(null)
  const [
    showingCardFulfillmentHistoryForId,
    setShowCardFulfillmentHistoryForId,
  ] = useState<string | null>(null)

  const applications = useDebitcardApplicationsUS(id as string)
  const applicationHistory = useDebitcardApplicationHistoryUS(
    showingStatusHistoryForId
  )
  const debitcards = useDebitcardUS(id as string)
  const cardHistory = usePaymentcardStatusHistory(showingCardStatusHistoryForId)
  const cardFulfillmentHistory = usePaymentcardFulfillmentHistory(
    showingCardFulfillmentHistoryForId
  )

  const paymentAccounts = usePaymentAccountsUS(id as string)

  const applicationColumns = useDebitcardApplicationColumns({
    onViewStatusHistory: setShowStatusHistoryForId,
  })

  const cardColumns = useDebitcardColumns({
    onViewStatusHistory: setShowCardStatusHistoryForId,
    onViewFulfillmentHistory: setShowCardFulfillmentHistoryForId,
  })

  return (
    <>
      <Grid item xs={12}>
        <CollapsibleCard
          title="Payment card applications"
          type="main"
          startExpanded
          hideCollapse
        >
          {applications.data &&
            applications.data.data.filter((item) => item.completed_at === null)
              .length > 0 && (
              <UserDebitcardApplicationRetryAction userId={id as string} />
            )}
          <Card>
            <SimpleGrid
              idField="card_application_id"
              rows={applications?.data?.data ?? []}
              columns={applicationColumns}
              error={debitcards.error}
              isLoading={debitcards.isLoading}
              initialState={{
                sorting: { sortModel: [{ field: "updated_at", sort: "desc" }] },
              }}
            />
          </Card>
        </CollapsibleCard>
        <CollapsibleCard
          title="Payment cards"
          type="main"
          startExpanded
          hideCollapse
        >
          <Card>
            <SimpleGrid
              idField="ws_card_id"
              rows={debitcards?.data?.data ?? []}
              columns={cardColumns}
              error={debitcards.error}
              isLoading={debitcards.isLoading}
              initialState={{
                sorting: { sortModel: [{ field: "updated_at", sort: "desc" }] },
              }}
            />
          </Card>
        </CollapsibleCard>
        <CollapsibleCard
          title="Payment accounts"
          type="main"
          startExpanded
          hideCollapse
        >
          <Card>
            <SimpleGrid
              idField="account_id"
              rows={
                paymentAccounts?.data?.data ? [paymentAccounts?.data?.data] : []
              }
              columns={USER_PAYMENT_ACCOUNT_COLUMNS}
              error={paymentAccounts.error}
              isLoading={paymentAccounts.isLoading}
              initialState={{
                sorting: {
                  sortModel: [{ field: "date_created", sort: "desc" }],
                },
              }}
            />
          </Card>
        </CollapsibleCard>
      </Grid>
      <Dialog
        open={showingStatusHistoryForId !== null}
        onClose={() => setShowStatusHistoryForId(null)}
        fullWidth
      >
        <DialogTitle>History</DialogTitle>
        <DialogContent sx={{ typography: "body2" }}>
          <SimpleGrid
            idField="card_application_id"
            rows={applicationHistory.data?.data ?? []}
            columns={USER_DEBITCARD_APPLICATION_HISTORY_COLUMNS}
            error={applicationHistory.error}
            isLoading={applicationHistory.isLoading}
            initialState={{
              sorting: { sortModel: [{ field: "created_at", sort: "desc" }] },
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={showingCardStatusHistoryForId !== null}
        onClose={() => setShowCardStatusHistoryForId(null)}
        fullWidth
      >
        <DialogTitle>History</DialogTitle>
        <DialogContent sx={{ typography: "body2" }}>
          <SimpleGrid
            idField="ws_card_id"
            rows={cardHistory.data?.data ?? []}
            columns={USER_DEBITCARD_STATUS_HISTORY_COLUMNS}
            error={cardHistory.error}
            isLoading={cardHistory.isLoading}
            initialState={{
              sorting: { sortModel: [{ field: "created_at", sort: "desc" }] },
            }}
          />
        </DialogContent>
      </Dialog>
      <Dialog
        open={showingCardFulfillmentHistoryForId !== null}
        onClose={() => setShowCardFulfillmentHistoryForId(null)}
        fullWidth
      >
        <DialogTitle>History</DialogTitle>
        <DialogContent sx={{ typography: "body2" }}>
          <SimpleGrid
            idField="ws_card_id"
            rows={cardFulfillmentHistory.data?.data ?? []}
            columns={USER_DEBITCARD_FULFILLMENT_HISTORY_COLUMNS}
            error={cardFulfillmentHistory.error}
            isLoading={cardFulfillmentHistory.isLoading}
            initialState={{
              sorting: { sortModel: [{ field: "created_at", sort: "desc" }] },
            }}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default UserPaymentCards

import { Chip, Stack } from "@mui/material"

type Color = "default"

type PropertyCategoriesProps = {
  value?: Array<string>
}

export function PropertyCategories({ value }: PropertyCategoriesProps) {
  const categories = !!value ? value : []
  const color: Color = "default"

  return (
    <Stack direction="column" spacing={1}>
      {categories.map((category) => (
        <Chip
          sx={{ minWidth: "7ch", px: 1 }}
          size={"small"}
          color={color}
          label={category}
        />
      ))}
    </Stack>
  )
}

import { useNotification } from "context/notification"
import { pickErrorMessage } from "utils/pickErrorMessage"
import { safeHtmlDecode } from "utils/safeHtmlDecode"
import usePostSegment from "query-hooks/usePostSegment"
import { SegmentBuilderDialog } from "../SegmentBuilderDialog/SegmentBuilderDialog"

interface CreateSegmentDialogProps {
  onClose: () => void
}

export function CreateSegmentDialog({ onClose }: CreateSegmentDialogProps) {
  const notify = useNotification()

  const { mutate, isLoading } = usePostSegment({
    onSuccess: () => {
      notify.that("segment").successfully("created")
      onClose()
    },
    onError: (error) => {
      if (safeHtmlDecode(pickErrorMessage(error))) {
        notify.error(`Error: ${safeHtmlDecode(pickErrorMessage(error))}`, {
          autoHideDuration: 10000,
        })
      } else {
        notify.that("segment").erroredWhile("creating")
      }
    },
  })

  return (
    <SegmentBuilderDialog
      title="Create New Segment"
      primaryButtonText="Create"
      onClose={onClose}
      isSubmitting={isLoading}
      onSubmit={mutate}
    />
  )
}

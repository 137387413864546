import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"
import { DATE_FORMATS, formatDate } from "utils/date"

export const getQueryKey = () => ["root-actions"] as const

function useRootActions(startDate: Date | null, endDate: Date | null) {
  const formattedStartDate = formatDate(
    startDate,
    DATE_FORMATS.standardShortDate
  )
  const formattedEndDate = formatDate(endDate, DATE_FORMATS.standardShortDate)

  const endpoint = endpoints.rootActions.all(
    formattedStartDate,
    formattedEndDate
  )

  return useQuery(
    [...getQueryKey(), formattedStartDate, formattedEndDate],
    endpoint.get,
    {
      select: (data) => data.data.data,
    }
  )
}

useRootActions.getQueryKey = getQueryKey

export default useRootActions

import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = () => ["discounts", "retailers"] as const

const useDiscountRetailers = () => {
  const endpoint = endpoints.discounts.all()

  return useQuery(getQueryKey(), endpoint.get, {
    select: (data) => data.data.data,
  })
}

useDiscountRetailers.getQueryKey = getQueryKey

export default useDiscountRetailers

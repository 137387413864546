import {
  secondsToMilliseconds,
  minutesToMilliseconds,
  millisecondsToMinutes,
} from "date-fns"

export function getSessionTtlMessage(ttl: number) {
  if (ttl <= 0) {
    return "Session expired"
  }

  if (ttl <= secondsToMilliseconds(45)) {
    return `Session expires in a few seconds`
  }

  if (ttl <= secondsToMilliseconds(90)) {
    return `Session expires in a minute`
  }

  if (ttl <= minutesToMilliseconds(45)) {
    return `Session expires in ${millisecondsToMinutes(ttl)} minutes`
  }

  const hours = Math.round(millisecondsToMinutes(ttl) / 60)
  return `Session expires in ${hours} ${hours === 1 ? "hour" : "hours"}`
}

export function getRefreshInterval(ttl: number) {
  if (ttl > minutesToMilliseconds(60)) {
    return minutesToMilliseconds(1)
  }

  if (ttl > minutesToMilliseconds(5)) {
    return secondsToMilliseconds(30)
  }

  return secondsToMilliseconds(5)
}

export function getTtl(exp: number) {
  return secondsToMilliseconds(exp) - Date.now()
}

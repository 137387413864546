import { Chip } from "@mui/material"
import type { ColorSchema } from "theme/palette"

export type ValueType = "string" | "integer" | "boolean" | "object" | "array"

type Color = ColorSchema | undefined

const statusColors: Record<ValueType, Color> = {
  string: "success",
  integer: "warning",
  boolean: "error",
  object: "info",
  array: "primary",
}

type DataTypeProps = {
  value?: ValueType
}

export function DataType({ value }: DataTypeProps) {
  const color: Color = !!value ? statusColors[value] : undefined

  return (
    <Chip
      sx={{ minWidth: "7ch", px: 1 }}
      size={"small"}
      color={color}
      label={value}
    />
  )
}

import React, { useState } from "react"
import { Link as RouterLink } from "react-router-dom"
import {
  Autocomplete,
  CircularProgress,
  InputAdornment,
  styled,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import BusinessIcon from "@mui/icons-material/Business"
import PersonIcon from "@mui/icons-material/Person"
import { useDebounce } from "use-debounce"
import { routes } from "routes"
import { useGlobalSearchQueries } from "./useGlobalSearchQueries"
import { GlobalSearchListbox } from "./GlobalSearchListbox"

const MINIMUM_SEARCH_TERM_LENGTH = 3

const RouterLinkWithinAutocomplete = styled(RouterLink)({
  width: "100%",
  height: "100%",
  padding: "5px 5px 5px 24px",
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  color: "inherit",
  textDecoration: "inherit",
})

export function GlobalSearch() {
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"))

  const [searchValue, setSearchValue] = useState<string>("")
  const [debouncedSearchValue] = useDebounce(searchValue, 500)
  const isBeingDebounced = searchValue !== debouncedSearchValue
  const isSearchValueTooShort = searchValue.length < MINIMUM_SEARCH_TERM_LENGTH
  const isSearchValueLongEnough = !isSearchValueTooShort
  const isDebouncedSearchValueTooShort =
    debouncedSearchValue.length < MINIMUM_SEARCH_TERM_LENGTH
  const {
    data,
    areAllQueriesLoading,
    areSomeQueriesLoading,
    isErrorLoadingCompanies,
    isErrorLoadingEmployees,
  } = useGlobalSearchQueries(debouncedSearchValue, {
    enabled: !isDebouncedSearchValueTooShort,
  })

  // NOTE: There is no way to stop the value of the Autocomplete changing once a result has been
  // selected. The value of the autocomplete can either be uncontrolled, where selection will
  // change the value, or controlled with a present value. We don't want to control it with a
  // a present value, only an empty value but this does not appear to be possible. Instead I am
  // resetting the autocomplete by changing the key in onChange, so that the autocomplete is reset
  // and the value does not persist.
  const [key, setKeyState] = useState(0)
  function resetAutocomplete() {
    setKeyState((key) => (key === 0 ? 1 : 0))
  }

  const options = isBeingDebounced
    ? []
    : data?.map((result) => ({
        id: result.primary_key,
        label: result.name,
        type: result.entity,
        link:
          result.entity === "company"
            ? routes.company({ id: result.primary_key })
            : routes.employee({ id: result.primary_key }),
        extraInfo: result.extra_info,
      })) || []

  const ListboxComponent = React.forwardRef<
    HTMLUListElement,
    React.HTMLAttributes<HTMLElement>
  >((props, ref) => (
    <GlobalSearchListbox
      ref={ref}
      isErrorLoadingCompanies={isErrorLoadingCompanies}
      isErrorLoadingEmployees={isErrorLoadingEmployees}
      {...props}
    />
  ))

  return (
    <Autocomplete
      fullWidth
      key={key}
      size={isDesktop ? "medium" : "small"}
      sx={{ flex: 1, mr: 2 }}
      openOnFocus
      clearOnBlur
      disableClearable
      loading={
        isSearchValueLongEnough && (isBeingDebounced || areAllQueriesLoading)
      }
      loadingText="Loading…"
      inputValue={searchValue}
      onInputChange={(_, newValue) => setSearchValue(newValue)}
      onChange={() => {
        resetAutocomplete()
      }}
      options={options}
      // NOTE: To foregoe the in-built client-side filtering of the options by the input value
      // since the filtering is already done by API query:
      // https://mui.com/material-ui/react-autocomplete/#search-as-you-type
      filterOptions={(o) => o}
      groupBy={(option) => option.type}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder="Search for companies and employees"
          InputProps={{
            ...params.InputProps,
            type: "search",
            startAdornment: (
              <InputAdornment position="start">
                {isSearchValueLongEnough &&
                (isBeingDebounced || areSomeQueriesLoading) ? (
                  <CircularProgress size="24px" sx={{ p: "2px" }} />
                ) : (
                  <SearchIcon />
                )}
              </InputAdornment>
            ),
          }}
        />
      )}
      ListboxComponent={ListboxComponent}
      renderOption={(props, option) => {
        const { id, type, label, extraInfo, link } = option
        return (
          <li {...props} key={id} style={{ padding: 0 }}>
            <RouterLinkWithinAutocomplete to={link}>
              {type === "company" && (
                <BusinessIcon aria-label="Company" sx={{ mr: 1 }} />
              )}
              {type === "employee" && (
                <PersonIcon aria-label="Employee" sx={{ mr: 1 }} />
              )}
              {label}
              {extraInfo && ` (${extraInfo})`}
            </RouterLinkWithinAutocomplete>
          </li>
        )
      }}
      noOptionsText={
        isSearchValueTooShort
          ? "You must enter three or more characters"
          : areSomeQueriesLoading
          ? "Loading…"
          : isErrorLoadingCompanies && isErrorLoadingEmployees
          ? "Error loading the results"
          : "No results"
      }
    />
  )
}

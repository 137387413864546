import { Chip } from "@mui/material"
import type { PARTNER_STATES } from "utils/constants"

export type PartnerState = keyof typeof PARTNER_STATES

type Color =
  | "success"
  | "error"
  | "default"
  | "primary"
  | "secondary"
  | "info"
  | "warning"
  | undefined

const statusColors: Record<PartnerState, Color> = {
  LIVE: "success",
  ONBOARDING: "secondary",
  TRIAL: "default",
  DISABLED: "error",
}

type CompanyStatusProps = {
  small?: boolean
  value?: PartnerState
}

export function CompanyStatus({ small, value }: CompanyStatusProps) {
  const color: Color = !!value ? statusColors[value] : undefined

  return (
    <Chip
      sx={{ minWidth: "7ch", px: small ? 1 : undefined }}
      size={small ? "small" : "medium"}
      color={color}
      label={value}
    />
  )
}

import { Button, MenuItem, Stack, TextField } from "@mui/material"
import type { FILTER_OPTIONS } from "../../constants/filterOptions"

interface AddSegmentFilterProps {
  filters: typeof FILTER_OPTIONS
  value: string
  onValueChange: (value: string) => void
  onValueSubmit: (value: string) => void
}

export function AddSegmentFilter({
  filters,
  value,
  onValueChange,
  onValueSubmit,
}: AddSegmentFilterProps) {
  return (
    <Stack gap={4} direction="row" alignItems="center">
      <TextField
        name="filterIdToAdd"
        value={value}
        disabled={filters.length === 0}
        label="Add filter"
        select
        size="small"
        fullWidth
        onChange={({ target: { value } }) => onValueChange(value)}
      >
        {filters.map(({ id, name }) => (
          <MenuItem key={id} value={id}>
            {name}
          </MenuItem>
        ))}
      </TextField>
      <Button
        variant="contained"
        disabled={filters.length === 0}
        onClick={() => onValueSubmit(value)}
        style={{ height: 37 }}
      >
        Add
      </Button>
    </Stack>
  )
}

import { useState } from "react"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import MoreVertIcon from "@mui/icons-material/MoreVert"
import { IconButton, Menu, MenuItem } from "@mui/material"
import { useNotification } from "context/notification"
import { getAPIToken } from "context/auth/getAPIToken"

export function SessionExpiryMoreToggle() {
  const notify = useNotification()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const isMenuOpen = Boolean(anchorEl)

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const handleCopyTokenClick = () => {
    navigator.clipboard.writeText(getAPIToken() || "")
    notify.info("Token copied to clipboard")
    handleClose()
  }

  return (
    <>
      <IconButton
        aria-label="more"
        id="session-expiry-more-button"
        aria-controls={isMenuOpen ? "session-expiry-more-menu" : undefined}
        aria-expanded={isMenuOpen ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleOpen}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="session-expiry-more-menu"
        MenuListProps={{
          "aria-labelledby": "session-expiry-more-button",
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={isMenuOpen}
        onClose={handleClose}
      >
        <MenuItem onClick={handleCopyTokenClick}>
          <ContentCopyIcon fontSize="inherit" sx={{ mr: 2 }} />
          Copy Token
        </MenuItem>
      </Menu>
    </>
  )
}

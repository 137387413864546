import Icon from "@mui/material/Icon"
import CancelIcon from "@mui/icons-material/Cancel"
import { GridActionsCellItem } from "@mui/x-data-grid"
import useDebitcardUSStatusUpdate from "query-hooks/useDebitcardUSStatusUpdate"
import { ConfirmationDialog } from "components"
import { useState } from "react"

function UserDebitcardCancelAction({
  cardId,
  userId,
  status,
}: {
  cardId: string
  userId: string
  status: string
}) {
  const { mutate, isLoading } = useDebitcardUSStatusUpdate(cardId, userId, {
    onSuccess: () => {
      setShowConfirm(false)
    },
  })
  const [showConfirm, setShowConfirm] = useState(false)
  const [reason, setReason] = useState<string | undefined>()

  return (
    <>
      <GridActionsCellItem
        label="Cancel"
        disabled={status === "TERMINATED"}
        icon={status === "TERMINATED" ? <Icon /> : <CancelIcon />}
        onClick={() => {
          setShowConfirm(true)
        }}
      />
      <ConfirmationDialog
        open={showConfirm}
        title="Confirm card cancelation"
        isLoading={isLoading}
        disabled={!reason || reason?.length <= 0}
        onConfirm={() => {
          mutate({ status: "TERMINATED", reason: reason })
        }}
        onCancel={() => setShowConfirm(false)}
      >
        Are you sure you want to cancel this card? (This change is not
        reversible)
        <br /> <br />
        If yes, please enter a reason:
        <br /> <br />
        <input
          placeholder={`Enter reason...`}
          maxLength={1000}
          type="text"
          onChange={(e) => setReason(e.target.value)}
        />
      </ConfirmationDialog>
    </>
  )
}

export default UserDebitcardCancelAction

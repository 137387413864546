import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = (id?: string, includeHidden?: boolean) =>
  ["company", id, "properties", "schema", includeHidden] as const

function useOptionsCompanyProperties(
  id: string | undefined,
  includeHidden?: boolean,
  options?: Partial<{
    onSuccess: (id?: string) => void
  }>
) {
  const endpoint = endpoints.companies.properties.byId(id, includeHidden)

  return useQuery(getQueryKey(id, includeHidden), endpoint.options, {
    enabled: Boolean(id),
    onSuccess: () => {
      options?.onSuccess?.(id)
    },
  })
}

useOptionsCompanyProperties.getQueryKey = getQueryKey

export default useOptionsCompanyProperties

import { Box, Typography, alpha } from "@mui/material"
import { styled } from "@mui/material/styles"

const StyledContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(6),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha("#A8220A", 0.15),
  color: "#A8220A",
  fontWeight: 700,
}))

export function UsageWarningMessage() {
  return (
    <StyledContainer>
      <Box sx={{ overflow: "hidden", textAlign: "center", width: 450 }}>
        <Typography variant="h2">
          All access attempts and actions are logged and recorded. Unauthorised
          actions are prohibited under the 1990 Computer Misuse Act and US Law.
        </Typography>
      </Box>
    </StyledContainer>
  )
}

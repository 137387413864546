import { useMutation, useQueryClient } from "@tanstack/react-query"
import { endpoints } from "api"
import { getPayScheduleDates } from "pages/Employee/query-hooks/usePayScheduleDates"

function usePostPayScheduleDateApprove(
  id: string,
  payScheduleId: string,
  options?: Partial<{
    onSuccess: () => void
    onError: () => void
  }>
) {
  const endpoint = endpoints.paySchedules.dates.approve(payScheduleId, id)
  const queryClient = useQueryClient()

  return useMutation(() => endpoint.post(), {
    onSuccess: () => {
      options?.onSuccess?.()
      queryClient.invalidateQueries(getPayScheduleDates(payScheduleId))
    },
    onError: () => {
      options?.onError?.()
    },
  })
}

export default usePostPayScheduleDateApprove

import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = (withSegments?: boolean) =>
  ["inappdialogs", withSegments] as const

function useInAppDialogs({ withSegments }: { withSegments?: boolean } = {}) {
  const endpoint = endpoints.inAppDialogs.all(withSegments)

  return useQuery(getQueryKey(), endpoint.get, {
    select: (data) => data.data.dialogs,
  })
}

useInAppDialogs.getQueryKey = getQueryKey

export default useInAppDialogs

import React from "react"
import ReactDOM from "react-dom/client"
import { QueryClientProvider } from "@tanstack/react-query"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import enGbLocale from "date-fns/locale/en-GB"
import { AuthProvider } from "context/auth"
import { NotistackProvider } from "context/notification"
import ThemeProvider from "theme"
import { queryClient } from "utils/queryClient"
import { ReactQueryDevtoolsProduction } from "utils/ReactQueryDevtoolsProduction"
import reportWebVitals from "./reportWebVitals"
import App from "./App"

// eslint-disable-next-line xss/no-mixed-html
const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ThemeProvider>
          <LocalizationProvider
            dateAdapter={AdapterDateFns}
            adapterLocale={enGbLocale}
          >
            <NotistackProvider>
              <App />
            </NotistackProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </AuthProvider>
      <ReactQueryDevtools />
      <ReactQueryDevtoolsProduction />
    </QueryClientProvider>
  </React.StrictMode>
)

reportWebVitals()

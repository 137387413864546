import React from "react"
import * as Sentry from "@sentry/react"
import { BrowserTracing } from "@sentry/tracing"
import {
  createRoutesFromChildren,
  matchRoutes,
  Routes,
  useLocation,
  useNavigationType,
} from "react-router-dom"

const getDns = () => {
  if (process.env.NODE_ENV !== "production") {
    return ""
  }

  return "https://a3f2715e0f1148cd827b926bf0a1c689@o315138.ingest.sentry.io/6524929"
}

const tracer = new BrowserTracing({
  routingInstrumentation: Sentry.reactRouterV6Instrumentation(
    React.useEffect,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes
  ),
})

Sentry.init({
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_ENVIRONMENT,
  integrations: [tracer],
  dsn: getDns(),
  tracesSampleRate: 0.25,
})

export const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

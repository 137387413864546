import assert from "assert"
import { LoadingButton } from "@mui/lab"
import { Alert, Stack } from "@mui/material"
import { CollapsibleCard, ControlledTextField } from "components"
import { useNotification } from "context/notification"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { pickErrorMessage } from "utils/pickErrorMessage"
import usePostPayScheduleDateGenerate from "../../query-hooks/usePostPayScheduleDateGenerate"

function isValidDayOfMonth(value: unknown) {
  const number = Number(value)
  return Number.isInteger(number) && Number(number) >= 1 && Number(number) <= 31
}

export const GenerateDates = () => {
  const notify = useNotification()
  const { id: payScheduleId } = useParams()
  assert(payScheduleId)

  const { mutate, isLoading, isError, error } = usePostPayScheduleDateGenerate(
    payScheduleId,
    {
      onSuccess: () => {
        reset()
        notify.that("pay schedule dates").successfully("generated")
      },
    }
  )

  const { handleSubmit, reset, control } = useForm<{
    pay_period_start: string
    pay_day_of_month: string
  }>({
    defaultValues: {
      pay_period_start: "",
      pay_day_of_month: "",
    },
  })

  const handleFormSubmit = handleSubmit((values) => mutate(values))

  return (
    <CollapsibleCard title="Generate Dates" type="action">
      <form id="generate-pay-schedule-dates" onSubmit={handleFormSubmit}>
        <Stack spacing={4}>
          {isError && <Alert severity="error">{pickErrorMessage(error)}</Alert>}
          <ControlledTextField
            name="pay_period_start"
            control={control}
            label="Pay Period Start (1-31)"
            rules={{
              required: true,
              validate: (value) =>
                isValidDayOfMonth(value) ||
                "Pay Period Start must be a whole number between 1 and 31",
            }}
            fieldProps={{
              type: "number",
              required: true,
              fullWidth: true,
              autoComplete: "off",
            }}
          />
          <ControlledTextField
            name="pay_day_of_month"
            control={control}
            label="Pay Day (1-31)"
            rules={{
              required: true,
              validate: (value) =>
                isValidDayOfMonth(value) ||
                "Pay Day must be a whole number between 1 and 31",
            }}
            fieldProps={{
              type: "number",
              required: true,
              fullWidth: true,
              autoComplete: "off",
            }}
          />
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            Generate Dates
          </LoadingButton>
        </Stack>
      </form>
    </CollapsibleCard>
  )
}

import { Card, CardContent, Grid, IconButton, Typography } from "@mui/material"
import ArrowBackIcon from "@mui/icons-material/ArrowBack"
import type { FC, ReactNode } from "react"
import { Link } from "react-router-dom"
import CopyTextComponent from "components/CopyTextToClipboard/CopyTextToClipboard"

type PageHeaderProps = {
  backLink?: string
  title?: ReactNode
  subtitle?: ReactNode
  subtitleLink?: string
  additionalInfo?: ReactNode
  idToCopy?: string
}

export const PageHeader: FC<PageHeaderProps> = ({
  backLink,
  title,
  subtitle,
  subtitleLink,
  additionalInfo,
  idToCopy,
}) => (
  <Card sx={{ p: 4, pb: 1 }}>
    <CardContent>
      <Grid container spacing={2} sx={{ px: 2 }}>
        {backLink && (
          <Grid item display="flex" alignItems="center" height="100%">
            <IconButton component={Link} to={backLink} aria-label="back">
              <ArrowBackIcon fontSize="inherit" />
            </IconButton>
          </Grid>
        )}
        <Grid
          flex={1}
          display="flex"
          flexDirection="row"
          alignItems="center"
          gap="2em"
          paddingLeft=".5em"
        >
          <Grid
            item
            display="flex"
            justifyContent="center"
            flexDirection="column"
          >
            {typeof title === "string" ? (
              <Typography variant="h2" component="h1" gutterBottom={!!subtitle}>
                {title}
              </Typography>
            ) : (
              title
            )}
            {typeof subtitle === "string" ? (
              <Typography
                component={subtitleLink ? Link : "h2"}
                to={subtitleLink}
                color="grey.500"
                variant="subtitle1"
                target="_blank"
              >
                {subtitle}
              </Typography>
            ) : (
              subtitle
            )}
          </Grid>
          {idToCopy && (
            <CopyTextComponent text={idToCopy} hideText valueName="ID" />
          )}
        </Grid>

        {Boolean(additionalInfo) && (
          <Grid item ml="auto">
            {additionalInfo}
          </Grid>
        )}
      </Grid>
    </CardContent>
  </Card>
)

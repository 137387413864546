import { styled } from "@mui/material/styles"
import { HEADER, SIDEBAR } from "shell/config"

export const Main = styled("main")(({ theme }) => ({
  flexGrow: 1,
  paddingTop: HEADER.MOBILE_HEIGHT + 24,
  paddingBottom: 24,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: 16,
    paddingRight: 16,
    paddingTop: HEADER.DESKTOP_HEIGHT + 24,
    paddingBottom: 24,
    width: `calc(100% - ${SIDEBAR.WIDTH}px)`,
  },
}))

import type { OptionsObject, SnackbarMessage } from "notistack"
import { useSnackbar } from "notistack"

export const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar()

  const variants = {
    error: (message: SnackbarMessage, options?: OptionsObject) =>
      enqueueSnackbar(message, {
        variant: "error",
        // @ts-expect-error This works but there's no support for typing it https://github.com/iamhosseindhv/notistack/pull/260
        "data-testid": "error-notification",
        ...options,
      }),
    success: (message: SnackbarMessage, options?: OptionsObject) =>
      enqueueSnackbar(message, {
        variant: "success",
        // @ts-expect-error This works but there's no support for typing it https://github.com/iamhosseindhv/notistack/pull/260
        "data-testid": "success-notification",
        ...options,
      }),
    info: (message: SnackbarMessage, options?: OptionsObject) =>
      enqueueSnackbar(message, {
        variant: "info",
        // @ts-expect-error This works but there's no support for typing it https://github.com/iamhosseindhv/notistack/pull/260
        "data-testid": "info-notification",
        ...options,
      }),
    warning: (message: SnackbarMessage, options?: OptionsObject) =>
      enqueueSnackbar(message, {
        variant: "warning",
        // @ts-expect-error This works but there's no support for typing it https://github.com/iamhosseindhv/notistack/pull/260
        "data-testid": "warning-notification",
        ...options,
      }),
  }

  return {
    ...variants,
    that: (target: string) => ({
      erroredWhile: (progressiveTense: string) =>
        variants.error(`Error ${progressiveTense} ${target}.`),
      successfully: (pastTense: string) =>
        variants.success(`Successfully ${pastTense} ${target}.`),
    }),
  }
}

import type { GridFilterItem, GridFilterModel } from "@mui/x-data-grid"
import { useState } from "react"
import { useSearchParams } from "react-router-dom"

const filterTransformer = (filter: GridFilterItem): [string, string] => [
  `${filter.columnField}.${filter.operatorValue}`,
  filter.value,
]

const filterParser = ([clause, value]: [string, string]): GridFilterItem => {
  const [columnField, operatorValue] = clause.split(".")
  return { columnField, operatorValue, value }
}

const searchParamsParser = (
  searchParams: URLSearchParams
): [string, string][] => {
  const pairs = []

  for (const entry of searchParams.entries()) {
    if (entry[0].includes(".")) {
      pairs.push(entry)
    }
  }

  return pairs
}

export const useGridQueryParamFilter = (): [
  GridFilterModel,
  (model: GridFilterModel) => void
] => {
  const [searchParams, setSearchParams] = useSearchParams()
  const initialItems = searchParamsParser(searchParams).map(filterParser)
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: initialItems,
  })

  const onFilterModelChange = (model: GridFilterModel) => {
    const serializedItems = model.items
      .filter(({ value }) => typeof value !== "undefined")
      .map(filterTransformer)
    setSearchParams(serializedItems)
    setFilterModel(model)
  }

  return [filterModel, onFilterModelChange]
}

import { Chip } from "@mui/material"
import type { EMPLOYEE_STATES } from "utils/constants"

export type EmployeeState = keyof typeof EMPLOYEE_STATES

type Color = "success" | "error" | "info" | "secondary" | "default" | undefined

const statusColors: Record<EmployeeState, Color> = {
  ACTIVE: "success",
  ENROLLED: "success",
  PENDING: "secondary",
  INSTALLED: "info",
  ENROLLING: "secondary",
  NEW: "info",
  DISABLED: "error",
}

type EmployeeStatusChipProps = {
  small?: boolean
  value?: EmployeeState
}

export function EmployeeStatusChip({ small, value }: EmployeeStatusChipProps) {
  const color: Color = !!value ? statusColors[value] : undefined

  return (
    <Chip
      sx={{ minWidth: "7ch", px: small ? 1 : undefined }}
      size={small ? "small" : "medium"}
      color={color}
      label={value}
    />
  )
}

import { typeScaleRem } from "./getFontValue"

// ----------------------------------------------------------------------

const FONT_PRIMARY = "Public Sans, sans-serif" // Google Font

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    fontSize: typeScaleRem(7),
    lineHeight: typeScaleRem(9),
  },
  h2: {
    fontWeight: 700,
    fontSize: typeScaleRem(6),
    lineHeight: typeScaleRem(7),
  },
  h3: {
    fontWeight: 700,
    fontSize: typeScaleRem(5),
    lineHeight: typeScaleRem(7),
  },
  h4: {
    fontWeight: 700,
    fontSize: typeScaleRem(4),
    lineHeight: typeScaleRem(6),
  },
  h5: {
    fontWeight: 700,
    fontSize: typeScaleRem(3),
    lineHeight: typeScaleRem(5),
  },
  h6: {
    fontWeight: 700,
    fontSize: typeScaleRem(2),
    lineHeight: typeScaleRem(4),
  },
  subtitle1: {
    fontWeight: 600,
    fontSize: typeScaleRem(4),
    lineHeight: typeScaleRem(6),
  },
  subtitle2: {
    fontWeight: 600,
    fontSize: typeScaleRem(3),
    lineHeight: typeScaleRem(5),
  },
  body1: {
    fontSize: typeScaleRem(4),
    lineHeight: typeScaleRem(6),
  },
  body2: {
    fontSize: typeScaleRem(3),
    lineHeight: typeScaleRem(5),
  },
  caption: {
    fontSize: typeScaleRem(2),
    lineHeight: typeScaleRem(4),
  },
  overline: {
    fontWeight: 700,
    fontSize: typeScaleRem(2),
    lineHeight: typeScaleRem(4),
    textTransform: "uppercase",
  },
  button: {
    fontWeight: 700,
    fontSize: typeScaleRem(3),
    lineHeight: typeScaleRem(5),
    textTransform: "capitalize",
  },
} as const

export default typography

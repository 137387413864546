import { type GridColumns } from "@mui/x-data-grid"
import type { DebitcardApplicationHistoryUS } from "api"
import { dateTimeColumnDefaultOptions } from "utils/columns"

export const USER_DEBITCARD_APPLICATION_HISTORY_COLUMNS: GridColumns<DebitcardApplicationHistoryUS> =
  [
    {
      field: "card_application_id",
      headerName: "Card Application ID",
      minWidth: 160,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 160,
    },
    {
      field: "created_at",
      headerName: "Created At",
      minWidth: 140,
      ...dateTimeColumnDefaultOptions(),
    },
  ]

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material"
import type { ManualTransaction } from "api"
import type { AxiosError } from "axios"
import { ControlledTextField } from "components"
import ButtonWithConfirmation from "components/ButtonWithConfirmation/ButtonWithConfirmation"
import { ControlledSelect } from "components/ControlledSelect/ControlledSelect"
import { useNotification } from "context/notification"
import useManualTransfer from "query-hooks/useManualTransfer"
import { useState } from "react"
import { useForm } from "react-hook-form"

export default function ManalTransactionRequest() {
  const notify = useNotification()

  const [showDialog, setShowDialog] = useState(false)

  const { mutate, isLoading } = useManualTransfer({
    onSuccess: () => {
      notify.that("action").successfully("queued")
    },
    onError: (e: AxiosError) => {
      const data = e?.response?.data as { message: string }
      notify.error(`Error: ${data?.message}`)
    },
  })

  const { control, handleSubmit } = useForm<ManualTransaction>({
    defaultValues: {
      banking_provider: "MODULR",
      type: "inter-ledger",
      amount: 0,
      currency: "GBP",
    },
  })

  const onSubmit = (data: ManualTransaction) => {
    setShowDialog(false)
    data.amount = Number(data.amount)
    mutate({ data })
  }

  const onErrors = (data: unknown) => {
    console.log(data)
    notify.error("Form validation error")
  }

  const confirm = () => {
    handleSubmit(onSubmit, onErrors)()
    return true
  }

  return (
    <>
      <Button
        disabled={isLoading}
        variant="contained"
        onClick={() => setShowDialog(true)}
      >
        Manual Transfer
      </Button>
      <Dialog open={showDialog}>
        <DialogTitle>Action Input</DialogTitle>
        <br />
        <DialogContent>
          <Stack spacing={2} width={300}>
            <ControlledSelect
              control={control}
              label="Banking Provider"
              name="banking_provider"
              rules={{ required: true }}
              fieldProps={{
                margin: "normal",
                size: "small",
                fullWidth: true,
                autoComplete: "off",
              }}
            >
              {["MODULR", "RAILSBANK"].map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </ControlledSelect>

            <ControlledTextField
              control={control}
              label="Purpose"
              name="purpose"
              rules={{ required: true }}
            />
            <ControlledSelect
              control={control}
              label="transfer type"
              name="type"
              rules={{ required: true }}
            >
              {["inter-ledger", "faster-payments", "sepa"].map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </ControlledSelect>
            <ControlledTextField
              control={control}
              label="from ledger id"
              name="from_id"
              rules={{ required: true }}
            />
            <ControlledTextField
              control={control}
              label="to ledger id"
              name="to_id"
              rules={{ required: true }}
            />
            <ControlledTextField
              control={control}
              label="amount in pence (i.e. minor)"
              name="amount"
              rules={{
                required: true,
                validate: (value) =>
                  Number.isInteger(Number(value))
                    ? undefined
                    : "must be a whole number",
              }}
            />
            <ControlledSelect
              control={control}
              label="currency Provider"
              name="currency"
              rules={{ required: true }}
            >
              {["GBP", "EUR"].map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </ControlledSelect>
            <ControlledTextField
              control={control}
              label="reference"
              name="reference"
            />
            <ControlledTextField
              control={control}
              label="transfer id"
              name="transfer_id"
            />
            <ControlledTextField
              control={control}
              label="parent id"
              name="parent_id"
            />
            <ControlledTextField
              control={control}
              label="employee id"
              name="employee_id"
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)}>Cancel</Button>
          <ButtonWithConfirmation
            onConfirm={confirm}
            title="Confirmation"
            message="Are you sure you wish to queue this action for approval?"
            label="Submit"
          />
        </DialogActions>
      </Dialog>
    </>
  )
}

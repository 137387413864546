import { Card, Grid } from "@mui/material"
import { CollapsibleCard, JsonDialog, SimpleGrid } from "components"
import { useParams, useSearchParams } from "react-router-dom"
import useDeviceExperiments from "pages/Device/query-hooks/useDeviceExperiments"
import { useMemo, useState } from "react"
import { useColumns } from "./columns"
import { DeviceExperimentsGridToolbar } from "./DeviceExperimentsGridToolbar"

function DeviceExperiments() {
  const { id } = useParams()
  const [dialogProperties, setDialogProperties] = useState<
    [string, object] | null
  >()
  const { data, error, isLoading } = useDeviceExperiments(id)

  const columns = useColumns({
    onViewProperties: setDialogProperties,
  })

  const [searchParams, setSearchParams] = useSearchParams()
  const includeInactiveExperiments =
    searchParams.get("show_inactive")?.toLowerCase() === "true"

  function toggleInactiveExperiments() {
    setSearchParams({
      ...Object.fromEntries(searchParams.entries()),
      show_inactive: String(!includeInactiveExperiments),
    })
  }

  const rows = useMemo(() => {
    let rows =
      data?.map((experiment) => ({
        ...experiment,
        inactive: !experiment.active,
      })) || []

    if (!includeInactiveExperiments) {
      rows = rows.filter(({ inactive }) => !inactive)
    }

    return rows
  }, [data, includeInactiveExperiments])

  return (
    <Grid item xs={12}>
      <CollapsibleCard title="Experiments" type="section">
        <Card>
          <SimpleGrid
            idField="experiment_code"
            error={error}
            rows={rows}
            isLoading={isLoading}
            columns={columns}
            quickFilter
            pagination
            components={{
              Toolbar: DeviceExperimentsGridToolbar,
            }}
            componentsProps={{
              toolbar: {
                includeInactiveExperiments,
                toggleInactiveExperiments,
              },
            }}
          />
        </Card>
        <JsonDialog
          open={!!dialogProperties}
          title={"Treatment: " + dialogProperties?.[0] || ""}
          src={dialogProperties?.[1] || {}}
          jsonViewProps={{
            collapseStringsAfterLength: 50,
          }}
          onClose={() => setDialogProperties(null)}
          transitionDuration={{ exit: 0 }}
        />
      </CollapsibleCard>
    </Grid>
  )
}

export default DeviceExperiments

import type { ButtonTypeMap } from "@mui/material"
import { Button, Tooltip } from "@mui/material"
import { JsonDialog } from "components"
import { useState } from "react"

export default function JsonPopout({
  json,
  text,
  title,
  btnColor = "primary",
  btnVariant = "text",
  tooltip = undefined,
}: {
  json: object
  text: string
  title: string
  tooltip?: string
  btnColor?: ButtonTypeMap["props"]["color"]
  btnVariant?: ButtonTypeMap["props"]["variant"]
}) {
  const [open, setOpen] = useState(false)
  return (
    <>
      <JsonDialog
        open={open}
        title={title}
        src={json}
        jsonViewProps={{
          collapseStringsAfterLength: 50,
        }}
        onClose={() => {
          setOpen(false)
        }}
      />
      <Tooltip title={tooltip ?? ""}>
        <Button
          variant={btnVariant}
          color={btnColor}
          onClick={() => setOpen(true)}
        >
          {text}
        </Button>
      </Tooltip>
    </>
  )
}

import { parseJSON, isValid } from "date-fns"
import { formatInTimeZone } from "date-fns-tz"
import enGB from "date-fns/locale/en-GB"

type Value = string | Date | null | undefined

/**
 * `parseJSON` handles UTC automatically so appending `Z` is not necessary.
 */
export function parseDate(value: Value) {
  if (value == null) return null

  if (typeof value !== "string") return isValid(value) ? value : null

  // Check if a timestamp exists
  const dateString = value.includes(":") ? value : `${value}T00:00:00`
  const date = parseJSON(dateString)

  return isValid(date) ? date : null
}

export function parseDateAsLocal(value?: string | null) {
  if (value == null) return null

  const dateString = value.includes(":") ? value : `${value}T00:00:00`
  const localDate = new Date(dateString)

  return isValid(localDate) ? localDate : null
}

export const DATE_FORMATS = {
  longDate: "do MMMM yyyy", // 1st January 2000
  longDateTime: "do MMMM yyyy HH:mm:ss", // 1st January 2000 13:20:14
  standardShortDate: "yyyy-MM-dd", // 2000-01-01
  standardShortDateTime: "yyyy-MM-dd HH:mm:ss", // 2000-01-01 12:20:14
}

export function formatDate(value: Value, formatTo: string) {
  const date = parseDate(value)
  if (!formatTo || !date) return null
  return formatInTimeZone(date, "UTC", formatTo, { locale: enGB })
}

export function timeSince(date: Date) {
  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000)
  return timeFromNow(seconds)
}

export function timeUntil(date: Date) {
  const seconds = Math.floor((date.getTime() - new Date().getTime()) / 1000)
  return timeFromNow(seconds)
}

function timeFromNow(seconds: number) {
  let interval = seconds / 31536000

  if (interval > 1) {
    return Math.floor(interval) + " years"
  }

  interval = seconds / 2592000
  if (interval > 1) {
    return Math.floor(interval) + " months"
  }

  interval = seconds / 86400
  if (interval > 1) {
    return Math.floor(interval) + " days"
  }

  interval = seconds / 3600
  if (interval > 1) {
    return Math.floor(interval) + " hours"
  }

  interval = seconds / 60
  if (interval > 1) {
    return Math.floor(interval) + " minutes"
  }

  return seconds === 0 ? "now" : Math.floor(seconds) + " seconds"
}

export const getUTCTimeStringFromDate = (date: Date | null) => {
  return date?.toISOString()?.substring(11, 16)
}

export const getDateFromUTCTimeString = (timeString: string | undefined) => {
  if (!timeString) return null
  const [hours, minutes] = timeString.split(":").map(Number)
  const date = new Date()
  date.setUTCHours(hours, minutes)
  return date
}

import { Box } from "@mui/material"
import type { FC, PropsWithChildren } from "react"

export const DottedUnderline: FC<PropsWithChildren<{ enabled?: boolean }>> = ({
  children,
  enabled = true,
}) => (
  <Box
    sx={{
      textDecoration: enabled ? "underline" : undefined,
      textDecorationStyle: enabled ? "dotted" : undefined,
    }}
  >
    {children}
  </Box>
)

import { styled, useTheme } from "@mui/material/styles"
import GoogleIcon from "@mui/icons-material/Google"
import AccessTimeIcon from "@mui/icons-material/AccessTime"
import { useAuthDispatch, useAuthState } from "context/auth"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  useMediaQuery,
  Typography,
  IconButton,
  Divider,
} from "@mui/material"
import { getSessionTtlMessage } from "./utils"
import { useEstimatedTtl } from "./useEstimatedTtl"
import { SessionExpiryMoreToggle } from "./SessionExpiryMoreToggle"

const StyledAccessTimeIcon = styled(AccessTimeIcon)(({ theme }) => ({
  boxSizing: "content-box",
  padding: theme.spacing(1),
}))

const StyledContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2, 2, 1),
  marginRight: theme.spacing(1),
  borderRadius: theme.shape.borderRadius,
  background: theme.palette.grey[500_12],
}))

export function SessionExpiryTimer() {
  const theme = useTheme()
  const { user } = useAuthState()
  const dispatch = useAuthDispatch()
  const isDesktop = useMediaQuery(theme.breakpoints.up("lg"))

  const estimatedTtl = useEstimatedTtl(user?.exp || 0)

  const timeRemainingText = getSessionTtlMessage(estimatedTtl)
  const isSessionExpired = estimatedTtl <= 0
  const iconColor = isSessionExpired ? "error" : "action"

  return (
    <>
      {isDesktop ? (
        <StyledContainer sx={{ gap: 2 }}>
          <StyledAccessTimeIcon color={iconColor} />
          <Typography color={isSessionExpired ? "error" : undefined}>
            {timeRemainingText}
          </Typography>
          <Divider orientation="vertical" flexItem variant="middle" />
          <Tooltip title="Refresh login">
            <IconButton
              size="small"
              onClick={() =>
                dispatch({
                  type: "LOGIN_WITH_GOOGLE",
                  payload: { inNewWindow: true },
                })
              }
            >
              <GoogleIcon fontSize="inherit" />
            </IconButton>
          </Tooltip>
          <Divider orientation="vertical" flexItem variant="middle" />
          <SessionExpiryMoreToggle />
        </StyledContainer>
      ) : (
        <Tooltip title={timeRemainingText}>
          <StyledAccessTimeIcon color={iconColor} sx={{ mr: 1 }} />
        </Tooltip>
      )}
      <Dialog open={false}>
        <DialogTitle>Session Expiring</DialogTitle>
        <DialogContent>
          Your session is about to expire. Either finish what you're doing, or
          log-in again.
        </DialogContent>
        <DialogActions>
          <Button variant="contained">Dismiss</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

import { endpoints } from "api"
import { useMutation } from "@tanstack/react-query"

function useDebitcardUSReissue(
  account_id: string,
  options?: Partial<{
    onSuccess: () => void
    onError: () => void
  }>
) {
  const endpoint = endpoints.paymentAccountUS.issue_card

  return useMutation((account_id: string) => endpoint(account_id).post(), {
    onSuccess: () => {
      options?.onSuccess?.()
    },
    onError: () => {
      options?.onError?.()
    },
  })
}

export default useDebitcardUSReissue

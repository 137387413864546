import React from "react"
import { Alert } from "@mui/material"

interface GlobalSearchListboxProps extends React.HTMLAttributes<HTMLElement> {
  isErrorLoadingCompanies: boolean
  isErrorLoadingEmployees: boolean
}

export const GlobalSearchListbox = React.forwardRef<
  HTMLUListElement,
  GlobalSearchListboxProps
>(({ isErrorLoadingCompanies, isErrorLoadingEmployees, ...props }, ref) => {
  const showAlert = isErrorLoadingCompanies || isErrorLoadingEmployees

  return (
    <>
      {showAlert && (
        <Alert severity="warning">
          {isErrorLoadingCompanies &&
            "Error loading some of the company results"}
          {isErrorLoadingEmployees &&
            "Error loading some of the employee results"}
        </Alert>
      )}
      <ul ref={ref} {...props} />
    </>
  )
})

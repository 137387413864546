import type { FC } from "react"
import { Stack, Alert, Typography } from "@mui/material"
import type { FallbackRender } from "@sentry/react/types/errorboundary"

export const ErrorFallback: FC<Parameters<FallbackRender>[0]> = (error) => (
  <Stack spacing={4} width="100%">
    <Alert variant="filled" severity="error">
      <Typography>An error ocurred!</Typography>
      <Typography variant="caption">Event ID: {error.eventId}</Typography>
    </Alert>
    <Alert icon={false} variant="outlined" severity="warning">
      <b>{error.error.name}</b>: {error.error.message}
      <pre>{error.error.stack}</pre>
    </Alert>
  </Stack>
)

import assert from "assert"
import { Autocomplete, Chip, TextField, styled } from "@mui/material"
import type { PaydaysSegmentPropertiesFilter } from "api"
import type { SegmentBuilderFormValues } from "components"
import { Controller, type Control, type FieldPath } from "react-hook-form"
import { get } from "lodash-es"
import { compareBy } from "utils/compareBy"
import { useDataSource } from "../../hooks/useDataSource"
import { isValidDataSource } from "../../utils/isValidDataSource"

interface Option {
  label: string
  value: string
}

interface MultiSelectFilterFieldProps {
  name: FieldPath<SegmentBuilderFormValues>
  control: Control<SegmentBuilderFormValues>
  filter: Omit<PaydaysSegmentPropertiesFilter, "values"> & {
    values: (string | boolean | Date | string[])[]
  }
}

type Filter = Pick<MultiSelectFilterFieldProps, "filter">["filter"]
// Created to handle simple prop access used in formatOptions
type SimplifiedDataSource = Record<string, string>[] | string[]

const formatOptions = (
  filterData: Filter["data"],
  data: SimplifiedDataSource
): Option[] | null => {
  if (Array.isArray(filterData)) {
    return filterData.map((i) => ({ value: i, label: i }))
  } else if (filterData.source && filterData.key && filterData.display) {
    const { source, key, display } = filterData

    return data.map((item) => {
      assert(
        typeof item === "object",
        "Item in data source data should be an object"
      )
      const value: string = get(item, key)
      assert(
        value,
        `Could not find value key "${key}" in an item belonging to data source: "${source}"`
      )
      const label: string = get(item, display)
      assert(
        label,
        `Could not find display key "${display}" in an item belonging to data source: "${source}"`
      )

      return { value, label }
    })
  }
  return null
}

const Container = styled("div")({
  flex: 1,
  minWidth: "300px",
})

export const MultiSelectFilterField = ({
  name,
  control,
  filter,
}: MultiSelectFilterFieldProps) => {
  const { data: filterData } = filter
  const source = !Array.isArray(filterData) ? filterData.source : undefined
  const dataSourceQuery = useDataSource(
    source && isValidDataSource(source) ? source : undefined
  )
  const { isLoading, isError, data } = dataSourceQuery || {
    isLoading: false,
    isError: false,
    data: [],
  }

  if (isLoading) return <div>Loading options</div>
  if (isError) return <div>Error loading options</div>

  // Casting to SimplifiedDataSource as formatOptions does enough checks to ensure type safety
  const options = formatOptions(filterData, data as SimplifiedDataSource)

  if (!options) {
    return <div>Unsupported config for MultiSelectFilterField</div>
  }

  const sortedOptions = options
    .map(({ label, value }) => ({ value, label: label.trim() }))
    .sort(compareBy("label"))

  const optionsMap: Record<string, string> = sortedOptions.reduce<
    Record<string, string>
  >((acc, o) => {
    return {
      ...acc,
      [o.value]: o.label,
    }
  }, {})

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const { value } = field

        return (
          <Container>
            <Autocomplete
              multiple
              options={sortedOptions}
              filterSelectedOptions
              value={((value as string[]) || []).map((id) => ({
                value: id,
                label: optionsMap[id],
              }))}
              onChange={(_, data) => field.onChange(data.map((i) => i.value))}
              renderInput={(params) => (
                <TextField {...params} label={filter.name} />
              )}
              renderOption={(props, option) => (
                <li {...props}>{option.label}</li>
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip {...getTagProps({ index })} label={option.label} />
                ))
              }
              isOptionEqualToValue={(o, v) => o.value === v.value}
            />
          </Container>
        )
      }}
    />
  )
}

import PaydaysApi from "../client/client"
import { auth, companies } from "../endpoints"
import type { ImpersonationTokenResponse } from "../types/client"
import type { PaydaysCompanyProperties } from "../types/paydays"

const LOGIN_URLS: Record<string, string> = {
  mhritrent: "REACT_APP_MHRITRENT_LOGIN_URL",
  mhrpeoplefirst: "REACT_APP_MHRPEOPLEFIRST_LOGIN_URL",
  wagestream: "REACT_APP_LOGIN_URL",
  zellispay: "REACT_APP_ZELLIS_LOGIN_URL",
  osv: "REACT_APP_OSV_LOGIN_URL",
}

const PARTNER_URLS: Record<string, string> = {
  harri: "REACT_APP_HARRI_ROOT",
  lip: "REACT_APP_LIP_ROOT",
  osv: "REACT_APP_OSV_ROOT",
  sme: "REACT_APP_SME_WAGESTREAM_ROOT",
  zellispay: "REACT_APP_ZELLIS_PP_ROOT",
  mhritrent: "REACT_APP_MHRITRENT_PP_ROOT",
  mhrpeoplefirst: "REACT_APP_MHRPEOPLEFIRST_PP_ROOT",
  wagestream: "REACT_APP_PP_ROOT",
}

const NEW_PARTNER_URLS: Record<string, string> = {
  wagestream: "REACT_APP_SME_WAGESTREAM_ROOT",
}

const APP_URLS: Record<string, string> = {
  frontline: "REACT_APP_FRONTLINE_APP_ROOT",
  harri: "REACT_APP_HARRI_APP_ROOT",
  lip: "REACT_APP_LEGION_APP_ROOT",
  osv: "REACT_APP_OSV_APP_ROOT",
  mhritrent: "REACT_APP_MHRITRENT_APP_ROOT",
  mhrpeoplefirst: "REACT_APP_MHRPEOPLEFIRST_APP_ROOT",
  wagestream: "REACT_APP_APP_ROOT",
  zellispay: "REACT_APP_ZELLIS_APP_ROOT",
}

const getPartnerUrl = (partner: string, newPortal: boolean) => {
  let urlEnvKey = PARTNER_URLS[partner] || "REACT_APP_PP_ROOT"

  if (newPortal) {
    urlEnvKey = NEW_PARTNER_URLS[partner] || "REACT_APP_SME_WAGESTREAM_ROOT"
  }

  return process.env[urlEnvKey] || process.env["REACT_APP_PP_ROOT"]
}

const getLoginUrl = (companyProps: PaydaysCompanyProperties | undefined) => {
  const partner = companyProps?.partnership || "wagestream"
  const urlEnvKey = LOGIN_URLS[partner] || "REACT_APP_LOGIN_URL"

  return process.env[urlEnvKey] || process.env.REACT_APP_LOGIN_URL
}

const getAppUrl = (companyProps: PaydaysCompanyProperties | undefined) => {
  const partner = companyProps?.partnership || "wagestream"
  const urlEnvKey = APP_URLS[partner] || "REACT_APP_APP_ROOT"

  if (partner === "frontline") {
    const appUrl = companyProps?.frontline?.app_url
    if (appUrl) {
      return appUrl
    }
  }

  return process.env[urlEnvKey] || process.env.REACT_APP_APP_ROOT
}

async function getAdminUrl({
  userId,
  partner,
  nextUrl,
  newPortal,
}: {
  userId: string
  partner: string
  nextUrl?: string
  newPortal: boolean
}) {
  const { data } = await PaydaysApi.post<ImpersonationTokenResponse>(
    auth.impersonateAdmin(userId),
    null,
    { includeToken: true }
  )

  if (!data.is_valid) {
    throw new Error("Could not log in")
  }

  const baseUrl = getPartnerUrl(partner, newPortal)

  const hashPath = "#/login/impersonate"

  const search = new URLSearchParams({
    access_token: data.access_token,
    refresh_token: data.refresh_token,
    ...(nextUrl ? { next: nextUrl } : {}),
  })

  return `${baseUrl}/?${search}${hashPath}`
}

async function getEmployeeUrl({
  userId,
  employeeId,
  useQrCode,
  companyProps,
}: {
  userId: string
  employeeId: string
  useQrCode: boolean
  companyProps: PaydaysCompanyProperties | undefined
}) {
  const { data } = await PaydaysApi.post<ImpersonationTokenResponse>(
    auth.impersonateAdmin(userId),
    new URLSearchParams({ employee_id: employeeId }),
    { includeToken: true }
  )

  if (!data.is_valid) {
    throw new Error("Could not log in")
  }
  const baseUrl = useQrCode
    ? getLoginUrl(companyProps)
    : getAppUrl(companyProps)

  // US apps need an env search param to switch backend api root.
  if (!process.env?.REACT_APP_ENVIRONMENT) {
    throw new Error(
      "Could not get REACT_APP_ENVIRONMENT as app env search param."
    )
  }

  const search = new URLSearchParams({
    token: data.access_token,
    env: process.env?.REACT_APP_ENVIRONMENT,
    impersonate: "true",
    ...(!useQrCode && data.tokens
      ? { tokens: JSON.stringify(data.tokens) }
      : {}),
  })

  return `${baseUrl}/?${search}`
}

export async function impersonateAdmin(
  userId: string,
  { partner, newPortal }: { partner: string; newPortal: boolean }
) {
  const redirectUrl = getAdminUrl({ userId, partner, newPortal })

  return redirectUrl
}

export async function impersonateCompany(
  companyId: string,
  { partner, nextUrl }: { nextUrl?: string; partner: string }
) {
  const { data } = await companies.admins.byId(companyId).get()

  const supportAdmin = data.data.find((admin) =>
    admin.username.includes("-support")
  )

  if (!supportAdmin) {
    throw new Error("No admins found")
  }

  const redirectUrl = getAdminUrl({
    userId: supportAdmin.user_id,
    partner,
    nextUrl,
    newPortal: false,
  })

  return redirectUrl
}

export async function impersonateEmployee(
  userId: string,
  {
    employeeId,
    useQrCode,
    companyProps,
  }: {
    employeeId: string
    useQrCode: boolean
    companyProps: PaydaysCompanyProperties | undefined
  }
) {
  const redirectUrl = getEmployeeUrl({
    userId,
    employeeId,
    useQrCode,
    companyProps,
  })

  return redirectUrl
}

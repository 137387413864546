import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"
export const getQueryKey = () => ["debitcard_us", "application"] as const

function usePaymentcardFulfillmentHistory(card_id: string | null) {
  const endpoint = endpoints.paymentcards.fulfillmentHistory(card_id)

  return useQuery([...getQueryKey(), card_id], endpoint.get, {
    enabled: !!card_id,
    select: (data) => data.data,
  })
}

usePaymentcardFulfillmentHistory.getQueryKey = getQueryKey

export default usePaymentcardFulfillmentHistory

import { useMutation } from "@tanstack/react-query"
import type { PaydaysDiscountCategoryUpdatePayload } from "api"
import { endpoints } from "api"
import { queryClient } from "utils/queryClient"
import useCategory from "pages/DiscountConfig/query-hooks/useCategory"
import useDiscountCategories from "pages/Discounts/query-hooks/useDiscountCategories"

export const useUpdateCategory = ({
  discount_category_id,
  options,
}: {
  discount_category_id: string | undefined
  options?: Partial<{ onSuccess: (id?: string) => void; onError: () => void }>
}) => {
  const endpoint = endpoints.discounts.category(discount_category_id)

  return useMutation(
    (payload: PaydaysDiscountCategoryUpdatePayload) => endpoint.patch(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          useCategory.getQueryKey(discount_category_id)
        )
        queryClient.invalidateQueries(useDiscountCategories.getQueryKey())
        options?.onSuccess?.()
      },
      onError: () => {
        options?.onError?.()
      },
    }
  )
}

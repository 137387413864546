import { alpha, IconButton, Stack, Tooltip } from "@mui/material"
import { styled } from "@mui/material/styles"
import MenuIcon from "@mui/icons-material/Menu"
import LogoutIcon from "@mui/icons-material/Logout"
import { useAuthDispatch } from "context/auth"
import { HEADER, SIDEBAR, getEnvAppProps } from "../../config"
import { GlobalSearch } from "../GlobalSearch/GlobalSearch"
import { SessionExpiryTimer } from "../SessionExpiryTimer/SessionExpiryTimer"
interface HeaderProps {
  onSidebarOpen: VoidFunction
}

const StyledHeader = styled("header")(({ theme }) => ({
  display: "flex",
  gap: 4,
  justifyContent: "space-between",
  alignItems: "center",
  position: "fixed",
  width: "100%",
  backgroundColor: alpha(getEnvAppProps().color, 0.3),
  backdropFilter: `blur(6px)`,
  WebkitBackdropFilter: `blur(6px)`,
  zIndex: theme.zIndex.appBar,
  height: HEADER.MOBILE_HEIGHT,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  [theme.breakpoints.up("lg")]: {
    height: HEADER.DESKTOP_HEIGHT,
    paddingLeft: `calc(${SIDEBAR.WIDTH}px + ${theme.spacing(2)})`,
  },
}))

export function Header({ onSidebarOpen }: HeaderProps) {
  const dispatch = useAuthDispatch()

  return (
    <StyledHeader>
      <IconButton
        aria-label="Open sidebar"
        sx={{ mr: 2, display: { lg: "none" } }}
        onClick={onSidebarOpen}
      >
        <MenuIcon />
      </IconButton>
      <GlobalSearch />
      <SessionExpiryTimer />
      <Stack direction="row" gap={2}>
        <Tooltip title="Logout">
          <IconButton
            onClick={() =>
              dispatch({ type: "LOGOUT", payload: { isPrimaryWindow: true } })
            }
          >
            <LogoutIcon />
          </IconButton>
        </Tooltip>
      </Stack>
    </StyledHeader>
  )
}

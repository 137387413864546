import React, { Suspense } from "react"

const ReactQueryProdUmd = React.lazy(() =>
  import("@tanstack/react-query-devtools/build/lib/index.prod.js").then(
    (module) => ({
      default: module.ReactQueryDevtools,
    })
  )
)

export const ReactQueryDevtoolsProduction = () => {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    // @ts-expect-error Dev manual
    window.toggleDevtools = () => setShow((current) => !current)
  }, [])

  if (!show) {
    return null
  }

  return (
    <Suspense>
      <ReactQueryProdUmd initialIsOpen={true} />
    </Suspense>
  )
}

import { Page } from "components/Page/Page"
import { TabNav } from "components"
import { Outlet, useLocation } from "react-router-dom"
import { routes } from "routes"

export default function RootActions() {
  const { pathname } = useLocation()

  const TABS = [
    { label: "Review Queue", href: routes.rootActionsReviewQueue() },
    { label: "Action Requests", href: routes.rootActionsRequest() },
  ]

  return (
    <Page
      headerTitle="Root Actions"
      tabs={<TabNav tabs={TABS} currentTab={pathname} />}
      scrollToTopButton
    >
      <Outlet />
    </Page>
  )
}

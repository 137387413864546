import { type GridColumns } from "@mui/x-data-grid"
import type { DebitcardStatusHistoryUS } from "api"
import { dateTimeColumnDefaultOptions } from "utils/columns"

export const USER_DEBITCARD_STATUS_HISTORY_COLUMNS: GridColumns<DebitcardStatusHistoryUS> =
  [
    {
      field: "ws_card_id",
      headerName: "Card ID",
      minWidth: 160,
    },
    {
      field: "card_status",
      headerName: "Status",
      minWidth: 160,
    },
    {
      field: "created_at",
      headerName: "Created At",
      minWidth: 140,
      ...dateTimeColumnDefaultOptions(),
    },
  ]

import { routes } from "routes"
import { PageHeader } from "components"
import { useLocation, useParams } from "react-router-dom"
import { Skeleton } from "@mui/material"
import usePaySchedule from "../../query-hooks/usePaySchedule"

function Header() {
  const location = useLocation()
  const { id } = useParams()
  const { data } = usePaySchedule(id)

  return (
    <PageHeader
      title={data?.name}
      backLink={
        typeof location.state === "object" &&
        location.state !== null &&
        location.state.hasOwnProperty("fromCompanyDefaultPaySchedules")
          ? routes.defaultPaySchedules()
          : routes.allPaySchedules()
      }
      subtitle={
        data ? (
          `paid ${data.periodicity.toLocaleLowerCase()} on ${
            data.paid_on_timezone
          }`
        ) : (
          <Skeleton width="30ch" />
        )
      }
    />
  )
}

export default Header

import { useParams } from "react-router-dom"
import { CollapsibleCard, Page, PageHeader } from "components"
import { Grid, Skeleton } from "@mui/material"
import { routes } from "routes"
import useCategory from "pages/DiscountConfig/query-hooks/useCategory"
import { CategoryForm } from "pages/DiscountConfig/Category/CategoryForm/CategoryForm"

const Category = () => {
  const { id } = useParams()
  const { data: category, isLoading } = useCategory(id)

  const title = category?.display_name || "Category"

  return (
    <Page
      title={title}
      header={
        <PageHeader title={title} backLink={routes.discountsCategories()} />
      }
    >
      <Grid item xs={12}>
        <CollapsibleCard type="main">
          {isLoading ? (
            <Skeleton width="100%" height="20vh" />
          ) : (
            <>
              <CategoryForm />
              <div style={{ marginTop: 30 }} />
            </>
          )}
        </CollapsibleCard>
      </Grid>
    </Page>
  )
}

export default Category

import { useMutation, useQueryClient } from "@tanstack/react-query"
import { endpoints } from "api"
import { getQueryKey } from "./usePaySchedule"

function usePatchPaySchedule(
  payScheduleId?: string,
  options?: Partial<{
    onSuccess: () => void
    onError: () => void
  }>
) {
  const queryClient = useQueryClient()
  const endpoint = endpoints.paySchedules.byId(payScheduleId)

  return useMutation(
    (payload: Record<string, string>) => endpoint.patch(payload),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getQueryKey(payScheduleId))
        options?.onSuccess?.()
      },
      onError: () => {
        options?.onError?.()
      },
    }
  )
}

export default usePatchPaySchedule

import type { Schema } from "api"
import { isEmpty } from "lodash-es"

const doesSearchMatch = (sourceSchema: Schema, search: string, key?: string) =>
  sourceSchema.description?.toLocaleLowerCase().includes(search) ||
  sourceSchema.title?.toLocaleLowerCase().includes(search) ||
  key?.toLocaleLowerCase().includes(search)

export const deepFilterSchema = (
  sourceSchema: Schema,
  search: string,
  key?: string
): Schema | null => {
  if (sourceSchema.type !== "object") {
    return doesSearchMatch(sourceSchema, search, key) ? sourceSchema : null
  }

  if (doesSearchMatch(sourceSchema, search, key)) {
    return sourceSchema
  }

  const entries = Object.entries(sourceSchema.properties || {})

  const onlyWithText = entries.reduce((prevEntries, [key, subSchema]) => {
    if (
      !JSON.stringify(subSchema).toLocaleLowerCase().includes(search) &&
      !key.toLocaleLowerCase().includes(search)
    ) {
      return prevEntries
    }

    const filteredSchema = deepFilterSchema(subSchema, search, key)

    if (
      !filteredSchema ||
      (filteredSchema.type === "object" && isEmpty(filteredSchema.properties))
    ) {
      return prevEntries
    }

    const entry: [string, Schema] = [key, filteredSchema]

    return [...prevEntries, entry]
  }, [] as typeof entries)

  return { ...sourceSchema, properties: Object.fromEntries(onlyWithText) }
}

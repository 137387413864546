import { TableContainer, Paper, Box } from "@mui/material"
import type { FC } from "react"
import { CollapsibleCard } from "components"
import type { PaydaysPropertyChangelog } from "api"
import { ChangelogTable } from "./ChangelogTable"

export const ExpandableChangelogTable: FC<{
  changelog?: PaydaysPropertyChangelog[]
}> = ({ changelog }) => {
  return (
    <Box py={2}>
      <CollapsibleCard title="Changelog">
        <TableContainer component={Paper}>
          <ChangelogTable changelog={changelog} />
        </TableContainer>
      </CollapsibleCard>
    </Box>
  )
}

// Based on minimals.cc react-scripts
// src/components/NotistackProvider.tsx
import type { ReactNode } from "react"
import type { ColorSchema } from "theme/palette"
import { alpha } from "@mui/material/styles"
import { Box } from "@mui/material"

type SnackbarIconProps = {
  children: ReactNode
  color: ColorSchema
}

export const SnackbarIcon = ({ children, color }: SnackbarIconProps) => {
  return (
    <Box
      component="span"
      sx={{
        mr: 1.5,
        width: 40,
        height: 40,
        display: "flex",
        borderRadius: 1.5,
        alignItems: "center",
        justifyContent: "center",
        color: `${color}.main`,
        bgcolor: (theme) => alpha(theme.palette[color].main, 0.16),
      }}
    >
      {children}
    </Box>
  )
}

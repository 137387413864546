import { Grid, Card, CardContent, Skeleton } from "@mui/material"

export function EmptyCard() {
  return (
    <Grid flex={1} container spacing={2}>
      <Grid item xs={12}>
        <Card sx={{ p: 4, pb: 1 }}>
          <CardContent>
            <Skeleton height={28} width="40ch" />
            <Skeleton height={20} width="20ch" />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12}>
        <Card sx={{ p: 2, height: "50vh" }}>
          <CardContent>
            <Skeleton height={30} width="35ch" />
            <Skeleton height={20} width="42ch" />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}

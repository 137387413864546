import { useContext } from "react"
import { AuthDispatchContext, AuthStateContext } from "./AuthContext"

export const useAuthState = () => {
  const context = useContext(AuthStateContext)

  if (!context) {
    throw new Error(
      "useAuthState may only be called in a child of AuthProvider"
    )
  }

  return context
}

export const useAuthDispatch = () => {
  const context = useContext(AuthDispatchContext)

  if (!context) {
    throw new Error(
      "useAuthDispatch may only be called in a child of AuthProvider"
    )
  }

  return context
}

import { Tabs, Tab } from "@mui/material"
import type { SyntheticEvent } from "react"
import { useNavigate } from "react-router-dom"

interface TabLinkProps {
  label: string
  href: string
  value?: string
}

interface NavigationTab {
  label: string
  href: string
  hidden?: boolean
}

type NavigationTabProps = NavigationTab[]

const TabLink = ({ label, href }: TabLinkProps) => {
  const navigate = useNavigate()

  const handleOnClick = (e: SyntheticEvent) => {
    e.preventDefault()
    navigate(href, { replace: true })
  }

  return <Tab label={label} onClick={handleOnClick} href={href} />
}

function TabNav({
  tabs,
  currentTab,
}: {
  tabs: NavigationTabProps
  currentTab: string | undefined
}) {
  return (
    <Tabs value={currentTab} sx={{ mb: 4 }} variant="scrollable">
      {tabs
        .filter(({ hidden }) => !hidden)
        .map(({ label, href }) => (
          <TabLink key={href} label={label} value={href} href={href} />
        ))}
    </Tabs>
  )
}

export { TabNav }

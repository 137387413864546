import { Button } from "@mui/material"
import { type GridColumns } from "@mui/x-data-grid"
import type { DebitcardApplicationUS } from "api"
import { dateTimeColumnDefaultOptions } from "utils/columns"
import PreviewIcon from "@mui/icons-material/Preview"
import { useMemo } from "react"
import ReactJson from "react-json-view"

type UseColumns = {
  onViewStatusHistory: (id: string) => void
}

const getColumns = ({
  onViewStatusHistory,
}: UseColumns): GridColumns<DebitcardApplicationUS> => {
  return [
    {
      field: "card_application_id",
      headerName: "Card Application ID",
      minWidth: 160,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 140,
      renderCell: ({ row }) => (
        <Button
          startIcon={<PreviewIcon />}
          onClick={() => {
            onViewStatusHistory(row.card_application_id)
          }}
        >
          {row.status}
        </Button>
      ),
    },
    {
      field: "internal_status",
      headerName: "Internal Status",
      minWidth: 160,
    },
    {
      field: "product_type",
      headerName: "Product Type",
      minWidth: 160,
    },
    {
      field: "account_id",
      headerName: "Account ID",
      minWidth: 160,
    },
    {
      field: "id_checks",
      headerName: "ID Checks",
      flex: 1,
      minWidth: 100,
      renderCell: ({ row }) => (
        <ReactJson
          name={null}
          indentWidth={2}
          src={row.id_checks}
          collapsed={true}
          displayDataTypes={false}
          displayObjectSize={false}
          enableClipboard={false}
        />
      ),
    },
    {
      field: "id_verified_at",
      headerName: "ID Verified At",
      minWidth: 140,
      ...dateTimeColumnDefaultOptions(),
    },
    {
      field: "accepted_terms_at",
      headerName: "Accepted Terms At",
      minWidth: 140,
      ...dateTimeColumnDefaultOptions(),
    },
    {
      field: "created_at",
      headerName: "Created At",
      minWidth: 140,
      ...dateTimeColumnDefaultOptions(),
    },
    {
      field: "completed_at",
      headerName: "Completed At",
      minWidth: 140,
      ...dateTimeColumnDefaultOptions(),
    },
    {
      field: "underwritten_at",
      headerName: "Underwritten At",
      minWidth: 140,
      ...dateTimeColumnDefaultOptions(),
    },
  ]
}

export const useColumns = ({ onViewStatusHistory }: UseColumns) =>
  useMemo(() => getColumns({ onViewStatusHistory }), [onViewStatusHistory])

import assert from "assert"
import { useMemo, useState } from "react"
import { Box, Card, Grid, Skeleton } from "@mui/material"
import usePaySchedulesDates from "pages/Employee/query-hooks/usePayScheduleDates"
import { useParams } from "react-router-dom"
import { CollapsibleCard, Page, SimpleGrid } from "components"
import { pickErrorMessage } from "utils/pickErrorMessage"
import type { PaydaysPayScheduleDate } from "api"
import { getBuildRegion, BUILD_REGION } from "shell/config"
import {
  GridToolbarContainer,
  GridToolbarExport,
  GridToolbarQuickFilter,
  type GridSelectionModel,
} from "@mui/x-data-grid"
import ButtonWithConfirmation from "components/ButtonWithConfirmation/ButtonWithConfirmation"
import { useNotification } from "context/notification"
import DeleteIcon from "@mui/icons-material/Delete"
import Header from "./components/Header/Header"
import { GenerateDates } from "./components/GenerateDates/GenerateDates"
import { GenerateDemoDates } from "./components/GenerateDemoDates/GenerateDemoDates"
import { AddDate } from "./components/AddDate/AddDate"
import { PayScheduleDetails } from "./components/PayScheduleDetails/PayScheduleDetails"
import usePaySchedule from "./query-hooks/usePaySchedule"
import { COLUMNS } from "./columns"
import { PayScheduleProperties } from "./components/PayScheduleProperties/PayScheduleProperties"
import { BulkUploadPaySchedules } from "./components/BulkUploadPaySchedules/BulkUploadPaySchedules"
import { GenerateDatesUS } from "./components/GenerateDatesUS/GenerateDatesUS"
import useBulkDeletePayScheduleDates from "./query-hooks/useBulkDeletePayScheduleDates"

export interface PayScheduleDateWithPayScheduleId
  extends PaydaysPayScheduleDate {
  pay_schedule_id: string
}

const PaySchedulesDatesContent = () => {
  const { id } = useParams()
  assert(id)

  const [selectedDates, setSelectedDates] = useState<GridSelectionModel>([])
  const notify = useNotification()
  const {
    data: payScheduleDatesData,
    isLoading: fetchLoading,
    isFetching,
    error,
  } = usePaySchedulesDates(id)
  const { mutate, isLoading: bulkDeleteLoading } =
    useBulkDeletePayScheduleDates(id, {
      onSuccess: () => {
        notify.that("pay schedule dates").successfully("deleted")
      },
      onError: () => {
        notify.that("pay schedule dates").erroredWhile("deleting")
      },
    })

  const allRowsSelected = selectedDates.length === payScheduleDatesData?.length
  const isLoading: boolean = fetchLoading || bulkDeleteLoading

  const deletePayScheduleDates = () => {
    assert(selectedDates.length > 0)

    mutate({
      pay_schedule_date_ids: selectedDates as string[],
    })

    return true
  }

  const rows = useMemo(() => {
    return (
      payScheduleDatesData?.map((paySchedules) => ({
        ...paySchedules,
        pay_schedule_id: id,
      })) || []
    )
  }, [payScheduleDatesData, id])

  const CustomToolbar = () => (
    <GridToolbarContainer>
      {selectedDates.length > 0 && (
        <ButtonWithConfirmation
          onConfirm={deletePayScheduleDates}
          message={`Are you sure you wish to delete ${selectedDates.length} pay schedule date(s)? This action cannot be reversed.`}
          title={"Bulk Delete Pay Schedule Dates"}
          label={`Delete ${
            allRowsSelected ? "All" : selectedDates.length
          } Pay Schedule ${selectedDates.length > 1 ? "Dates" : "Date"}`}
          disabled={isLoading || selectedDates.length === 0}
          icon={<DeleteIcon />}
        />
      )}
      <GridToolbarExport csvOptions={{ utf8WithBom: true }} />
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter debounceMs={200} />
    </GridToolbarContainer>
  )

  return (
    <>
      <Grid item xs={6}>
        <PayScheduleDetails />
      </Grid>
      <Grid item xs={6}>
        <PayScheduleProperties />
      </Grid>
      <Grid item xs={6}>
        <BulkUploadPaySchedules />
      </Grid>
      <Grid item xs={12}>
        <CollapsibleCard title="Pay Schedules Dates" type="section">
          <Grid container pb={4}>
            <Grid item xs={12} md={6}>
              {getBuildRegion() === BUILD_REGION.US && <GenerateDatesUS />}
              {getBuildRegion() === BUILD_REGION.UK && (
                <>
                  <GenerateDates />
                  <GenerateDemoDates />
                </>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <AddDate />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Card>
              {isLoading ? (
                <Skeleton
                  role="progressbar"
                  height={600}
                  sx={{ transform: "none" }}
                />
              ) : (
                <SimpleGrid
                  idField="pay_schedule_date_id"
                  isLoading={isFetching}
                  rows={rows}
                  columns={COLUMNS}
                  pagination
                  initialPaginationPageSize={20}
                  rowsPerPageOptions={[5, 10, 20]}
                  error={pickErrorMessage(error)}
                  checkboxSelection
                  selectionModel={selectedDates}
                  onSelectionModelChange={(newSelectedDates) => {
                    setSelectedDates(newSelectedDates)
                  }}
                  components={{
                    Toolbar: CustomToolbar,
                  }}
                />
              )}
            </Card>
          </Grid>
        </CollapsibleCard>
      </Grid>
    </>
  )
}

const PaySchedulesDates = () => {
  const { id } = useParams()

  const { data: paySchedule } = usePaySchedule(id)

  return (
    <Page title={paySchedule?.name} header={<Header />}>
      <PaySchedulesDatesContent />
    </Page>
  )
}

export default PaySchedulesDates

import axios from "axios"
import PaydaysApi from "../client/client"
import { auth } from "../endpoints"
import type {
  AccessTokenLoginPayload,
  AuthMeResponse,
  GoogleLoginPayload,
  PasswordLoginPayload,
  PasswordLoginResponse,
  TokenResponse,
} from "../types/client"

function setSessionData({
  token,
  errorMessage,
}: {
  token: string | null
  errorMessage: string | null
}) {
  localStorage.setItem("sessionData", JSON.stringify({ token, errorMessage }))
}

export function clearToken(errorMessage: string | null) {
  setSessionData({ token: null, errorMessage })
}

export function setToken(token: string) {
  setSessionData({ token, errorMessage: null })
}

export async function requestToken(code: string) {
  const { data } = await PaydaysApi.post<TokenResponse>(
    auth.googleTokenExchange(),
    { code },
    {
      params: {
        original_redirect_url: window.location.origin + "/auth_callback",
      },
    }
  )

  setToken(data.access_token)
}

export async function requestLoginWithPassword(
  payload: PasswordLoginPayload
): Promise<void> {
  try {
    const { data } = await PaydaysApi.post<PasswordLoginResponse>(
      auth.adminLogin(),
      new URLSearchParams(payload)
    )

    setToken(data.access_token)
  } catch (error) {
    if (error instanceof Error && !error.message) {
      throw new Error("Invalid login")
    }
    throw error
  }
}

export async function requestLoginWithGoogle(
  payload: GoogleLoginPayload
): Promise<void> {
  const { data } = await axios.get(
    process.env.REACT_APP_GOOGLE_AUTH_DISCOVERY_URL as string
  )

  const googleOauthUrl = data.authorization_endpoint

  const params = new URLSearchParams({
    client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID as string,
    flowName: "GeneralOAuthFlow",
    redirect_uri: window.location.origin + "/auth_callback",
    response_type: "code",
    scope: "openid email profile",
    state: JSON.stringify({ next: "/", close: !!payload.inNewWindow ? 1 : 0 }),
  })

  const url = `${googleOauthUrl}?${params}`

  payload.inNewWindow ? window.open(url) : window.location.assign(url)
}

export async function requestValidateAccessToken(
  payload: AccessTokenLoginPayload
): Promise<void> {
  try {
    const { data } = await PaydaysApi.get<AuthMeResponse>(auth.me(), {
      headers: { Authorization: `Bearer ${payload.accessToken}` },
    })

    if (!data.claims.r) {
      throw new Error("Invalid access token")
    }

    setToken(payload.accessToken)
  } catch (error) {
    if (error instanceof Error && !error.message) {
      throw new Error("Invalid login")
    }
    throw error
  }
}

import { IconButton, Tooltip } from "@mui/material"
import CheckIcon from "@mui/icons-material/Check"
import { useNotification } from "context/notification"
import usePostPayScheduleDateApprove from "../../query-hooks/usePostPayScheduleDateApprove"

interface ApproveActionProps {
  payScheduleId: string
  payScheduleDateId: string
}

export function ApproveAction({
  payScheduleId,
  payScheduleDateId,
}: ApproveActionProps) {
  const notify = useNotification()
  const { mutate, isLoading } = usePostPayScheduleDateApprove(
    payScheduleDateId,
    payScheduleId,
    {
      onSuccess: () =>
        notify.that("pay schedule date").successfully("approved"),
      onError: () => notify.that("pay schedule date").erroredWhile("approving"),
    }
  )

  return (
    <Tooltip title="Approve">
      <IconButton onClick={() => mutate()} disabled={isLoading}>
        <CheckIcon />
      </IconButton>
    </Tooltip>
  )
}

import ReactJson from "react-json-view"
import type { ReactJsonViewProps } from "react-json-view"
import type { DialogProps } from "@mui/material"
import { Stack } from "@mui/material"
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material"

interface JsonDialogProps extends DialogProps {
  open: boolean
  title: string
  closeButtonText?: string
  onClose: () => void
  src: object
  jsonViewProps: Omit<ReactJsonViewProps, "src">
}

export function JsonDialog({
  open,
  title,
  closeButtonText = "Close",
  onClose,
  src,
  jsonViewProps,
  children,
  fullWidth = true,
  ...rest
}: JsonDialogProps) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth={fullWidth}
      {...rest}
    >
      <DialogTitle>{title}</DialogTitle>
      <DialogContent sx={{ typography: "body2" }}>
        <Stack spacing={4}>
          {children}
          <ReactJson
            name={null}
            indentWidth={2}
            src={src}
            displayDataTypes={false}
            displayObjectSize={false}
            enableClipboard={false}
            {...jsonViewProps}
          />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onClose}>
          {closeButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

import type { Schema } from "api"
import { useMemo, useState } from "react"
import { useDebounce } from "use-debounce"
import { deepFilterSchema } from "../utils/deepFilterSchema"

export const useFilteredSchema = ({
  schema = {} as Schema,
}: {
  schema?: Schema
}) => {
  const [search, setSearch] = useState("")
  const [debouncedSearchValue] = useDebounce(search, 500)
  const isDebounced = search !== debouncedSearchValue

  const debouncedFilteredSchema = useMemo(() => {
    const { additionalProperties, ...filteredSchema } =
      deepFilterSchema(schema, debouncedSearchValue.toLocaleLowerCase()) || {}

    return filteredSchema
  }, [schema, debouncedSearchValue])

  return [
    search,
    setSearch,
    isDebounced,
    debouncedSearchValue,
    debouncedFilteredSchema,
  ] as const
}

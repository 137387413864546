import { Page } from "components/Page/Page"
import { TabNav } from "components"
import { Outlet, useLocation } from "react-router-dom"
import { routes } from "routes"

export default function AppReleases() {
  const { pathname } = useLocation()

  const TABS = [{ label: "List", href: routes.appReleasesList() }]

  return (
    <Page
      headerTitle="App Releases"
      tabs={<TabNav tabs={TABS} currentTab={pathname} />}
      scrollToTopButton
    >
      <Outlet />
    </Page>
  )
}

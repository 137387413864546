import { Tooltip, Typography } from "@mui/material"
import type { GridColumns } from "@mui/x-data-grid"
import { dateTimeColumnDefaultOptions } from "utils/columns"
import JsonPopout from "components/JsonPopout/JsonPopout"
import CopyToClipboard from "components/CopyToClipboard/CopyToClipboard"
import LaunchIcon from "@mui/icons-material/Launch"
import type { ConfigurationVersion } from "api"

export const parseReleaseDuration = (duration: string): string => {
  const daysMatch = duration.match(/(\d{2}) days/)
  const hoursMatch = duration.match(/(\d{2}):(\d{2}):(\d{2})/)

  const days = daysMatch ? parseInt(daysMatch[1], 10) : 0
  const hours = hoursMatch ? parseInt(hoursMatch[1], 10) : 0

  if (days === 0 && hours === 0) {
    return "Immediate"
  }

  let result = ""
  if (days > 0) {
    result += `${days} days`
  }
  if (hours > 0) {
    result += `${result ? ", " : ""}${hours} hours`
  }

  return result
}

const COLUMNS: GridColumns<ConfigurationVersion> = [
  {
    field: "mobile_application_version_id",
    headerName: "Version Link",
    width: 120,
    headerAlign: "center",
    align: "center",
    renderCell: ({ value }) => (
      <CopyToClipboard
        link={
          window.location.origin.toString() +
          window.location.pathname.toString() +
          "?mobile_application_version_id.contains=" +
          value
        }
      >
        <LaunchIcon />
      </CopyToClipboard>
    ),
  },
  {
    field: "version",
    headerName: "Version",
    width: 100,
    headerAlign: "center",
    align: "center",
    renderCell: ({ value }) => (
      <Tooltip
        arrow={true}
        title="The actual version of the app the user is running"
      >
        <Typography>{value}</Typography>
      </Tooltip>
    ),
  },
  {
    field: "minimum_native_wrapper_version",
    headerName: "Required Native Wrapper Version",
    width: 120,
    headerAlign: "center",
    align: "center",
    renderCell: ({ value }) => (
      <Tooltip title="The minimum required version installed from the store before the user will automatically get the next 'app verison' via remote artefacts.">
        <Typography>{value}</Typography>
      </Tooltip>
    ),
  },
  {
    field: "is_fully_rolled_out",
    headerName: "Fully Rolled Out",
    width: 120,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <Tooltip title="Will all users be receiving this update? (Subject to internet stability)">
        <Typography>
          {params.row.invalid_at
            ? "INVALID"
            : params.value
            ? "Yes"
            : params.value === undefined
            ? "Unavailable"
            : "No"}
        </Typography>
      </Tooltip>
    ),
  },
  {
    field: "deployed_to_store",
    headerName: "Deployed to Store",
    width: 140,
    headerAlign: "center",
    align: "center",
    renderCell: (params) => (
      <Tooltip title="Can this version be found in the App stores?">
        <Typography>{params.value ? "Yes" : "No"}</Typography>
      </Tooltip>
    ),
  },
  {
    field: "invalid_at",
    headerName: "Invalidate At",
    width: 160,
    headerAlign: "center",
    align: "center",
    ...dateTimeColumnDefaultOptions(),
    valueParser: undefined,
  },
  {
    field: "deployment_start",
    headerName: "Deployment Start UTC",
    width: 180,
    headerAlign: "center",
    align: "center",
    ...dateTimeColumnDefaultOptions(),
    valueParser: undefined,
  },
  {
    field: "deployment_duration",
    headerName: "Deployment Duration",
    width: 160,
    headerAlign: "center",
    align: "center",
    renderCell: ({ value }) => (
      <Tooltip title="The time taken to deploy this version to all users">
        <Typography>{parseReleaseDuration(value)}</Typography>
      </Tooltip>
    ),
  },
  {
    field: "properties",
    headerName: "Manifest",
    width: 100,
    headerAlign: "center",
    align: "center",
    renderCell: ({ value }) => (
      <JsonPopout text="Show" title="Manifest" json={value.manifest} />
    ),
  },
  {
    field: "created_at",
    headerName: "Created At",
    width: 160,
    headerAlign: "center",
    align: "center",
    ...dateTimeColumnDefaultOptions(),
    valueParser: undefined,
  },
  {
    field: "updated_at",
    headerName: "Updated At",
    width: 160,
    headerAlign: "center",
    align: "center",
    ...dateTimeColumnDefaultOptions(),
    valueParser: undefined,
  },
]

export default COLUMNS

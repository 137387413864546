import { useState, useCallback, Suspense, useEffect } from "react"
import { Outlet } from "react-router-dom"
import { Box } from "@mui/material"
import { useQueryClient } from "@tanstack/react-query"
import { getQueryKey } from "query-hooks/useCompanies"
import { Sidebar } from "./sidebar/Sidebar"
import { Header } from "./header/Header/Header"
import { Main } from "./components/Main/Main"
import { EmptyCard } from "./components/EmptyCard/EmptyCard"

let hasPrefetch = false

export function Shell() {
  const [isOpen, setIsOpen] = useState(false)
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!hasPrefetch) {
      queryClient.prefetchQuery(getQueryKey())
      hasPrefetch = true
    }
  })

  const handleOpen = useCallback(() => {
    setIsOpen(true)
  }, [])

  const handleClose = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <Box
      sx={{
        display: { lg: "flex" },
        minHeight: { lg: 1 },
      }}
    >
      <Header onSidebarOpen={handleOpen} />
      <Sidebar isOpen={isOpen} onClose={handleClose} />
      <Main>
        <Suspense fallback={<EmptyCard />}>
          <Outlet />
        </Suspense>
      </Main>
    </Box>
  )
}

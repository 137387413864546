import React, { useState } from "react"
import Tooltip from "@mui/material/Tooltip"
import ContentCopy from "@mui/icons-material/ContentCopy"
import { Container } from "@mui/material"

const CopyTextComponent = ({
  text,
  hideText,
  tooltipPlacement,
  valueName,
}: {
  text: string
  hideText?: boolean
  valueName?: string
  tooltipPlacement?:
    | "left"
    | "right"
    | "bottom"
    | "bottom-end"
    | "bottom-start"
    | "left-end"
    | "left-start"
    | "right-end"
    | "right-start"
    | "top-end"
    | "top-start"
    | "top"
    | undefined
}) => {
  const [copied, setCopied] = useState(false)

  const handleCopy = () => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setCopied(true)
        setTimeout(() => setCopied(false), 2000) // Reset copied state after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err)
      })
  }

  const tooltipText = valueName ? `Copy ${valueName}` : "Copy"
  const copyButton = (
    <Tooltip
      title={copied ? "Copied!" : tooltipText}
      placement={tooltipPlacement || "bottom"}
    >
      <ContentCopy
        onClick={handleCopy}
        style={{ cursor: "pointer" }}
        color="primary"
      />
    </Tooltip>
  )
  return hideText ? (
    copyButton
  ) : (
    <Container
      style={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      {text}
      {copyButton}
    </Container>
  )
}

export default CopyTextComponent

import Fab from "@mui/material/Fab"
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp"

export function ScrollToTopFab() {
  return (
    <Fab
      aria-label="scroll to top"
      onClick={() => window.scrollTo({ behavior: "smooth", top: 0 })}
      sx={{ position: "fixed", bottom: 30, right: 20 }}
    >
      <KeyboardArrowUpIcon />
    </Fab>
  )
}

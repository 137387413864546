import { useState } from "react"
import { IconButton, Tooltip } from "@mui/material"
import EditIcon from "@mui/icons-material/Edit"
import { parseDateAsLocal } from "utils/date"
import { pickErrorMessage } from "utils/pickErrorMessage"
import { useNotification } from "context/notification"
import usePutPayScheduleDateEdit from "../../query-hooks/usePutPayScheduleDateEdit"
import { EditDialog } from "./EditDialog"

interface EditActionProps {
  payScheduleId: string
  payScheduleDateId: string
  payPeriodStart?: string | null
  paidOn?: string | null
  blackoutPeriodStart?: string | null
  lockDate?: string | null
}

export function EditAction({
  payScheduleId,
  payScheduleDateId,
  payPeriodStart,
  paidOn,
  blackoutPeriodStart,
  lockDate,
}: EditActionProps) {
  const notify = useNotification()
  const [showDialog, setShowDialog] = useState(false)

  const { mutate, isLoading, error } = usePutPayScheduleDateEdit(
    payScheduleId,
    payScheduleDateId,
    {
      onSuccess: () => {
        closeDialog()
        notify.that("pay schedule date").successfully("updated")
      },
    }
  )

  const openDialog = () => setShowDialog(true)
  const closeDialog = () => setShowDialog(false)

  return (
    <>
      {showDialog && (
        <EditDialog
          defaultValues={{
            pay_period_start: parseDateAsLocal(payPeriodStart),
            paid_on: parseDateAsLocal(paidOn),
            blackout_period_start: parseDateAsLocal(blackoutPeriodStart),
            lock_date: parseDateAsLocal(lockDate),
          }}
          isSubmitting={isLoading}
          errorMessage={pickErrorMessage(error) ?? undefined}
          onSubmit={(payload) => mutate(payload)}
          onClose={closeDialog}
        />
      )}
      <Tooltip title="Edit">
        <IconButton onClick={openDialog} disabled={isLoading}>
          <EditIcon />
        </IconButton>
      </Tooltip>
    </>
  )
}

import { Chip } from "@mui/material"
import type { FUNDING_ENTITIES_ENUM } from "utils/constants"
import { FUNDING_ENTITIES } from "utils/constants"

export type FundingEntity = keyof typeof FUNDING_ENTITIES_ENUM

type Color =
  | "success"
  | "error"
  | "default"
  | "primary"
  | "secondary"
  | "info"
  | "warning"
  | undefined

type CompanyFundingEntityProps = {
  small?: boolean
  value?: FundingEntity
}

export function CompanyFundingEntity({
  small,
  value,
}: CompanyFundingEntityProps) {
  const funding_entity_text = value ? FUNDING_ENTITIES[value]?.text : undefined
  const color: Color = !!funding_entity_text
    ? "primary"
    : value
    ? "error"
    : "default"

  return (
    <Chip
      sx={{ minWidth: "7ch", px: small ? 1 : undefined }}
      size={small ? "small" : "medium"}
      color={color}
      label={
        value
          ? funding_entity_text ?? "Unrecognised Funding Entity: " + value
          : "No Funding Entity Set"
      }
    />
  )
}

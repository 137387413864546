import { ConfirmationDialog } from "components"
import type { FC, PropsWithChildren } from "react"
import { useState } from "react"
import type { ConfirmationState } from "./ConfirmationContext"
import { ConfirmationContext } from "./ConfirmationContext"

export const ConfirmationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [options, setOptions] = useState<ConfirmationState>()
  const [open, setOpen] = useState(false)

  const close = () => setOpen(false)

  const onConfirm = () => {
    if (options?.onConfirm) {
      return options?.onConfirm(close)
    }

    return close()
  }

  const onCancel = () => {
    if (options?.onCancel) {
      return options?.onCancel(close)
    }

    return close()
  }

  const confirm = (options: ConfirmationState) => {
    setOpen(true)
    setOptions(options)
  }

  return (
    <ConfirmationContext.Provider value={confirm}>
      {children}
      <ConfirmationDialog
        {...options}
        open={open}
        onConfirm={onConfirm}
        onCancel={onCancel}
      />
    </ConfirmationContext.Provider>
  )
}
